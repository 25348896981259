// @ts-ignore
import React from "react"
import styled from "styled-components"

import {
  background,
  BackgroundProps,
  bottom,
  BottomProps,
  color as styledColor,
  ColorProps,
  display,
  DisplayProps,
  height,
  HeightProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  right,
  RightProps,
  space as styledSpace,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
} from "styled-system"

export interface ViewProps
  extends BackgroundProps,
    BottomProps,
    ColorProps,
    DisplayProps,
    HeightProps,
    LeftProps,
    MaxWidthProps,
    MinHeightProps,
    PositionProps,
    RightProps,
    SpaceProps,
    TextAlignProps,
    TopProps,
    WidthProps,
    ZIndexProps {
  center?: boolean
  pointer?: boolean
  hover?: boolean
  ellipsis?: boolean
  textStyle?: string
  secondary?: boolean
  alternate?: boolean
  noScroll?: boolean
  flex?: boolean
  borderRadius?: any
  inline?: boolean
  relative?: boolean
  children?: any
  color?: string
  style?: any
  ref?: any
  id?: string
  className?: string
  onMouseUp?: any
  onClick?: any
  visible?: boolean
  as?: any
}

/**
 * Box is just a `View` or `div` (depending on the platform) with common styled-systems
 * hooks.
 */
const View = styled(
  React.forwardRef(
    (
      {
        center,
        ellipsis,
        textStyle,
        secondary,
        alternate,
        noScroll,
        borderRadius,
        inline,
        ...rest
      }: ViewProps,
      ref?: any
    ) => <div {...rest} ref={ref} />
  )
)`
  ${background};
  ${bottom};
  ${display};
  ${height};
  ${left};
  ${maxWidth};
  ${minHeight};
  ${position};
  ${right};
  ${styledColor};
  ${styledSpace};
  ${textAlign};
  ${top};
  ${width};
  ${zIndex};
  ${(props) =>
    props.center
      ? `
    margin-left: auto;
    margin-right: auto;
  `
      : null};
  ${(props) =>
    props.relative
      ? `
        position: relative;
      `
      : null};
  ${(props) =>
    props.pointer
      ? `
            cursor: pointer;
          `
      : null};
  ${(props) =>
    props.hover
      ? `
      :hover {
        opacity: 0.7;
      }`
      : ""};
`

View.displayName = "View"

export default View
