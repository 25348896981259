import React from "react"
import styled, { css } from "styled-components"
import useTheme from "../utilities/useTheme"
import {
  View,
  Stack,
  Text,
  Divider,
  Button,
  RatioContainer,
} from "../components"
import { SanityImage } from "./SlideImage"
import { useCheckoutOverlay } from "./CheckoutOverlay"

const SampleCard = ({ product, sample }) => {
  const { toggle, setProduct, setAmount, setSample } = useCheckoutOverlay()

  const percentWidth = "30%"

  const containerRatio = 1

  return (
    <View
      style={{
        minWidth: percentWidth,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
      pb={2}
    >
      <View>
        <RatioContainer ratio={containerRatio}>
          <View
            style={{
              position: "absolute",
              left: 8,
              top: 8,
              right: 8,
              bottom: 8,
            }}
          >
            <SanityImage source={sample.image} />
          </View>
        </RatioContainer>
      </View>
      <Stack style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Divider />
        <Text textStyle="subhead">{sample.displayName}</Text>
      </Stack>
      <Text
        style={{
          flex: 1,
          paddingLeft: 8,
          paddingRight: 8,
        }}
        my={2}
      >
        {sample.description.split("\n").map((txt, index) => (
          <>
            {txt}
            <br />
          </>
        ))}
      </Text>
      <Stack style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Divider my={0} />
        <Text my={2}> € {sample.price},- (VAT excluded)</Text>
        <Button
          my={2}
          mr={3}
          as="button"
          onClick={() => {
            toggle()
            if (!!setProduct) setProduct(product || null)
            if (!!setAmount) setAmount(sample?.price || null)
            if (!!setSample) setSample(sample || null)
          }}
          style={{ display: "block", width: "100%" }}
        >
          Order Direct
        </Button>
      </Stack>
    </View>
  )
}

export default SampleCard
