// a thing, where, based on the content/data, the correct thing is being shown
import React, { useState } from "react"
import styled from "styled-components"
import {
  View,
  Stack,
  Inline,
  Text,
  Headline,
  RatioContainer,
  SideBySide,
  Center,
  Button,
  Link,
  Divider,
} from "./index"
import { SanityImage } from "../components/SlideImage"
import Vimeo from "./Vimeo"
import CoverCarousel from "./CoverCarousel"
import useIsWide from "../utilities/useIsWide"
import BlockContent from "@sanity/block-content-to-react"
import { useMedia } from "use-media"
import useTheme from "../utilities/useTheme"
import Arrow from "./Arrow"
import Slideshow from "../components/Slideshow"
import Icon from "./Icon"
import Hero from "./Hero"
import { useContactOverlay } from "./ContactOverlay"

const ExpandWhenActive = styled(View)<{ expand?: boolean }>`
  transition: max-height 450ms ease;
  max-height: ${(props) => (props.expand ? "800px" : 0)};
  overflow-y: hidden;
`

const VideoWrapper = styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
`

const FullEverything = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50vw;
  right: -50vw;
  & * {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  & iframe {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

export const FullVideo = ({ url }) => (
  <VideoWrapper>
    <FullEverything>
      <Vimeo
        videoLink={url}
        background
        responsive={false}
        style={{ pointerEvents: "none" }}
      />
    </FullEverything>
  </VideoWrapper>
)

export default ({ item }) => {
  const { toggle } = useContactOverlay()
  const [active, setActive] = useState(item.fullWidth ? 0 : -1)
  const blockType = item.blockType
  const theme = useTheme()
  const isWide = useIsWide()
  const isNarrow = useMedia({ maxWidth: 600 })
  const isWrapped = useMedia({ maxWidth: theme.breakpoints[1] })
  const hasImage =
    blockType !== "LIST" &&
    item.images &&
    (item.images[0]?.image || item.images[0]?.url)
  const Container = hasImage ? RatioContainer : View
  const CenterIfWide = isWide ? Center : View
  const textColor = item.textColor || "black"
  const fullWidth = item.fullWidth
  const marginBottom = item.zeroMargin || isNarrow ? "-1px" : 4
  // return <div>{item.catchphrase}</div>
  if (item.label === "Hero") {
    return <Hero item={item} />
  }
  if (!!item.videoUrl) {
    console.log("Video")
    return (
      <View mb={marginBottom}>
        <RatioContainer {...{ ratio: 16 / 9 }}>
          <Vimeo
            videoLink={item.videoUrl}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </RatioContainer>
      </View>
    )
  }
  if (blockType === "SLIDESHOW") {
    return (
      <Slideshow
        slides={item.slides
          .map((slide) => ({
            ...slide.image.image,
            title: slide.title,
            descriptionRaw: slide.descriptionRaw,
          }))
          .filter((slide) => !!slide.asset)}
        mb={4}
        isNarrow={isNarrow}
      />
    )
  }
  if (blockType === "LIST_EXPAND") {
    if (fullWidth && !isWrapped) {
      return (
        <View mb={4}>
          <SideBySide>
            <View>
              <Divider mb={3} />

              {item.catchphrase && (
                <Text
                  color={textColor}
                  textStyle="headline"
                  mb={[10, 8, 6]}
                  mt={[4, 5, 6]}
                >
                  {item.catchphrase}
                </Text>
              )}

              {item.list &&
                item.list.map((listItem, index) => (
                  <View>
                    <Text
                      textStyle="action"
                      mb={[2, 4, 4]}
                      onClick={() => setActive(index)}
                      hover
                      pointer
                    >
                      {active === index && (
                        <Arrow
                          style={{
                            fontSize: "1.5em",
                            lineHeight: 1,
                            display: "inline-block",
                          }}
                          mr={1}
                        />
                      )}
                      {listItem.title}
                    </Text>
                  </View>
                ))}
            </View>
            <Stack gap={3}>
              {item.list[active]?.image?.image ||
              item.list[active]?.image?.url?.includes("vimeo") ? (
                <RatioContainer ratio={1} mb={2}>
                  {item.list[active]?.image.url?.includes("vimeo") ? (
                    <FullVideo url={item.list[active]?.image.url} />
                  ) : (
                    <SanityImage
                      source={
                        isNarrow && item.list[active]?.image.mobile
                          ? item.list[active]?.image.mobile
                          : item.list[active]?.image.image
                      }
                    />
                  )}
                </RatioContainer>
              ) : (
                <Divider mb={3} />
              )}

              <Text>
                {item.list[active]?.textRaw && (
                  <BlockContent blocks={item.list[active].textRaw} />
                )}
              </Text>

              {item.list[active].action && (
                <Link
                  to={item.list[active].action.action || "/"}
                  style={{ textDecoration: "underline" }}
                  mt={2}
                  block
                  textStyle="footnote"
                  {...(item.list[active].action.action?.includes("https") && {
                    href: item.list[active].action.action,
                    target: "_blank",
                    as: "a",
                  })}
                >
                  <Icon name="arrow-right" mr={1} />
                  {item.list[active].action.label}
                </Link>
              )}
            </Stack>
          </SideBySide>
          {/* <SideBySide>
            <View>
              <Divider mt={3} />
            </View>
            <View className="hide-on-wrap">
              <Divider mt={3} />
            </View>
          </SideBySide> */}
        </View>
      )
    } else {
      // LIST_EXPAND ..in one block
      return (
        <View mb={4}>
          {item.list &&
            item.list.map((listItem, index) => (
              <>
                <Divider mb={3} mt={index > 0 ? 3 : 0} />
                <View
                  onClick={() => setActive(index === active ? -1 : index)}
                  hover
                  pointer
                >
                  <Inline verticalAlign="center">
                    <Text textStyle="action" flex>
                      {listItem.title}
                    </Text>
                    <Icon
                      name={index !== active ? "arrow-left" : "arrow-down"}
                    />
                  </Inline>
                </View>
                {listItem?.textRaw && (
                  <Text mt={[2, 3, 3]}>
                    <BlockContent blocks={listItem.textRaw} />
                  </Text>
                )}
                <ExpandWhenActive expand={index === active}>
                  <View mt={3}>
                    {listItem?.image?.image ||
                    listItem?.image?.url?.includes("vimeo") ? (
                      <RatioContainer ratio={1} mb={2}>
                        {listItem?.image.url?.includes("vimeo") ? (
                          <VideoWrapper>
                            <FullEverything>
                              <Vimeo
                                videoLink={listItem?.image.url}
                                background
                                responsive={false}
                              />
                            </FullEverything>
                          </VideoWrapper>
                        ) : (
                          <SanityImage
                            source={
                              isNarrow && listItem?.image.mobile
                                ? listItem?.image.mobile
                                : listItem?.image.image
                            }
                          />
                        )}
                      </RatioContainer>
                    ) : null}
                    {listItem?.moreRaw && (
                      <Text mt={3} style={{ paddingBottom: "0.2em" }}>
                        <BlockContent blocks={listItem.moreRaw} />
                      </Text>
                    )}
                    {listItem.action && listItem.action.label?.length > 1 && (
                      <Link
                        to={listItem.action.action || "/"}
                        style={{ textDecoration: "underline" }}
                        mt={2}
                        block
                        textStyle="footnote"
                      >
                        <Icon name="arrow-right" mr={1} />
                        {listItem.action.label}
                      </Link>
                    )}
                  </View>
                </ExpandWhenActive>
              </>
            ))}
        </View>
      )
    }
  }
  if (blockType === "LIST") {
    if (fullWidth) {
      console.log(item.list)
      return (
        <View mb={marginBottom} mt={5}>
          {item.list &&
            item.list.map((listItem, index) => (
              <SideBySide>
                <View>
                  <Divider mb={3} mt={0} />
                  <Text textStyle="headline" mb={[2, 4, 4]}>
                    {listItem.title}
                  </Text>
                </View>
                <View>
                  <Divider mb={3} mt={0} />
                  <Inline
                    as={listItem.action?.action ? Link : null}
                    to={listItem.action?.action}
                    mb={4}
                  >
                    <Text flex>
                      {listItem?.textRaw && (
                        <BlockContent blocks={listItem.textRaw} />
                      )}
                    </Text>
                    <Text>
                      {listItem.action && <Icon name="arrow-right" ml={2} />}
                    </Text>
                  </Inline>
                </View>
              </SideBySide>
            ))}
          {/* <SideBySide>
            <View>
              <Divider mt={0} />
            </View>
            <View className="hide-on-wrap">
              <Divider mt={0} />
            </View>
          </SideBySide> */}
        </View>
      )
    } else {
      // STANDARD LIST
      return (
        <View mb={marginBottom}>
          {item.list &&
            item.list.map((listItem, index) => (
              <>
                <Divider mb={3} />

                <Stack gap={3}>
                  <Text textStyle="subhead" mb={3}>
                    {listItem.title}
                  </Text>
                  {listItem?.textRaw && (
                    <Text mb={[!!listItem.action ? 2 : 3, 0, 0]}>
                      <BlockContent blocks={listItem.textRaw} />
                    </Text>
                  )}
                  {listItem.action && (
                    <Link
                      to={listItem.action.action || "/"}
                      style={{ textDecoration: "underline" }}
                      mt={2}
                      block
                      textStyle="footnote"
                    >
                      <Icon name="arrow-right" mr={1} />
                      {listItem.action.label}
                    </Link>
                  )}
                </Stack>
              </>
            ))}
        </View>
      )
    }
  }
  // STANDARD
  return (
    <View mb={marginBottom}>
      {!hasImage && <Divider />}
      <View>
        <Container
          style={{ ...(hasImage && { zIndex: 654 }) }}
          {...(fullWidth
            ? {
                ratio: isNarrow ? 4 / 3 : 184 / 100,
              }
            : {
                ratio: isNarrow ? 4 / 5 : 92 / 100,
              })}

          /*
        style={{
          ...(!isWide && {
            position: "relative",
            maxHeight: 440,
          }),
          ...(hasImage && {
            minHeight: "40vw",
          }),
        }}
        */
        >
          {hasImage &&
            (item.images[0].url?.includes("vimeo") ? (
              <FullVideo url={item.images[0].url} />
            ) : (
              <SanityImage
                source={
                  isNarrow && item.images[0].mobile
                    ? item.images[0].mobile
                    : item.images[0].image
                }
              />
            ))}
          {/* <CoverCarousel title="Home - Digital Producer" /> */}
          <View
            px={hasImage && item.label !== "Hero" ? [3, 4, 5] : 0}
            pt={hasImage ? [3, 4, 5] : 0}
            style={{
              pointerEvents: "none",
              ...(fullWidth && { width: "clamp(300px, 50%, 25em" }),
              ...(hasImage && {
                position: "absolute",
                left: 0,
                ...(fullWidth ? { bottom: 0 } : { top: 0 }),
              }),
            }}
            pb={item.label === "Hero" ? [1, 2, 4] : 0}
          >
            <Stack
              gap={[1, 2, 3]}
              mb={[10, 8, 6]}
              mt={item.label === "Hero" ? [9, 9, 10] : [5, 6, 7]}
            >
              {item.catchphrase && (
                <Text
                  color={textColor}
                  textStyle={item.label === "Hero" ? "hero" : "headline"}
                  mr={[2, 3, 3]}
                  pt={item.label === "Hero" ? [9, 9, 10] : 0}
                  style={{
                    ...(item.label === "Hero" && {
                      maxWidth: "8em",
                      fontSize: "clamp(2.3rem, 4.5rem, 5.5vw)",
                      paddingTop: "1em",
                    }),
                  }}
                >
                  {item.catchphrase}
                </Text>
              )}
              {item.subtitle && <Text textStyle="title">{item.subtitle}</Text>}
              {item.subtext && <Text>{item.subtext}</Text>}
              {item.actions &&
                (() => {
                  if (item.label !== "Hero" && item.actions.length === 1) {
                    return (
                      <Link
                        to={item.actions[0].action || "/"}
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "auto",
                        }}
                        mt={3}
                        block
                        textStyle="footnote"
                        {...(item.actions[0].action.includes("https") && {
                          href: item.actions[0].action,
                          as: "a",
                        })}
                      >
                        <Icon
                          name="arrow-right"
                          mr={1}
                          style={{ position: "relative", top: "0.2em" }}
                        />
                        {item.actions[0].label}
                      </Link>
                    )
                  }

                  if (item.actions.length !== 2) {
                    return item.actions.map((action) => (
                      <Button
                        key={action.id}
                        center
                        ellipsis
                        secondary
                        {...(action.action === "/contact"
                          ? { onClick: toggle }
                          : { as: Link, to: action.action })}
                        mr={3}
                        style={{
                          pointerEvents: "auto",
                          ...(isNarrow && { width: "100%" }),
                        }}
                        bg={"black"}
                        color={"white"}
                      >
                        {action.label}
                      </Button>
                    ))
                  }

                  if (item.actions && item.actions.length === 2) {
                    return (
                      <SideBySide>
                        {item.actions.map((action) => (
                          <Button
                            key={action.id}
                            px={1}
                            pr={1}
                            center
                            secondary
                            {...(action.action === "/contact"
                              ? { onClick: toggle }
                              : { as: Link, to: action.action })}
                            mr={3}
                            style={{
                              pointerEvents: "auto",
                              ...(isNarrow && { width: "100%" }),
                            }}
                            mt={2}
                          >
                            {action.label}
                          </Button>
                        ))}
                      </SideBySide>
                    )
                  }
                })()}
            </Stack>
          </View>
        </Container>
      </View>
    </View>
  )
}
