import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
// @ts-ignore
import { useNavigate, useParams, Navigate } from "react-router-dom"
import { Stack, View, Text, Input, Button, Link } from "../../components"
import { useForm } from "react-hook-form"

import { useCreateModel } from "./ListModels"

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  center: true,
  bg: "white",
})`
  min-width: 24em;
  max-width: 26em;
`

export default ({
  refetch,
  propProductId,
}: {
  refetch?: Function
  propProductId?: string
}) => {
  const { productId } = useParams()

  const { register, handleSubmit, watch, errors, reset, clearError } = useForm()
  const [done, setDone] = useState(false)

  const navigate = useNavigate()
  const [newModel] = useCreateModel()

  const onSubmit = (data) => {
    const variables = {
      productId: parseInt(propProductId || productId, 10),
      title: data.title,
      ticket: data.ticket,
      backendTicket: data.backendTicket,
      parameterSectionsKey: data.parameterSectionsKey,
      parameters: data.parameters,
    }
    newModel({ variables }).then((data) => {
      console.log(data)
      if (!!refetch) {
        refetch()
        setDone(true)
      } else {
        setTimeout(() => navigate("../../"), 300)
      }
    })
  }

  return (
    <Container pt={6}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Text textStyle="title" mb={4}>
          {done ? "Success!" : "Add Model"}
        </Text>
        <Stack
          gap={4}
          style={{ ...(done && { opacity: 0.3, pointerEvents: "none" }) }}
        >
          <View>
            <Text textStyle="footnote">Title</Text>
            <Input
              name="title"
              mt={1}
              ref={register({ required: true, minLength: 1 })}
              autoFocus
            />
          </View>

          <View>
            <Text textStyle="footnote">Ticket</Text>
            <Input
              name="ticket"
              mt={1}
              ref={register({
                required: true,
                minLength: 6,
              })}
            />
          </View>

          <View>
            <Text textStyle="footnote">Backend Ticket</Text>
            <Input
              name="backendTicket"
              mt={1}
              ref={register({
                required: true,
                minLength: 6,
              })}
            />
          </View>

          <View>
            <Text textStyle="footnote">Parameter Sections Key</Text>
            <Input name="parameterSectionsKey" mt={1} ref={register()} />
          </View>

          <View>
            <Text textStyle="footnote">Parameters</Text>
            <Input name="parameters" mt={1} ref={register()} />
          </View>

          <View>
            <Button my={2} mr={3} as="button" type="submit">
              Submit
            </Button>
          </View>
        </Stack>
      </form>
    </Container>
  )
}
