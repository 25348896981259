import React, { useRef, useLayoutEffect, useState } from "react"
import { atom, useAtom } from "jotai"

export const outputAtom = atom<[any?]>([])

const useShapeDiver = (ticket, applyParameters) => {
  const containerSD: any = useRef(null)
  const api: any = useRef(null)
  const [parameters, setParameters] = useState<any | null>(null)
  const [editedParameters, setEditedParameters] = useState({})
  // const [output, setOutput] = useState<[any?]>([])
  const [_, setOutput] = useAtom(outputAtom)
  const setParameter = (id: string, value: any) => {
    // console.log(id, value)
    setEditedParameters({ ...editedParameters, ...{ [id]: value } })
  }

  async function setup() {
    // container for the viewer
    // here the reference works and the container is loaded correctly
    let _container = containerSD.current

    // ShapeDiver Viewer constructor settings
    // Refer to https://app.shapediver.com/api for details
    let settings = {
      container: _container,
      showSceneMode: 1, // do not show the scene automatically
      brandedMode: false,
    }
    // construct an instance of the viewer
    api.current = new window.SDVApp.ParametricViewer(settings)

    // register a ShapeDiver CommPlugin
    await api.current.plugins.registerCommPluginAsync({
      // ticket of the model as shown on app.shapediver.com
      ticket: ticket,
      // URL of the ShapeDiver backend system used
      modelViewUrl: "eu-central-1",
      // runtime id to use for this CommPlugin (you might register several)
      runtimeId: "CommPlugin_1",
      // the following setting tells the viewer to wait with loading of geometry
      deferGeometryLoading: true,
      //
    })
    console.log("ShapeDiver CommPlugin successfully loaded")

    // get parameters of the model
    const modelParameters = await api.current.parameters.get().data
    setParameters(modelParameters)
    console.log("Available model parameters", modelParameters)

    // optionally change parameter values before showing the scene
    console.log(applyParameters)
    if (applyParameters) {
      const parametersByName = [...Object.keys(applyParameters)].reduce(
        (collect, key) => {
          // find id, can be either name or key
          console.log("key", key)
          const parameter = modelParameters.find(
            (parameter) => parameter.id === key || parameter.name === key
          )
          if (!parameter) return collect
          return { ...collect, [parameter.name]: applyParameters[key] }
        },
        {}
      )
      console.log({
        parametersByName,
      })

      setEditedParameters({ ...parametersByName })
      // get data outputs:

      for (const parameter of Object.keys(parametersByName).map((key) => ({
        name: key,
        value: parametersByName[key],
      }))) {
        await api.current.parameters
          .updateAsync(parameter)
          .then((data) => console.log(data))
      }

      // console.log(api.current.scene.getData())
    }

    // refresh (load geometry), because the initial parameter update might not have changed any values
    await api.current.plugins.refreshPluginAsync("CommPlugin_1")
    console.log(api.current.scene.getData().data)
    setOutput(api.current.scene.getData().data)

    // finally show the scene
    await api.current.updateSettingAsync("scene.show", true)
  }

  console.log({ editedParameters })

  useLayoutEffect(() => {
    // initalize Viewer
    const element = window.document.getElementsByTagName("script")[0]
    const fjs = element
    let js = element
    js = window.document.createElement("script")
    js.id = "shape-diver"
    js.src = "https://viewer.shapediver.com/v2/2.17.0/sdv.concat.min.js"
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs)
    } else {
      window.document.head.appendChild(js)
    }
    js.onload = () => {
      setup()
    }

    return () => {
      //
    }
  }, [])

  const data = {}

  return {
    api: api.current,
    parameters,
    setParameters,
    setParameter,
    editedParameters,
    setEditedParameters,
    data,
  }
}

export default useShapeDiver
