import React from "react"
import styled from "styled-components"
import { View } from "./"

const Circle = styled(View)`
  width: 90px;
  height: 90px;
  border-radius: 45px;
`

export default Circle
