import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import gql from "graphql-tag"
import { useMutation } from "react-apollo"
import client from "../../user/authClient"
// @ts-ignore
import { useNavigate, useParams, Navigate } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  Input,
  Button,
  Link,
  Portal,
  Icon,
  Divider,
  Inline,
} from "../../components"
import ImageSelect from "../../components/ImageSelect"
import FilePreview from "../../components/FilePreview"
import { useForm } from "react-hook-form"
import { useUser } from "../../user/AuthProvider"

const CREATE_PROJECT = gql`
  mutation newProject(
    $name: String!
    $client: String
    $location: String
    $image: String
  ) {
    createProject(
      input: {
        name: $name
        client: $client
        location: $location
        image: $image
      }
    ) {
      project {
        id
        name
        client
        location
        image {
          url
          thumb
        }
      }
    }
  }
`

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  center: true,
  pt: 5,
})`
  max-width: 26em;
`

export default () => {
  const user = useUser()

  const [image, setImage] = useState(null)
  console.log({ image })

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    clearError,
    getValues,
  } = useForm()

  const navigate = useNavigate()
  const [newProject] = useMutation(CREATE_PROJECT, { client })

  const onSubmit = (data) => {
    const variables = {
      name: data.name,
      client: data.client,
      location: data.location,
      // @ts-ignore
      image: image?.replace(/^data:.+;base64,/, ""),
    }
    newProject({ variables }).then((data) => {
      console.log(data)
      setTimeout(() => navigate("../../"), 300)
    })
  }

  return (
    <>
      <Portal portal="dashboard-side">
        {user && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />
            <Inline as={Link} to="../../" back replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Text textStyle="title" mt={8} mb={8}>
              New Project
            </Text>
          </Stack>
        )}
      </Portal>

      <Container>
        <form noValidate={true} onSubmit={(e) => e.preventDefault()}>
          <Stack gap={4} mt={6}>
            <Text textStyle="title">Project Details</Text>
            <ImageSelect callback={(blob) => setImage(blob)} />
            {image && (
              <FilePreview
                blob={image}
                style={{ height: "20vh", objectFit: "fit" }}
              />
            )}
            <View>
              <Text textStyle="footnote">Name</Text>
              <Input
                name="name"
                mt={1}
                ref={register({ required: true, minLength: 1 })}
                autoFocus
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault()
                }}
              />
            </View>

            <View>
              <Text textStyle="footnote">Client</Text>
              <Input
                name="client"
                mt={1}
                ref={register()}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault()
                }}
              />
            </View>

            <View>
              <Text textStyle="footnote">Location</Text>
              <Input
                name="location"
                mt={1}
                ref={register()}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault()
                }}
              />
            </View>

            <View>
              <Button
                my={2}
                mr={3}
                as="button"
                onClick={() => onSubmit(getValues())}
              >
                Continue
              </Button>
            </View>
          </Stack>
        </form>
      </Container>
    </>
  )
}
