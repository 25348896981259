import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react"
import {
  // @ts-ignore
  Routes,
  Route,
  useParams,
  NavLink as RouterLink,
  // @ts-ignore
  useNavigate,
} from "react-router-dom"
import styled, { css } from "styled-components"
import useTheme from "../utilities/useTheme"
import {
  View,
  Stack,
  Grid,
  Text,
  Headline,
  Divider,
  Link,
  Button,
  RatioContainer,
  SideBySide,
  Icon,
  Portal,
} from "../components"
import Arrow from "../components/Arrow"
import { SanityImage } from "../components/SlideImage"
import SlidesGrid from "./Overview"
import Overview from "./Overview"
import Viewer from "./Viewer"
import {
  Container,
  OverviewContainer,
  NavigatorContainer,
  Sticky,
} from "./ProductsPage"
import friendlyUrl from "friendly-url"
import { useLocation } from "react-use"
import { useMedia } from "use-media"
import themeGet from "@styled-system/theme-get"

import productTypes from "../productTypes"
import ProductTypeProjects from "./ProductTypeProjects"
import FloorVariations from "./FloorVariations"
import {
  useCategoryOverviewQuery,
  useProductCategoryQuery,
} from "../sanity/sanityQueries"
import PromoBlocksOverview from "../components/PromoBlockOverview"
import useCategoryId from "../sanity/useCategoryId"
import FAQ from "./FAQ"
import DownloadItem from "../components/DownloadItem"
import useIsWide from "../utilities/useIsWide"
import ScrollToOnMatch from "../components/ScrollToOnMatch"

import StartProductAction from "../dashboard/product/StartProductAction"
import { useAuthOverlay } from "../user/AuthOverlay"
import SampleCard from "../components/SampleCard"
import { useUser } from "../user/AuthProvider"

const Inline = styled(View)<{ onClick?: any }>`
  display: flex;
  justify-content: space-between;
`
// Hook
const useOnScreen = (ref, rootMargin = "0px") => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.unobserve(ref.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}

interface ProductTypePageProps {
  children?: any
  productType?: string
  productTypeName?: string
}

const productTypeNameBySlug = (slug) =>
  productTypes.find((productType) => friendlyUrl(productType) === slug)

export const ScrollOrNavigate = ({
  path,
  noScroll,
}: {
  path: string
  noScroll?: boolean
}) => {
  const ref = useRef(null)
  const onScreen = useOnScreen(ref)
  const navigate = useNavigate()
  // multiple sectiona can be on screen, so navigate to the latest (show the latest in the nav bar)
  // or do not check whether the path is active..
  console.log(onScreen, path)
  useLayoutEffect(() => {
    if (
      onScreen === true &&
      window.sessionStorage.getItem("navigated") !== "click"
    ) {
      console.log("NAVIGATE TO ", path)
      window.sessionStorage.setItem("noScroll", "active")
      navigate(path, { replace: true })
    }
  }, [onScreen])
  return (
    <>
      <div
        ref={ref}
        style={{
          position: "absolute",
          width: 10,
          top: 0,
          bottom: "30vh",
        }}
      />
      {!noScroll && <ScrollToOnMatch path={path} />}
    </>
  )
}

export const NavLink = styled(Link).attrs(() => ({
  as: RouterLink,
  // activeStyle: { backgroundColor: "#88e" },
}))`
  transition: background-color 250ms ease-in-out;
  .active {
    display: none;
  }
  &[aria-current] {
    width: 100%;
    display: block;
    opacity: 1;
    pointer-events: none;
    .active {
      display: inline-block;
    }
  }
  & span {
    transition: color 250ms ease-in-out;
  }
  &[aria-current] span {
    color: black;
  }
`

const scrollMagic = {
  onClick: () => {
    window.sessionStorage.setItem("noScroll", "active")
    // setTimeout(() => window.sessionStorage.removeItem("noScroll"), 500)
    if (window.scrollY > 100) {
      // window.scrollTo(0, 94)
    }
  },
}

const sections = [
  { label: "The Product in Short", slug: "intro", path: "allOverview" },
  {
    label: "Design Possibilities",
    slug: "possibilities",
    path: "allPossibilities",
  },
  {
    label: "Sustainability",
    slug: "sustainability",
    path: "allSustainability",
  },
  { label: "The Product in Use", slug: "projects", path: "allInUse" },
  { label: "Pattern Library", slug: "patterns", path: "allDesignerVariations" },
  {
    label: "Materials & Colors Library",
    slug: "materials",
    path: "allMaterials",
  },
  { label: "Order Sample", slug: "samples", path: "ProductCategory.samples" },
  {
    label: "Downloads",
    slug: "downloads",
    path: "allDownloads",
    hideOnMobile: true,
  },
  { label: "F.A.Q.", slug: "faq", path: "allFaq", hideOnMobile: true },
  /* {
    label: "Tools & Services",
    slug: "tools-services",
    path: "allToolsServices",
  }, */
]

const ProjectContainer = styled(View)`
  display: flex;
  flex-wrap: nowrap;
  margin-left: -8px;
  margin-right: -8px;
  overflow-x: auto;
  align-items: stretch;
`

const FadeIn = styled(View)<{ visible?: boolean }>`
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity 350ms ease-out, transform 350ms ease-out;
  ${(props) =>
    props.visible === true &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`

const FadeInSection = (props) => {
  const [once, setOnce] = useState(false)
  const ref = useRef(null)
  const visible = useOnScreen(ref)
  useEffect(() => {
    if (visible && !once) setOnce(true)
  }, [visible])
  console.log(props.label, { visible, once })
  return (
    <div ref={ref}>
      <FadeIn visible={once} {...props} />
    </div>
  )
}

const SectionHeader = ({ title, text, closingDividers = false }) => {
  const isWide = useIsWide()
  return isWide ? (
    <FadeInSection label={title}>
      <SideBySide mt={7}>
        <View mb={4}>
          <Divider />
          <Text textStyle="title" my={5}>
            {title}
          </Text>
        </View>
        <View mb={4}>
          <Divider />
          <Text my={5}>{text}</Text>
        </View>
      </SideBySide>
      {closingDividers && (
        <SideBySide mt={0}>
          <View>
            <Divider my={0} />
          </View>
          <View>
            <Divider my={0} className="hide-on-wrap" />
          </View>
        </SideBySide>
      )}
    </FadeInSection>
  ) : (
    <View mt={7}>
      <Divider />
      <Stack gap={3} mt={5} mb={5}>
        <Text textStyle="title">{title}</Text>
        <Text>{text}</Text>
      </Stack>
      {closingDividers && <Divider my={0} />}
    </View>
  )
}

const EditorPortal = ({ children }) => (
  <Portal portal="editor">
    <View px={[2, 3, 3]}>{children}</View>
  </Portal>
)

export const useMemorizeScrollOrClick = () =>
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        const navigatedTime = window.sessionStorage.getItem("navigatedTime")
        const now = new Date().getTime()
        const timeAgo = now - parseInt(navigatedTime || "0", 10)

        const navigated = window.sessionStorage.getItem("navigated")

        if (navigated === "click" && timeAgo < 1000) {
          // do nothing
          console.log("Clicked, for sure")
        } else {
          window.sessionStorage.setItem("navigated", "scroll")
        }
        window.sessionStorage.setItem(
          "navigatedTime",
          new Date().getTime().toString()
        )
      },
      false
    )
  }, [])

const ProductTypePage = ({ dashboard = false }): JSX.Element => {
  const { navigateAuth, executeIfAuthenticated } = useAuthOverlay()

  const theme = useTheme()

  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })
  const isNarrow = useMedia({ maxWidth: 620 })

  const { productType } = useParams()
  // const { path, url } = useRouteMatch()
  const productTypeName = productTypeNameBySlug(productType)
  const location = useLocation()

  const showSubContent = true

  const { data: overviewData } = useCategoryOverviewQuery({
    variables: { slug: productType },
  })

  const productPageVariations =
    overviewData?.allProductCategory[0]?.productPageVariations ?? []
  console.log({ productPageVariations })

  const ID = useCategoryId(productType)
  console.log({ ID })

  const [loadCategory, { data: productTypeData }] = useProductCategoryQuery({
    variables: { id: ID },
  })
  // fetch product type content
  // lazily loaded, since ID is needed
  useEffect(() => {
    if (ID) {
      console.log("LOAD, DATA, " + ID)
      loadCategory()
    } else {
      console.log("NOT YET")
    }
  }, [ID])

  useMemorizeScrollOrClick()

  console.log({ productTypeData })
  console.log(overviewData?.allProductCategory?.[0])

  const PortalOrFragment = dashboard ? EditorPortal : React.Fragment

  const user = useUser()

  return (
    <Fragment>
      <Container>
        <OverviewContainer style={{ marginTop: theme.headerHeight }}>
          {sections.map((section, index) => {
            if (isNarrow && section.hideOnMobile === true) {
              return null
            }
            const samples =
              productTypeData?.[section.path.split(".")[0]]?.[
                section.path.split(".")[1]
              ]
            if (!!samples && user?.employee) {
              return (
                <View key={section.slug} relative>
                  <div style={{ position: "relative", top: -16 }}>
                    <ScrollOrNavigate
                      path={section.slug}
                      noScroll={index == 0}
                    />
                  </div>

                  <SectionHeader
                    title={"Order Sample"}
                    text={null}
                    closingDividers
                  />

                  <View style={{ display: "grid" }}>
                    <ProjectContainer>
                      {samples.map((sample) => (
                        <SampleCard
                          product={productTypeName}
                          sample={sample}
                          key={sample.id}
                        />
                      ))}
                    </ProjectContainer>
                  </View>
                </View>
              )
            }
            if (productTypeData?.[section.path]?.[0]) {
              const data = productTypeData[section.path][0]
              return (
                <View key={section.slug} relative>
                  {
                    <div style={{ position: "relative", top: -16 }}>
                      <ScrollOrNavigate
                        path={section.slug}
                        noScroll={index == 0}
                      />
                    </div>
                  }
                  {/* !isWide && (
                    <View
                      mb={1}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <View bg="background">
                        <Text pb={1} textStyle="footnote">
                          {section.label}
                        </Text>
                      </View>
                      <Divider mt={0} mb={0} />
                    </View>
                    )*/}
                  {data.promoBlocks && (
                    <PromoBlocksOverview
                      promoBlocks={productTypeData[section.path][0].promoBlocks}
                    />
                  )}

                  {section.path === "allInUse" && data && (
                    <View>
                      <SectionHeader
                        title={data.title}
                        text={data.text}
                        closingDividers
                      />
                      {data.projects?.map((project) => (
                        <Stack gap={1} mb={3}>
                          <ProjectContainer>
                            {project.slides.map((slide) => {
                              const ratio =
                                slide.image.image.asset.metadata.dimensions
                                  .width /
                                slide.image.image.asset.metadata.dimensions
                                  .height
                              const percentWidth =
                                ratio < 1
                                  ? "33.333%"
                                  : ratio < 2
                                  ? "66.666%"
                                  : "100%"
                              const containerRatio =
                                ratio < 1 ? 4 / 5 : ratio < 2 ? 8 / 5 : 12 / 5

                              return (
                                <View
                                  style={{
                                    minWidth: percentWidth,
                                  }}
                                >
                                  <RatioContainer ratio={containerRatio}>
                                    <View
                                      style={{
                                        position: "absolute",
                                        left: 8,
                                        top: 8,
                                        right: 8,
                                        bottom: 8,
                                      }}
                                    >
                                      <SanityImage source={slide.image.image} />
                                    </View>
                                  </RatioContainer>
                                </View>
                              )
                            })}
                          </ProjectContainer>
                          <Text textStyle="footnote">
                            Designed by {project.designer}
                          </Text>
                          <Text textStyle="footnote">For {project.client}</Text>
                        </Stack>
                      ))}
                    </View>
                  )}

                  {section.path === "allDesignerVariations" && (
                    <>
                      <SectionHeader title={data.title} text={data.text} />

                      {productPageVariations.map((variation) => (
                        <Stack gap={2} mb={0} key={variation.id}>
                          <SideBySide mt={0}>
                            <View>
                              <Divider my={0} />
                            </View>
                            <View>
                              <Divider my={0} className="hide-on-wrap" />
                            </View>
                          </SideBySide>

                          <RatioContainer ratio={4 / 2}>
                            <SanityImage source={variation.image} />
                          </RatioContainer>

                          <SideBySide mt={3}>
                            <View mb={3}>
                              {variation.title && (
                                <Text textStyle="title">
                                  {variation.title}{" "}
                                  {variation.showOnProductPage}
                                </Text>
                              )}
                            </View>
                            <Stack mb={3} gap={1}>
                              <Text textStyle="subhead">
                                {variation.designer}
                              </Text>
                              {variation.description && (
                                <Text textStyle="footnote">
                                  {variation.description}
                                </Text>
                              )}
                            </Stack>
                          </SideBySide>
                        </Stack>
                      ))}

                      {data.designerVariations
                        .filter(
                          (variation) =>
                            variation.showOnProductPage !== false &&
                            (variation.display === null ||
                              variation.display?.includes("PRODUCT_PAGE"))
                        )
                        .map((variation) => (
                          <Stack gap={2} mb={0}>
                            <SideBySide mt={0}>
                              <View>
                                <Divider my={0} />
                              </View>
                              <View>
                                <Divider my={0} className="hide-on-wrap" />
                              </View>
                            </SideBySide>

                            <RatioContainer ratio={4 / 2}>
                              <SanityImage source={variation.image} />
                            </RatioContainer>

                            <SideBySide mt={3}>
                              <View mb={3}>
                                {variation.title && (
                                  <Text textStyle="title">
                                    {variation.title}{" "}
                                    {variation.showOnProductPage}
                                  </Text>
                                )}
                              </View>
                              <Stack mb={3} gap={1}>
                                <Text textStyle="subhead">
                                  {variation.designer}
                                </Text>
                                {variation.description && (
                                  <Text textStyle="footnote">
                                    {variation.description}
                                  </Text>
                                )}
                              </Stack>
                            </SideBySide>
                          </Stack>
                        ))}
                    </>
                  )}
                  {section.path === "allMaterials" && (
                    <>
                      <SectionHeader title={data.title} text={data.text} />

                      <Grid
                        gridGap={[2, 3, 4]}
                        minColumnWidth={[180, 180, "15vw"]}
                        mb={5}
                      >
                        {data.materials.map((material) => (
                          <Stack gap={2} mb={2}>
                            <RatioContainer ratio={2 / 3}>
                              <SanityImage source={material.image} />
                            </RatioContainer>
                            <Stack gap={1}>
                              <Text textStyle="footnote">{material.code}</Text>
                              <Text textStyle="footnote">{material.title}</Text>
                              {material.description && (
                                <Text textStyle="footnote">
                                  {material.description}
                                </Text>
                              )}
                            </Stack>
                          </Stack>
                        ))}
                        <div />
                        <div />
                        <div />
                      </Grid>
                    </>
                  )}
                  {section.path === "allFaq" && data.questions && (
                    <>
                      <SectionHeader
                        title={data.catchphrase}
                        text={data.text}
                      />

                      <FAQ questions={data.questions} />
                    </>
                  )}

                  {section.path === "allDownloads" &&
                    productTypeData.allDownloads && (
                      <>
                        <SectionHeader
                          title={productTypeData.allDownloads[0]?.title}
                          text={productTypeData.allDownloads[0]?.text}
                        />
                        <View key="downloads">
                          {productTypeData.allDownloads[0]?.downloads.map(
                            (download) => (
                              <DownloadItem download={download} />
                            )
                          )}
                          <Divider my={0} />
                        </View>
                      </>
                    )}
                </View>
              )
            }
          })}
        </OverviewContainer>

        {isWide && (
          <NavigatorContainer
            {...(dashboard && { style: { display: "none" } })}
          >
            <Sticky>
              <Divider />
              <Link to="../" textStyle="footnote" back>
                <Inline>
                  <span>
                    {overviewData?.allProductCategory?.[0]
                      ? overviewData?.allProductCategory?.[0]?.parent?.name ||
                        "Product Categories"
                      : "Home"}
                  </span>
                  <Icon name="arrow-left" size={18} />
                </Inline>
              </Link>
              <Divider />
              <Text textStyle="headline">
                {overviewData?.allProductCategory?.[0].name ||
                  "Product Categories"}
              </Text>
              <Divider />
              <PortalOrFragment>
                <Stack gap={4} my={4}>
                  {overviewData?.allProductCategory?.[0].children &&
                  overviewData?.allProductCategory?.[0].children.length > 0
                    ? overviewData?.allProductCategory?.[0].children.map(
                        (category) => (
                          <View>
                            <NavLink
                              key={category.id}
                              to={"variants/" + category.slug?.current}
                              block
                              {...(category.disabled && {
                                style: { pointerEvents: "none", opacity: 0.4 },
                              })}
                            >
                              {category.name}
                            </NavLink>
                          </View>
                        )
                      )
                    : sections.map((section, index) => {
                        if (
                          productTypeData?.[section.path]?.[0] ||
                          productTypeData?.[section.path.split(".")[0]]?.[
                            section.path.split(".")[1]
                          ]
                        )
                          return section.slug == "samples" &&
                            !user?.employee ? null : (
                            <View
                              onMouseUp={() => {
                                window.sessionStorage.setItem(
                                  "navigated",
                                  "click"
                                )
                                window.sessionStorage.setItem(
                                  "navigatedTime",
                                  new Date().getTime().toString()
                                )
                              }}
                            >
                              <NavLink
                                to={section.slug}
                                block
                                noScroll={index > 0}
                                replace
                              >
                                <Icon
                                  className="active"
                                  name="arrow-right"
                                  style={{
                                    position: "relative",
                                    top: "0.05em",
                                  }}
                                  size={18}
                                />{" "}
                                {section.label}
                              </NavLink>
                            </View>
                          )
                      })}
                </Stack>
              </PortalOrFragment>
              {!!ID && !!productTypeData && (
                <StartProductAction categoryId={ID} />
              )}
              {/*
              <Button onClick={() => navigateAuth("/dashboard")}>
                Test Auth Overlay
              </Button>
              <Button
                onClick={() =>
                  executeIfAuthenticated(() =>
                    window.alert("Function to execute!")
                  )
                }
              >
                Test Auth Function
              </Button>
              */}
            </Sticky>
          </NavigatorContainer>
        )}
      </Container>
    </Fragment>
  )
}

export default ProductTypePage
