import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation, useLazyQuery } from "react-apollo"
import sanityClient from "../../sanity/sanityClient"
import { IMAGE } from "../../sanity/sanityQueries"

export const CLIENT_PRODUCT_QUERY = gql`
  query clientProduct($id: Int!) {
    product: clientProduct(id: $id) {
      id
      name
      description
      project {
        id
        name
      }
      image {
        url
      }
      category {
        id
        name
      }
      models {
        id
        title
        ticket
        parameters
      }
      files {
        id
        name
        versions {
          id
          url
          date
        }
      }
    }
  }
`

export const useProductQuery = (id) =>
  useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(id, 10) },
    client,
  })

export const CREATE_FILE_MUTATION = gql`
  mutation createFile($name: String!, $productId: Int!) {
    createFile(input: { name: $name, productId: $productId }) {
      file {
        id
        name
        versions {
          id
          url
          date
        }
      }
    }
  }
`

export const useCreateFile = () => useMutation(CREATE_FILE_MUTATION, { client })

export const CREATE_FILE_VERSION_MUTATION = gql`
  mutation createFile(
    $content: String
    $fileId: Int!
    $generator: Boolean
    $url: String
  ) {
    createFileVersion(
      input: {
        content: $content
        fileId: $fileId
        generator: $generator
        url: $url
      }
    ) {
      version {
        id
        url
        date
        generator
      }
    }
  }
`

export const useCreateVersion = () =>
  useMutation(CREATE_FILE_VERSION_MUTATION, {
    client,
  })

export const UPDATE_PRODUCT_NAME = gql`
  mutation updateProduct($id: Int!, $name: String!) {
    updateProduct(input: { id: $id, patch: { name: $name } }) {
      product {
        id
        name
      }
    }
  }
`

const GET_PRODUCT_TYPE_DETAILS = gql`
  query productTypeByOdooId($id: Float!) {
    allProductCategory(where: { odooProductCategoryId: { eq: $id } }) {
      id: _id
      name
      slug {
        current
      }
      odooProductCategoryId
      image {
        image {
          ${IMAGE}
        }
        mobile {
          ${IMAGE}
        }
        url
      }
    }
  }
`

const GET_PRODUCT_TYPE_SLUG = gql`
  query productTypeByOdooId($id: Float!) {
    allProductCategory(where: { odooProductCategoryId: { eq: $id } }) {
      id: _id
      name
      slug {
        current
      }
      odooProductCategoryId
      samples {
        id: _id
      }
    }
  }
`

const GET_PRODUCT_TYPE_SAMPLES = gql`
  query productTypeByOdooId($id: Float!) {
    allProductCategory(where: { odooProductCategoryId: { eq: $id } }) {
      id: _id
      name
      slug {
        current
      }
      odooProductCategoryId
      samples {
        id: _id
        price
        title
        displayName
        description
        image {
          ${IMAGE}
        }
      }
    }
  }
`

const PRODUCT_CATEGORIES = gql`
  query Categories {
    allProductCategory {
      id: _id
      name
      slug {
        current
      }
      enabledInProductChooser
      odooProductCategoryId
      image {
        image {
          ${IMAGE}
        }
        url
      }
      parent {
        id: _id
        name
        slug {
          current
        }
      }
    }
  }
`

export const useProductCategories = () =>
  useQuery(PRODUCT_CATEGORIES, {
    client: sanityClient,
  })

export const useProductTypeSlug = () =>
  useLazyQuery(GET_PRODUCT_TYPE_SLUG, { client: sanityClient })

export const useProductTypeSamples = () =>
  useLazyQuery(GET_PRODUCT_TYPE_SAMPLES, { client: sanityClient })

export const useProductTypeDetails = () =>
  useLazyQuery(GET_PRODUCT_TYPE_DETAILS, { client: sanityClient })
