import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import styled, { keyframes } from "styled-components"
import { useParams } from "react-router-dom"
import { useQuery, ChildDataProps } from "react-apollo"
import {
  View,
  RatioContainer,
  SideBySide,
  Headline,
  Text,
  Link,
  SlideImage,
  Center,
  Button,
  Stack,
  Inline,
} from "../components"
import { SanityImage } from "../components/SlideImage"
import Overview from "../components/Overview"
import {
  Container,
  Header,
  Body,
  Content,
  Aside,
  Footer,
} from "../components/Layout"
import useComponentSize from "@rehooks/component-size"
import useDimensions from "react-use-dimensions"
import { fit } from "../utilities/imageSize"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import BlockContent from "@sanity/block-content-to-react"

const NavContainer = styled(Text)`
  display: flex;
`

export const Navigator = ({
  active,
  count,
  previous,
  next,
  loop = true,
}: {
  active: number
  count: number
  previous: Function
  next: Function
  flex?: boolean
  loop?: boolean
}): JSX.Element => {
  return (
    <NavContainer>
      <Text
        inline
        style={{
          fontSize: "1.5em",
          lineHeight: "1em",
          ...(!loop && active === 0 && { pointerEvents: "none", opacity: 0.5 }),
        }}
        onClick={previous}
        pointer
      >
        ←
      </Text>
      <Text inline mx={2}>
        {active + 1}/{count}
      </Text>
      <Text
        inline
        style={{
          fontSize: "1.5em",
          lineHeight: "1em",
          ...(!loop &&
            active === count - 1 && { pointerEvents: "none", opacity: 0.5 }),
        }}
        onClick={next}
        pointer
      >
        →
      </Text>
    </NavContainer>
  )
}

export const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.92;
  }
`

const Slideshow = ({ slides, mb, isNarrow }): JSX.Element => {
  // const ref = useRef(null)
  // const canvas = useComponentSize(ref)
  const [active, setActive] = useState(0)
  const next = () => setActive(active === slides.length - 1 ? 0 : active + 1)
  const previous = () =>
    setActive(active === 0 ? slides.length - 1 : active - 1)
  const activeSlide = slides && slides[active]
  /*
  useLayoutEffect(() => {
    disableBodyScroll(ref.current)
    return () => clearAllBodyScrollLocks()
  })
  */
  return (
    <Container mb={mb}>
      <Header></Header>
      <Body>
        <Content
          style={{
            position: "relative",
          }}
        >
          {slides && (
            <Carousel
              showIndicators={false}
              showThumbs={false}
              useKeyboardArrows={true}
              showArrows={false}
              showStatus={false}
              selectedItem={active}
              onChange={(current) => setActive(current)}
              onClickItem={() => console.log("onClickItem")}
              swipeable={true}
              emulateTouch={true}
              autoPlay
              transitionTime={350}
              interval={3000}
              infiniteLoop={true}
            >
              {slides.map((slide, index) => {
                const ratio = isNarrow ? 2 / 3 : 16 / 8
                const dimensions = fit(
                  {
                    width: slide.asset.metadata.dimensions.width,
                    height: slide.asset.metadata.dimensions.height,
                  },
                  { width: 1000, height: 1000 / ratio }
                )
                return (
                  <RatioContainer ratio={ratio}>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <SanityImage source={slide} />
                    </div>
                  </RatioContainer>
                )
              })}
            </Carousel>
          )}
        </Content>
      </Body>
      <Footer pt={2}>
        <Inline>
          <Navigator
            flex
            {...{ active, count: slides && slides.length, previous, next }}
          />
          <Stack gap={1} pt={1} flex pl={[0, 1, 1]}>
            <Text textStyle="footnote">{slides[active].title}</Text>
            <Text textStyle="action">
              <BlockContent blocks={slides[active].descriptionRaw} />
            </Text>
          </Stack>
        </Inline>
      </Footer>
    </Container>
  )
}

Slideshow.displayName = "Slideshow"

export default Slideshow
