import * as React from "react"
import { useParams } from "react-router-dom"
import {
  Stack,
  Inline,
  Text,
  Link,
  Divider,
  Portal,
  Icon,
} from "../../components"
import client from "../../user/authClient"
import { useUser } from "../../user/AuthProvider"
import { useQuery } from "react-apollo"

import { CLIENT_PRODUCT_QUERY, useProductTypeSlug } from "./product-mutations"

const ProductInfoSide = () => {
  let { productId = "" } = useParams()
  const user = useUser()

  const { data } = useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
  })

  // Fetch product category based on Odoo Category Id:
  const [getCategory, { data: categoryData }] = useProductTypeSlug()

  React.useEffect(() => {
    if (data?.product?.category?.id) {
      getCategory({ variables: { id: data.product.category.id } })
    }
  }, [data])

  return (
    <Portal portal="dashboard-side">
      {data && (
        <Stack gap={2} px={[2, 3, 3]} mt={3}>
          <Divider />

          <Inline as={Link} to="../../../" replace verticalAlign="center">
            {user && (
              <Text flex textStyle="body">
                {user.company && user.company.length > 1
                  ? user.company
                  : user.name}
              </Text>
            )}
            <Icon name="arrow-left" size={18} />
          </Inline>
          <Divider />
          <Inline as={Link} to="../" replace verticalAlign="center">
            {user && (
              <Text flex textStyle="body">
                {data?.product?.project?.name}
              </Text>
            )}
            <Icon name="arrow-left" size={18} />
          </Inline>
          <Divider />

          <Inline as={Link} to="../" replace verticalAlign="center">
            {user && (
              <Text flex textStyle="body">
                {data?.product?.name}
              </Text>
            )}
            <Icon name="arrow-left" size={18} />
          </Inline>
          <Divider />
          {categoryData?.allProductCategory?.[0]?.name && (
            <Text textStyle="title" mt={8} mb={4}>
              {categoryData?.allProductCategory?.[0]?.name}
            </Text>
          )}
        </Stack>
      )}
    </Portal>
  )
}

export default ProductInfoSide
