import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { ApolloLink, concat } from "apollo-link"
import { InMemoryCache } from "apollo-cache-inmemory"

if (!process.env.REACT_APP_GRAPHQL_SANITY_ENDPOINT) {
  window.alert(
    "Please specify the Sanity graphql endpoint in the environment variables."
  )
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SANITY_ENDPOINT
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default client
