import React, { Component } from "react"
import { ApolloProvider } from "react-apollo"
import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { CachePersistor } from "apollo-cache-persist"
// yarn add graphql graphql-tag react-apollo apollo-client apollo-link-http apollo-cache-inmemory apollo-cache-persist

const cache = new InMemoryCache()

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debug: true,
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SIMPLE_ENDPOINT,
})

if (!process.env.REACT_APP_GRAPHQL_SIMPLE_ENDPOINT) {
  window.alert(
    "Please specify a graphql endpoint in the environment variables."
  )
}

const client = new ApolloClient({
  cache,
  link: httpLink,
})

export default class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restored: true,
    }
  }

  componentDidMount() {
    /*
    persistor
      .restore()
      .then(() => this.setState({ restored: true }))
      .catch(() => this.setState({ restored: true }))
      */
  }

  render() {
    if (!this.state.restored) {
      return <div>...</div>
    } else {
      return (
        <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
      )
    }
  }
}
