import React, { memo, useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import imageSize from "../utilities/imageSize"
import useComponentSize from "@rehooks/component-size"
import urlFor from "../sanity/imageUrl"

export const FadeImage = ({ src, style }: { src: string; style?: any }) => {
  const [loaded, setLoaded] = useState(false)
  let img = new window.Image()
  useEffect(() => {
    img.onload = () => {
      setLoaded(true)
    }
    img.src = src
    return () => {
      img.onload = null
      img.src = ""
    }
  }, [])
  return loaded ? <BackgroundImage src={src} style={style} /> : null
}

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const BackgroundImage = styled.div<{ src: string; style?: any }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${props => props.src});
  opacity: 0;
  animation: ${opacity} 0.7s ease forwards;
  animation-delay: 0;
  transform: translate3d(0, 0, 0);
`

const SlideImage = memo(({ slide }: { slide: any }) => {
  const secret = slide && slide.file && slide.file.secret
  // Ref for the element that we want to detect whether on screen
  const ref = useRef(null)
  const onScreen = useOnScreen(ref)

  let { width, height } = useComponentSize(ref)
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.

  return (
    <div
      ref={ref}
      style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
    >
      {secret && onScreen ? (
        <ImageDimensionsAndUrl width={width} height={height} secret={secret}>
          {({ url }) => <FadeImage src={url} />}
        </ImageDimensionsAndUrl>
      ) : null}
    </div>
  )
})

export const SanityImage = memo(({ source }: { source: any }) => {
  // Ref for the element that we want to detect whether on screen
  const ref = useRef(null)
  const onScreen = useOnScreen(ref)

  let { width, height } = useComponentSize(ref)
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.

  return (
    <div
      ref={ref}
      style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
    >
      {source && onScreen ? (
        <SanityImageDimensionsAndUrl
          width={width}
          height={height}
          source={source}
        >
          {({ url }) => <FadeImage src={url} />}
        </SanityImageDimensionsAndUrl>
      ) : null}
    </div>
  )
})

export const Image = ({
  src,
  slide,
  width,
  height
}: {
  src?: string
  slide?: any
  width: number
  height: number
}) => {
  const secret = slide && slide.file && slide.file.secret
  if (!secret && !!src) {
    return <FadeImage src={src} />
  }
  return (
    <ImageDimensionsAndUrl width={width} height={height} secret={secret}>
      {({ url }) => <FadeImage src={url} />}
    </ImageDimensionsAndUrl>
  )
}

// Hook
const useOnScreen = (ref, rootMargin = "0px") => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.unobserve(ref.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export default SlideImage

export const ImageDimensionsAndUrl = ({ width, height, secret, children }) => {
  const dimensions = imageSize({ width, height })
  const url = `https://images.graph.cool/v1/ciyt117ih64ym01326m6fpbfc/${secret}/${dimensions.width}x`
  return children({ ...dimensions, url })
}

export const SanityImageDimensionsAndUrl = ({
  width,
  height,
  source,
  children
}) => {
  const dimensions = imageSize({ width, height })
  const url = urlFor(source, dimensions)
  return children({ ...dimensions, url })
}
