import styled from "styled-components"
import View from "./View"

// always flex 1
export default styled(View)`
  flex: 1;
  @media (min-width: ${props =>
      props.theme.breakpoints
        ? props.theme.breakpoints[props.breakpoint || 1]
        : `46em`}) {
    flex: 1;
  }
`
