import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Fragment,
} from "react"
import useShapeDiver from "./useShapeDiver"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import { createGlobalStyle } from "styled-components"
import {
  View,
  Text,
  Label,
  Checkbox,
  Stack,
  Inline,
  InputRange,
  RatioContainer,
  DropZone as DropzoneComponent,
  Button,
  Input,
  ButtonOutline,
  Portal,
  Icon,
  Divider,
} from "../components"
import "react-input-range/lib/css/index.css"
import Dropzone from "react-dropzone"
import { useDebouncedCallback } from "use-debounce"
import Select from "react-select"
import { DateTime } from "luxon"
import { useProcessModel } from "../models/modelQueriesAndMutations"
import Spinner from "../components/Spinner"
import { useUser } from "../user/AuthProvider"
import { SanityImage } from "../components/SlideImage"

const defaultTicket =
  "20f9d15ecb236b79c4c342f2714558f571d34ae17fc3678f09a4e6742f28045119010401d66d47bd2f4c0a4a5ae92be36c560606effd0a98ec6ebdcec3975a5fd947d156338ff31e4a3c142a7eb4aa864f62d3db3938be957ab118903125b434296303731c19772d5a7fc89ae42348eba26888a80fdd-75013a6ebb275765f424fa8c2b7976a5"

declare global {
  interface Window {
    SDVApp: any
  }
}

type SnapshotFunctionType = (
  parameters: object,
  description: string | null
) => void

interface Snapshot {
  id: string
  description?: string
  parameters?: string
  createdAt: string
}

interface ViewerProps {
  ticket?: string
  propTicket?: string
  createSnapshot?: SnapshotFunctionType
  snapshots?: [Snapshot]
  saveParameters?: any
  saveSelectedPreset?: any
  modelId?: string | null
  secret?: string | null
  portal?: string
  processCallback?: Function
  parameterSections?: any
  preview?: boolean
  propParameters?: any
  presets?: any
  full?: boolean
  standalone?: boolean
  selectedPreset?: string | null
}

const RangeSliderStyles = createGlobalStyle`
  .input-range__slider.input-range__slider {
    background: black;
    border-color: black;
  }
  .input-range__track.input-range__track {
    background: #cccccc;
  }
  .input-range__track--active.input-range__track--active {
    background: black;
  }
  .input-range.input-range {
    // width: calc(100% - 12px);
  }
  .input-range__label {
    font-family: inherit;
  }
  .input-range__label--max .input-range__label-container.input-range__label-container {
    // left: 0;
  }
  .input-range__label--min .input-range__label-container.input-range__label-container {
    // left: 0;
  }
  .input-range__label--min.input-range__label--min, .input-range__label--max.input-range__label--max {
    bottom: -1.1rem;
  }
  .shapediver-iconnav {
    display: none;
  }
`

const convertToKeys = (editedParameters, parameters) => {
  const parametersByKey = [...Object.keys(editedParameters)].reduce(
    (collect, name) => {
      // find id, can be either name or key
      const parameter = parameters.find(
        // might be a key
        (parameter) => parameter.id === name || parameter.name === name
      )
      return { ...collect, [parameter.id]: editedParameters[name] }
    },
    {}
  )
  return parametersByKey
}

const Container = styled(View)`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const ModelContainer = styled(View)`
  position: relative;
  width: 100%;
  height: 100%;
`

const GrayScale = styled(Text)`
  filter: grayscale(1);
`

export default ({
  standalone,
  propTicket,
  snapshots,
  createSnapshot,
  saveParameters,
  saveSelectedPreset,
  selectedPreset,
  modelId,
  portal,
  secret,
  processCallback,
  parameterSections,
  preview,
  propParameters: initPropParemeters,
  presets,
  full,
}: ViewerProps) => {
  const user = useUser()
  const [propParameters] = useState({ ...initPropParemeters })
  console.log({ propParameters })
  // load the viewer...
  const { ticket = defaultTicket } = useParams()
  const [downloads, setDownloads] = useState<[any?]>([])
  const [selectedSnapshot, setSelectedSnapshot] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [activeSection, activateSection] = useState<string | null>(null)

  const ref = useRef(null)

  const {
    api,
    parameters,
    setParameters,
    setParameter,
    editedParameters,
    setEditedParameters,
  } = useShapeDiver(propTicket || ticket, propParameters)

  const [debouncedCallback] = useDebouncedCallback((name, value) => {
    api.parameters.updateAsync({ name, value })
  }, 350)

  const updateParameter = (name, value, debounce = true) => {
    if (api) {
      // api.parameters.updateAsync({ id, value })
      if (debounce === false) {
        console.log("directly update")
        console.log(api.parameters)
        api.parameters
          .updateAsync({ name, value })
          .then((zo) => console.log(zo))
      } else {
        debouncedCallback(name, value)
      }
      // for the collection of edited parameters:
      setParameter(name, value)
      // also reflect this in 'local' state:
      const parametersByName = parameters.map((parameter) =>
        parameter.name === name ? { ...parameter, value } : parameter
      )
      setParameters(parametersByName)
    }
  }

  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current === true) {
      isFirstRun.current = false
      return
    }
    console.log({ editedParameters })
    !!saveParameters && saveParameters(editedParameters)
  }, [editedParameters])

  const applySnapshotValues = (id) => {
    const snapshot = snapshots?.find((snapshot) => snapshot.id === id)
    if (typeof snapshot?.parameters === "string") {
      const snapShotParameters = JSON.parse(snapshot.parameters)
      console.log("snapshotValues", { snapShotParameters })
      if (snapShotParameters instanceof Array) {
        // love backwards compatibility
        snapShotParameters.forEach((parameter) => {
          api.parameters.updateAsync({
            id: parameter.id,
            value: parameter.value,
          })
          setParameter(parameter.id, parameter.value)
          api.parameters.updateAsync({
            id: parameter.id,
            value: parameter.value,
          })
        })
      } else {
        setEditedParameters({ ...snapShotParameters })
        Object.keys(snapShotParameters).forEach((key) => {
          setParameter(key, snapShotParameters[key])
          api.parameters.updateAsync({
            id: key,
            value: snapShotParameters[key],
          })
        })
      }
      // some day: get rid of syncing parameters
      // setParameters(JSON.parse(snapshot.parameters))
    }
  }

  const applyPreset = (title, presetParameters) => {
    console.log(title, { presetParameters })
    if (!!saveSelectedPreset) {
      saveSelectedPreset(title)
    }
    // todo: save 'selectedPreset' to model
    const parametersByName = [...Object.keys(presetParameters)].reduce(
      (collect, key) => {
        // find id, can be either name or key
        const parameter = parameters.find(
          (parameter) => parameter.id === key || parameter.name === key
        )
        return { ...collect, [parameter.name]: presetParameters[key] }
      },
      {}
    )
    setEditedParameters({ ...parametersByName })
    Object.keys(parametersByName).forEach((name) => {
      setParameter(name, parametersByName[name])
      api.parameters.updateAsync({
        name,
        value: parametersByName[name],
      })
    })
  }

  const options = snapshots?.map((snapshot) => ({
    id: snapshot.id,
    label: `${
      snapshot.description ? snapshot.description + " " : ""
    }${DateTime.fromISO(snapshot.createdAt).toRelative({
      locale: "en",
    })}`,
  }))

  useEffect(() => {
    if (api && api.exports.get()?.data?.[0]) {
      setDownloads(api.exports.get().data)
    }
  }, [parameters])

  const downloadFile = (index) => {
    const data = api.exports.get()
    if (data?.data) {
      console.log(data.data)
      api.exports
        .requestAsync({ name: data?.data[index].name })
        .catch(function(err) {
          return Promise.reject(err)
        })
        .then(function(response) {
          console.log("EXPORT DONE!", response)
        })
    } else {
      window.alert("No downloads available.")
    }
  }

  const saveSnapshot = () => {
    if (createSnapshot) {
      const name = window.prompt("Name this snapshot")
      createSnapshot(editedParameters, name)
    }
  }

  const [processModel, { data, error }] = useProcessModel({})

  useEffect(() => setProcessing(false), [data, error])

  const EditorContainer = portal ? Portal : Fragment
  const RatioContainerOr = full ? Fragment : RatioContainer

  return (
    <>
      <RangeSliderStyles />
      <RatioContainerOr
        {...(!full && { ratio: preview ? 3 / 1 : 16 / 9 })}
        {...(preview && {
          style: {
            display: "flex",
            width: "calc(100% - 210px)",
            marginLeft: 2,
          },
        })}
      >
        <Container
          {...(preview && {
            style: {
              outline: "1px solid black",
              left: 2,
              right: 2,
              top: 1,
              bottom: 2,
            },
          })}
        >
          <ModelContainer {...(full && { height: "100%" })}>
            <div
              ref={ref}
              id="sdv-container"
              className="shapediver-container-flex shapediver-container-fullsize"
              sdv-fullscreen="true"
            >
              <div
                id="sdv-container-settings"
                className="shapediver-settings-flex"
                style={{ display: "none" }}
              ></div>
              <div className="shapediver-viewport-flex">
                <div
                  id="sdv-container-viewport"
                  className="shapediver-container-fullsize"
                  style={{ opacity: 0 }}
                ></div>
              </div>
              <div
                id="sdv-container-controls"
                className="shapediver-controls-flex"
                style={{ display: "none" }}
              ></div>
            </div>
          </ModelContainer>
          <EditorContainer {...(!!portal && { portal })}>
            {activeSection ? (
              <View px={portal ? [2, 3, 3] : [2, 2, 2]}>
                <Inline
                  onClick={() => activateSection(null)}
                  replace
                  verticalAlign="center"
                  my={2}
                  pointer
                  hover
                >
                  <Text flex textStyle="body">
                    Design
                  </Text>
                  <Icon name="arrow-left" size={18} />
                </Inline>
                <Divider mt={0} mb={0} />
              </View>
            ) : null}
            <View
              px={portal ? [2, 3, 3] : [2, 2, 2]}
              style={{
                ...(!portal && { width: 310 }),
                height: "100%",
                overflowX: "hidden",
                boxSizing: "content-box",
                ...(preview && { display: "none" }),
              }}
            >
              <Stack gap={2}>
                {!preview && parameters && createSnapshot && (
                  <View mb={4}>
                    <GrayScale pt={1} textStyle="footnote">
                      <Select
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: "9999 !important",
                            filter: "grayScale(1)",
                          }),
                        }}
                        menuPortalTarget={document.querySelector("#app")}
                        placeholder="Select Snapshot..."
                        options={options}
                        onChange={(item) => {
                          applySnapshotValues(item.id)
                          setSelectedSnapshot(item)
                        }}
                        value={selectedSnapshot}
                      />
                    </GrayScale>
                    <Button
                      mt={2}
                      textStyle="footnote"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                      py={2}
                      onClick={saveSnapshot}
                    >
                      Save Snapshot
                    </Button>
                  </View>
                )}
                {!parameterSections && !preview && (
                  <Text textStyle="title" mt={4} mb={3}>
                    Design
                  </Text>
                )}
                {!preview && parameterSections && (
                  <Stack gap={2}>
                    {activeSection ? null : (
                      <Text textStyle="title" mt={6} mb={4}>
                        {activeSection && (
                          <Icon name="arrow-left" size={18} mr={1} />
                        )}
                        Design
                      </Text>
                    )}
                    {!activeSection && (
                      <Stack gap={3}>
                        {parameterSections
                          .filter(
                            (section) =>
                              !!parameters &&
                              parameters.find((parameter) =>
                                parameter.name.includes(section.key)
                              )
                          )
                          .map((section) => (
                            <Text
                              textStyle="action"
                              color={
                                activeSection === section.key ? "black" : "#888"
                              }
                              hover
                              pointer
                              onClick={() => activateSection(section.key)}
                            >
                              {section.label}
                            </Text>
                          ))}
                      </Stack>
                    )}
                    {activeSection && (
                      <Text textStyle="title" mt={6}>
                        {
                          parameterSections.find(
                            (section) => section.key === activeSection
                          )?.label
                        }
                        <Text textStyle="footnote" mt={2} mb={6}>
                          {
                            parameterSections.find(
                              (section) => section.key === activeSection
                            )?.note
                          }
                        </Text>
                      </Text>
                    )}
                  </Stack>
                )}
                {activeSection === "PATTERN" && presets && (
                  <View mb={4}>
                    {presets.map((preset) => (
                      <View
                        style={{
                          width: "45%",
                          display: "inline-block",
                        }}
                        mr={1}
                        onClick={() => {
                          // sanitize!
                          applyPreset(
                            preset.title,
                            JSON.parse(preset.parameters)
                          )
                        }}
                        pointer
                      >
                        <Text textStyle="footnote" mb={1}>
                          {preset.title}
                        </Text>
                        <RatioContainer
                          ratio={1}
                          style={{
                            ...(selectedPreset === preset.title && {
                              outline: "2px solid black",
                            }),
                          }}
                        >
                          <SanityImage source={preset.image} />
                        </RatioContainer>
                      </View>
                    ))}
                  </View>
                )}

                {!preview && parameters && (
                  <Stack gap={2}>
                    {parameters
                      .filter(
                        (parameter) =>
                          !parameterSections ||
                          (activeSection &&
                            parameter.name.includes(activeSection))
                      )
                      .map((parameter) => {
                        if (parameter.hidden === true) return null
                        switch (parameter.type) {
                          case "String":
                            return (
                              <ButtonOutline
                                onClick={() => {
                                  const url = window.prompt("Enter Url")
                                  if (url && url.length > 5)
                                    updateParameter(parameter.name, url)
                                }}
                                width="100%"
                                py={1}
                                textStyle="footnote"
                                block
                                my={2}
                              >
                                File Url
                              </ButtonOutline>
                            )
                          case "Bool":
                            return (
                              <Label key={parameter.id}>
                                <Checkbox
                                  checked={parameter.value}
                                  onChange={(event) =>
                                    updateParameter(
                                      parameter.name,
                                      event.target.checked
                                    )
                                  }
                                />
                                <span>
                                  {parameter.name
                                    .split(`${activeSection} - `)
                                    .join("")}
                                </span>
                              </Label>
                            )
                          case "Int":
                            return parameter.visualization === "slider" ? (
                              <Fragment key={parameter.id}>
                                <Label>
                                  {parameter.name
                                    .split(`${activeSection} - `)
                                    .join("")}
                                </Label>
                                <View mt={3} mb={4}>
                                  <InputRange
                                    maxValue={parameter.max}
                                    minValue={parameter.min}
                                    value={
                                      editedParameters[parameter.id] ||
                                      parameter.value
                                    }
                                    onChange={(value) => {
                                      if (typeof value === "number")
                                        updateParameter(parameter.name, value)
                                    }}
                                  />
                                </View>
                              </Fragment>
                            ) : null
                          case "Float":
                            return parameter.visualization === "slider" ? (
                              <Fragment key={parameter.id}>
                                <Label>
                                  {parameter.name
                                    .split(`${activeSection} - `)
                                    .join("")}
                                </Label>
                                <View mt={3} mb={4}>
                                  <InputRange
                                    maxValue={parameter.max}
                                    minValue={parameter.min}
                                    value={
                                      editedParameters[parameter.name]
                                        ? editedParameters[
                                            parameter.name
                                          ].toFixed(2)
                                        : parameter.value.toFixed(2)
                                    }
                                    step={0.05}
                                    onChange={(value) => {
                                      if (typeof value === "number")
                                        updateParameter(parameter.name, value)
                                    }}
                                  />
                                </View>
                              </Fragment>
                            ) : null
                          case "File":
                            const extension = parameter.format.find((type) =>
                              type.includes("dxf")
                            )
                              ? "dxf"
                              : "jpg/png"
                            return (
                              <Fragment key={parameter.id}>
                                <Label>
                                  {parameter.name
                                    .split(`${activeSection} - `)
                                    .join("")}
                                </Label>
                                <View width={248} mt={3} mb={4}>
                                  <Dropzone
                                    onDrop={(acceptedFiles) =>
                                      updateParameter(
                                        parameter.name,
                                        acceptedFiles[0]
                                      )
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <DropzoneComponent {...getRootProps()}>
                                        <Text textStyle="footnote">
                                          Select file ({extension})
                                        </Text>
                                        <input {...getInputProps()} />
                                      </DropzoneComponent>
                                    )}
                                  </Dropzone>
                                </View>
                              </Fragment>
                            )
                          case "StringList":
                            return (
                              <Fragment key={parameter.id}>
                                <Label mb={2}>
                                  {parameter.name
                                    .split(`${activeSection} - `)
                                    .join("")}
                                </Label>
                                <View mb={4}>
                                  {parameter.choices.map((value, index) => {
                                    return (
                                      <>
                                        {parameterSections &&
                                          parameterSections
                                            .find(
                                              (section) =>
                                                section.key === activeSection
                                            )
                                            ?.valueImages?.filter(
                                              (valueImage) =>
                                                valueImage.value === value
                                            )
                                            .map((valueImage) => (
                                              <View
                                                style={{
                                                  width: "45%",
                                                  display: "inline-block",
                                                }}
                                                mr={1}
                                                onClick={() => {
                                                  updateParameter(
                                                    parameter.name,
                                                    index,
                                                    false
                                                  )
                                                }}
                                                pointer
                                              >
                                                <RatioContainer ratio={1}>
                                                  <SanityImage
                                                    source={valueImage.image}
                                                  />
                                                </RatioContainer>
                                              </View>
                                            ))}
                                      </>
                                    )
                                  })}
                                </View>
                              </Fragment>
                            )
                          default:
                            return null
                        }
                      })}
                  </Stack>
                )}
              </Stack>

              {data?.processModel?.files && (
                <>
                  <Text mb={2}>Files generated on backend:</Text>
                  <Text textStyle="footnote">
                    {data.processModel.files[0].msg}
                  </Text>
                  <a href={data.processModel.files[0].url}>
                    <Text textStyle="footnote" mt={2}>
                      {data.processModel.files[0].url}
                    </Text>
                  </a>
                  <br />
                  <Text textStyle="footnote">
                    {data.processModel.files[1].msg}
                  </Text>

                  <a href={data.processModel.files[1].url}>
                    <Text textStyle="footnote" mt={2}>
                      {data.processModel.files[1].url}
                    </Text>
                  </a>
                </>
              )}
              {user.employee && !processing && (
                <Button
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    textAlign: "center",
                  }}
                  px={0}
                  textStyle="footnote"
                  py={2}
                  onClick={() => {
                    setProcessing(true)
                    processModel({
                      variables: {
                        id: secret ? null : modelId || null,
                        secret: secret || null,
                        // make sure these are based on / converted to keys..!
                        parameters: JSON.stringify(
                          convertToKeys(editedParameters, parameters)
                        ),
                      },
                    })
                      .then(({ data }) => {
                        if (processCallback) {
                          processCallback(data.processModel.files)
                        }
                      })
                      .catch((err) => {
                        window.alert("Error processing model. " + err?.message)
                        console.log({ err })
                      })
                  }}
                  mt={3}
                  disabled={processing}
                >
                  Process Model
                </Button>
              )}
              {processing && <Spinner mt={2} />}
            </View>
          </EditorContainer>
        </Container>
      </RatioContainerOr>
      {standalone && (
        <Text my={4}>
          <pre
            style={{
              maxHeight: 300,
              fontSize: "0.65em",
              overscrollBehaviorY: "contain",
            }}
          >
            {JSON.stringify(parameters, null, 2)}
          </pre>
        </Text>
      )}
    </>
  )
}
