import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import client from "../../user/authClient"
// @ts-ignore
import { useNavigate, useParams, Navigate, useLocation } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  Input,
  Button,
  Link,
  Portal,
  Icon,
  Divider,
  Inline,
  RatioContainer,
  Grid,
} from "../../components"
import ImageSelect from "../../components/ImageSelect"
import FilePreview from "../../components/FilePreview"
import { useForm } from "react-hook-form"
import { useUser } from "../../user/AuthProvider"
import axios from "axios"
import {
  CLIENT_PRODUCT_QUERY,
  useProductTypeSamples,
} from "../product/product-mutations"
import Spinner from "../../components/Spinner"
import { SanityImage } from "../../components/SlideImage"
import { useCheckoutOverlay } from "../../components/CheckoutOverlay"
import SampleCard from "../../components/SampleCard"

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  pt: 5,
})`
  position: relative;
`

const OverlayCenter = styled(View).attrs(() => ({ pr: 3 }))`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;

  flex-direction: row;
  &::before,
  &::after {
    content: "";
    flex: 1;
  }

  align-items: center;
  overflow: auto;
`

const SampleContainer = styled(View)<{ last: Boolean }>``

export const DetailsForm = ({ product, amount, sample }) => {
  const [processing, setProcessing] = useState(false)
  const user = useUser()

  const navigate = useNavigate()

  const location = useLocation()
  console.log({ location })

  const purchase = (details) => {
    setProcessing(true)
    axios({
      method: "GET",
      url: "https://aectual-process-payment.vercel.app/api/payment",
      params: {
        // name: details.name,
        // email: user.email,
        amount: details.amount,
        metadata: details.metadata,
        description: details.description,
        redirectUrl:
          window.location.origin +
          "/success?path=" +
          location.pathname.replace("/sample", ""),
      },
    })
      .then((data) => {
        console.log(data)
        // @ts-ignore
        window.location = data.data.payment._links.checkout.href
      })
      .catch(() => {
        setProcessing(false)
        window.alert("Something went wrong, please contact support")
      })
  }

  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    const details = {
      metadata: `
${data.name}\n
${user.email || data.email}\n
\n
Product: ${product}\n
Street: ${data.street}\n
City: ${data.city}\n
Zip: ${data.zip}\n
Country: ${data.country}\n
Notes: ${data.notes}\n
      `,
      amount: (1.21 * parseFloat(amount)).toFixed(2),
      description: product,
    }
    purchase(details)
  }

  if (!sample) return null

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Stack gap={2} style={{ minWidth: "24em" }}>
          <Stack gap={1}>
            <Text textStyle="title">{sample.displayName}</Text>
            <Text textStyle="footnote">
              € {sample.price},-
              <br />
              Total: € {(1.21 * parseFloat(sample.price)).toFixed(2)} (VAT 21%)
            </Text>
          </Stack>
          <Text textStyle="action" mt={4}>
            Enter your details
          </Text>
          <View>
            <Text textStyle="footnote">Name</Text>
            <Input
              name="name"
              mt={1}
              ref={register({ required: true })}
              {...(user?.name && { defaultValue: user?.name })}
              bg="tertiaryBackground"
              autoFocus
            />
          </View>
          {!user.email && (
            <View>
              <Text textStyle="footnote">Email</Text>
              <Input
                name="name"
                mt={1}
                ref={register({ required: true })}
                bg="tertiaryBackground"
              />
            </View>
          )}
          <View>
            <Text textStyle="footnote">Street</Text>
            <Input
              name="street"
              mt={1}
              ref={register({ required: true })}
              bg="tertiaryBackground"
            />
          </View>
          <View>
            <Text textStyle="footnote">City</Text>
            <Input
              name="city"
              mt={1}
              ref={register({ required: true })}
              bg="tertiaryBackground"
            />
          </View>
          <View>
            <Text textStyle="footnote">Zip</Text>
            <Input
              name="zip"
              mt={1}
              ref={register({ required: true })}
              bg="tertiaryBackground"
            />
          </View>
          <View>
            <Text textStyle="footnote">Country</Text>
            <Input
              name="country"
              mt={1}
              ref={register()}
              bg="tertiaryBackground"
            />
          </View>
          <View>
            <Text textStyle="footnote">Notes</Text>
            <Input
              as="textarea"
              name="notes"
              mt={1}
              ref={register()}
              style={{ minHeight: 140 }}
              bg="tertiaryBackground"
            />
          </View>
          <Button my={2} mr={3} mb={7} as="button" type="submit">
            Continue Payment
          </Button>
        </Stack>
      </form>
      {processing && (
        <OverlayCenter>
          <Spinner />
        </OverlayCenter>
      )}
    </>
  )
}

export default () => {
  const user = useUser()

  let { productId = "" } = useParams()

  const { data, refetch } = useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
    fetchPolicy: "network-only",
  })

  console.log({ data })

  // Fetch product category+samples based on Odoo Category Id:
  const [getCategory, { data: samplesData }] = useProductTypeSamples()

  React.useEffect(() => {
    if (data?.product?.category?.id) {
      getCategory({ variables: { id: data.product.category.id } })
    }
  }, [data])

  console.log({ samplesData })

  const { toggle, setProduct, setAmount, setSample } = useCheckoutOverlay()

  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />

            <Inline
              as={Link}
              to="../../../../../"
              replace
              verticalAlign="center"
            >
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.project?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider mb={0} />
            <Text textStyle="title" mt={8} mb={8}>
              Sample Request
            </Text>
          </Stack>
        )}
      </Portal>
      <Container>
        <Grid gridGap={[3, 4, 5]} minColumnWidth={[180, 270, 310]}>
          {samplesData &&
            samplesData.allProductCategory[0]?.samples?.map(
              (sample, index, samples) => (
                <SampleCard
                  key={sample.id}
                  sample={sample}
                  product={sample.displayName}
                />
              )
            )}
        </Grid>
      </Container>
    </>
  )
}
