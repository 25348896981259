import React from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import {
  Stack,
  Inline,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
  Portal,
  Icon,
} from "../../components"
import { SanityImage } from "../../components/SlideImage"

import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import { useCreateProduct } from "../project/ProjectPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import { useProjectQuery } from "../project/ProjectPage"
import { useCreateModel } from "../model/ListModels"
import sanityClient from "../../sanity/sanityClient"
import { IMAGE } from "../../sanity/sanityQueries"
import { useUser } from "../../user/AuthProvider"
import { useContactOverlay } from "../../components/ContactOverlay"

const DESIGNER_VARIANTS = gql`

  fragment variantFields on DesignerVariationDocument {
    id: _id
    key: title
    title: displayName
    designer
    author {
      name
    }
    ticket
    secret
    image {
      ${IMAGE}
    }
    presets {
      id: _key
      title
      parameters
      image {
        ${IMAGE}
      }
    }
  }

  query Variants($slug: String!) {
    allProductCategory(where: { slug: { current: { eq: $slug } } }) {
      id: _id
      name
      slug {
        current
      }
      odooProductCategoryId
      dashboardVariations {
        ...variantFields
      }
      earlyAccessVariations {
        ...variantFields
      }
      testVariations {
        ...variantFields
      }
    }
    allDesignerVariations {
      category {
        id: _id
        name
        slug {
          current
        }
        odooProductCategoryId
        testVariations {
          ...variantFields
        }
      }
      title
      catchphrase
      designerVariations {
        id: _key
        title
        display
        access
        designer
        author {
          name
        }
        ticket
        secret
        image {
          ${IMAGE}
        }
        presets {
          id: _key
          title
          parameters
          image {
            ${IMAGE}
          }
        }
      }
    }
  }
`

export const Item = ({ item, onClick }) => (
  <Stack gap={2}>
    <RatioContainer
      ratio={1}
      bg="#EEE"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <SanityImage source={item?.image} />
    </RatioContainer>
    <Stack gap={1}>
      <Text textStyle="action">{item.title || item.name}</Text>
      <Text textStyle="footnote">
        {(item.author?.name || item.designer) && "by "}
        {item.author?.name || item.designer}
      </Text>
    </Stack>
  </Stack>
)

// todo: when selecting a design: create the product with the currect odooProductCategoryId
// then create the first model (mutation also) with the correct ticket

const ChooseVariant = () => {
  const user = useUser()
  const { toggle } = useContactOverlay()

  let { projectId = "", variantSlug = "curve" } = useParams()
  const { data, refetch } = useProjectQuery(projectId)

  const { data: sanityData } = useQuery(DESIGNER_VARIANTS, {
    client: sanityClient,
    variables: { slug: variantSlug },
  })

  // *new*

  const dashboardVariations =
    sanityData?.allProductCategory[0]?.dashboardVariations ?? []
  const earlyAccessVariations =
    ((user?.earlyAccess || user?.employee) &&
      sanityData?.allProductCategory[0]?.earlyAccessVariations) ||
    []
  const testVariations =
    (user?.employee && sanityData?.allProductCategory[0]?.testVariations) || []

  console.log({ testVariations })
  const newVariations = sanityData
    ? [
        ...dashboardVariations,
        ...earlyAccessVariations,
        ...testVariations,
      ].reduce(
        (collect, variant) =>
          !variant.presets
            ? [...collect, variant]
            : [
                ...collect,
                variant,
                ...variant.presets.map((preset) => ({
                  ...variant,
                  ...preset,
                })),
              ],
        []
      )
    : []

  const variationIds = newVariations.map((variation) => variation.id)

  const withoutDuplicates = Array.from(new Set(variationIds)).map((id) =>
    newVariations.find((variation) => variation.id === id)
  )

  console.log({ withoutDuplicates })
  // /

  const variants = sanityData?.allDesignerVariations.find(
    (set) => set.category?.slug.current === variantSlug
  )

  const [createProduct] = useCreateProduct()
  const [createModel] = useCreateModel()

  const navigate = useNavigate()

  const odooProductCategoryId =
    sanityData?.allProductCategory[0]?.odooProductCategoryId

  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <>
            <Stack gap={2} px={[2, 3, 3]} mt={3}>
              <Divider />

              <Inline
                as={Link}
                to="../../../../"
                replace
                verticalAlign="center"
              >
                {user && (
                  <Text flex textStyle="body">
                    {user.company && user.company.length > 1
                      ? user.company
                      : user.name}
                  </Text>
                )}
                <Icon name="arrow-left" size={18} />
              </Inline>
              <Divider />
              <Inline as={Link} to="../../../" replace verticalAlign="center">
                {user && (
                  <Text flex textStyle="body">
                    {data?.project?.name}
                  </Text>
                )}
                <Icon name="arrow-left" size={18} />
              </Inline>
              <Divider />
              <Text textStyle="title" mt={8} mb={8}>
                {variants?.category?.name?.includes("Terrazzo")
                  ? "Add floor"
                  : "Choose Your Pattern"}
              </Text>

              {variants?.category?.name?.includes("Terrazzo") && (
                <Text textStyle="action"> Upload your floor design</Text>
              )}

              {variants?.category?.name?.includes("Terrazzo") ? (
                <Text textStyle="body">
                  Within the floor product you can create your own custom
                  pattern. Upload your pattern and floorplan to get feedback on
                  printability, get a quotation and see how your pattern fits
                  and enriches your space design by choosing colors and infills.
                  <br />
                  <br />
                  When you would love to use one of our design patterns you can
                  click on your favorite to start the process and make it yours.
                </Text>
              ) : (
                <Text textStyle="body">
                  Start your design process by selecting the pattern you like as
                  a starting point for your custom design.
                  {variants?.category?.name?.includes("Curve") && (
                    <>
                      Within the curve product you can customize the pattern
                      online, adjust product sizes and colors to fit your
                      project.
                      <br />
                      <br />
                      If you want to use your own design as a starting point
                      please{" "}
                      <Text
                        onClick={toggle}
                        hover
                        pointer
                        style={{
                          textDecoration: "underline",
                          display: "inline-block",
                        }}
                      >
                        contact us
                      </Text>{" "}
                      and we can upload your design in your project dashboard.
                    </>
                  )}
                </Text>
              )}
            </Stack>
          </>
        )}
      </Portal>
      <View p={3}>
        <Stack gap={2}>
          <DashboardContentHeader
            headline={"Aectual "}
            textStyle="logo"
            subtext="Design Library"
          />
          {sanityData && withoutDuplicates?.length === 0 && (
            <Stack gap={2} p={4}>
              <Text textStyle="title">
                There are currently no designs to choose from.
                <br />
                Please come back later.
              </Text>
              <Text
                onClick={toggle}
                hover
                pointer
                style={{
                  textDecoration: "underline",
                  display: "inline-block",
                }}
              >
                Let us know if you would like early access.
              </Text>
              <Link to="../../new" block>
                Go Back
              </Link>
            </Stack>
          )}
          <Grid gridGap={[3, 4, 5]} minColumnWidth={[180, 180, 180]}>
            {withoutDuplicates?.map((variant) => (
              <Item
                key={variant.id}
                item={variant}
                onClick={() => {
                  createProduct({
                    variables: {
                      name: variant.title,
                      projectId: parseInt(projectId, 10),
                      categoryId: odooProductCategoryId || 17,
                    },
                  }).then(({ data }) => {
                    console.log(data.createProduct.product.id)
                    refetch()
                    if (variant.key && variant.ticket) {
                      createModel({
                        variables: {
                          title: variant.title,
                          productId: parseInt(
                            data.createProduct.product.id,
                            10
                          ),
                          ticket: variant.key,
                          secret: variant.secret,
                          parameters: variant.parameters || null,
                        },
                      }).then(() => {
                        navigate("../../" + data.createProduct.product.id)
                      })
                    } else {
                      navigate("../../" + data.createProduct.product.id)
                    }
                  })
                }}
              />
            ))}

            <div />
            <div />
            <div />
          </Grid>
        </Stack>
      </View>
    </>
  )
}

export default ChooseVariant
