import { useCategoryIdsQuery } from "./sanityQueries"

export default (slug) => {
  const { data } = useCategoryIdsQuery({ fetchPolicy: "cache-first" })
  return data
    ? data?.allProductCategory?.find(
        (category) => category.slug?.current === slug
      )?.id
    : null
}
