import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
// @ts-ignore
import { useNavigate } from "react-router-dom"
import { Stack, View, Text, Input, Button, Link } from "../components"
import { useForm } from "react-hook-form"
import { AuthContext } from "./AuthProvider"
import client from "./authClient"
import { useLoginMutation } from "./authMutations"
import { useAuth, useUser } from "./AuthProvider"
import Odootest from "../products/ProductCategoriesOdoo"

const Redirect = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [])
  return null
}

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  center: true,
  mt: 120,
})`
  max-width: 26em;
`

const Alert = styled(Text).attrs({
  color: "red",
})``

const LoginPage = ({
  toggleLogin,
  callback,
  page = false
}: {
  toggleLogin?: Function
  callback?: Function
  page?: boolean
}) => {
  const { login } = useContext(AuthContext)
  const { register, handleSubmit, watch, errors } = useForm()
  const [loginMutation] = useLoginMutation({ client })
  const [failed, setFailed] = useState(false)

  const { isAuthenticated, isAuthenticating, refetch } = useAuth()
  const user = useUser()
  console.log(user)

  useEffect(() => {
    if (failed) setFailed(false)
  }, [watch("username"), watch("password")])

  const onSubmit = (data) => {
    console.log(login)
    login(data)
    const variables = {
      username: data.username,
      password: data.password,
    }
    loginMutation({ variables })
      .then((data) => {
        console.log(data)
        window.localStorage.setItem("accessToken", data.data.login.token)
        console.log("RUN CALLBACK AFTER LOGIN", callback)
        callback && setTimeout(callback, 120)
        setTimeout(refetch, 20)
      })
      .catch(() => setFailed(true))
    // navigate("/you")
  }

  if (isAuthenticating) return <Text>...</Text>

  return (
    <Container style={{minWidth: "26em"}}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Stack gap={4} mt={page ? 5 : 2}>
          <Text textStyle="title" mb={3}>
            Login
          </Text>
          <Text>
            Enter your email and password
            <Text>
              {isAuthenticated && !callback && <Redirect to="/dashboard" />}
            </Text>
          </Text>
          <View>
            <View>
              <Input
                name="username"
                py={2}
                ref={register({ required: true, minLength: 2 })}
                autoFocus
                type="email"
                bg={page? "background": "tertiaryBackground"}
                placeholder="Email"
              />
              {errors.username && <Alert>Email is a required field</Alert>}
            </View>

            <View>
              <Input
                name="password"
                type="password"
                py={2}
                ref={register({ required: true, minLength: 6 })}
                bg={page? "background": "tertiaryBackground"}
                placeholder="Password"
              />
              {errors.password && <Alert>Password is a required field</Alert>}
            </View>
          </View>

          {failed && <Text>Something went wrong, please try again.</Text>}

          <View>
            <Button
              my={2}
              mr={3}
              as="button"
              type="submit"
              secondary
              color="white"
            >
              Login
            </Button>
            {toggleLogin ? (
              <Text
                onClick={toggleLogin}
                mt={2}
                block
                color="alternate"
                hover
                pointer
              >
                Return to the Signup form
              </Text>
            ) : (
              <Link to="/signup" ml={2} mt={2} block color="alternate">
                Signup
              </Link>
            )}
          </View>
        </Stack>
      </form>

      {/* <Odootest /> */}
    </Container>
  )
}

export default LoginPage
