import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import styled, { keyframes, createGlobalStyle } from "styled-components"
import { useQuery, ChildDataProps } from "react-apollo"
import {
  View,
  Headline,
  Text,
  Link,
  SlideImage,
  Center,
  Button,
  Stack,
} from "../components"
import { Image } from "../components/SlideImage"

import useComponentSize from "@rehooks/component-size"
import { letterboxOrCrop } from "../utilities/imageSize"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import ALBUM_QUERY from "../products/ALBUM_BY_TITLE_QUERY"

interface ViewerProps {
  children?: any
  title?: string
}

const CarouselStyle = createGlobalStyle`
  .carousel .slide.slide {
    background-color: transparent;
  }

  .carousel.carousel-slider {
    max-width: 100vw;
  }

  .carousel .control-dots .dot {
    box-shadow: none !important;
    transition: background-color 250ms ease-in-out;
  }

  .carousel .control-dots .dot.selected {
    background-color: black;
  }
`

export const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.92;
  }
`

const Container = styled(View)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Viewer = ({ title }: ViewerProps): JSX.Element => {
  const ref = useRef(null)
  const canvas = useComponentSize(ref)
  const { loading, data } = useQuery<any, any>(ALBUM_QUERY, {
    variables: { title, user: process.env.REACT_APP_WHITESPACE_USER },
  })
  const slides = data && data.allAlbums && data.allAlbums[0].slides
  const [active, setActive] = useState(0)
  console.log({ canvas })
  return (
    <Container ref={ref}>
      <CarouselStyle />
      {!slides ? (
        `...`
      ) : (
        <Carousel
          showIndicators={true}
          showThumbs={false}
          useKeyboardArrows={true}
          showArrows={false}
          showStatus={false}
          selectedItem={active}
          onChange={(current) => setActive(current)}
          onClickItem={() => console.log("onClickItem")}
          swipeable={true}
          emulateTouch={true}
        >
          {slides.map((slide) => {
            const dimensions = letterboxOrCrop(
              { width: slide.width, height: slide.height },
              { width: canvas.width - 16, height: canvas.height },
              true
            )
            return (
              <div
                key={slide.id}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  height: canvas.height,
                  maxWidth: "100vw",
                }}
              >
                <div
                  style={{
                    width: canvas.width,
                    height: canvas.height,
                    position: "relative",
                  }}
                >
                  <Image
                    slide={slide}
                    width={dimensions.width}
                    height={dimensions.height}
                  />
                </div>
              </div>
            )
          })}
        </Carousel>
      )}
    </Container>
  )
}

Viewer.displayName = "FullViewer"

export default Viewer
