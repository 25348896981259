import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  Icon,
} from "../../components"
import { useUser, useAuth } from "../../user/AuthProvider"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import ProjectImageSelect from "./ProjectImageSelect"
import ProductImageSelect from "../product/ProductImageSelect"
import { SanityImage } from "../../components/SlideImage"

const PROJECTS = gql`
  query projects {
    currentUser {
      id
      projects {
        id
        name
        client
        location
        image {
          url
          thumb
        }
        owner: author {
          id
          username
          name
        }
        products {
          id
          name
        }
      }
    }
  }
`

export const Container = styled(View).attrs({
  px: [1, 2, 3],
  py: [2, 3, 3],
})`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 3.2rem);
`

export const DashboardContentHeader = ({
  headline,
  subtext,
  textStyle = "title",
}) =>
  null /* (
  <>
    <Divider mt={3} mb={5} />
    <Text mb={3} textStyle={textStyle}>
      {headline}
    </Text>

    <Text mb={1} mt={5} textStyle="body">
      {subtext}
    </Text>
    <Divider mt={0} mb={4} />
  </>
)*/

const Center = styled(View).attrs(() => ({ color: "white" }))`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProjectItem = ({
  project,
  newProject = false,
  isProduct = false,
  to = "",
  image = null,
  category = "",
}) => {
  const token = window.localStorage.getItem("accessToken")

  return (
    <View relative>
      <Link to={to} block my={2}>
        <Stack gap={2}>
          <RatioContainer ratio={1} bg="#EEE">
            {!!image && !project.image && <SanityImage source={image} />}
            {!!project.image && (
              <img
                key={project.image.url}
                src={
                  "https://erp-test12.aectual.com" +
                  project.image.url +
                  "&session_id=" +
                  token
                }
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
            {newProject && (
              <Center>
                <Icon name="add" size={84} />
              </Center>
            )}
          </RatioContainer>
          <Stack gap={1}>
            <Text>{project.title || project.name}</Text>
            <Text textStyle="footnote">
              {category}
              {project.location || ""}
            </Text>
          </Stack>
        </Stack>
      </Link>
      {!newProject && !isProduct && <ProjectImageSelect id={project.id} />}
      {isProduct && !newProject && <ProductImageSelect id={project.id} />}
    </View>
  )
}

export default () => {
  const user = useUser()
  const { isAuthenticating, logout } = useAuth()
  const { data, refetch } = useQuery(PROJECTS, {
    client,
    fetchPolicy: "cache-and-network",
  })

  if (isAuthenticating) {
    return <Text>...</Text>
  }

  const projects = data?.currentUser?.projects ?? []

  return (
    <Container>
      {user ? (
        <View>
          <DashboardContentHeader
            headline={
              user.company && user.company.length > 1 ? user.company : user.name
            }
            subtext="Projects"
          />

          <Grid gridGap={[3, 4, 5]} minColumnWidth={[200, 220, 240]}>
            {[...projects].reverse().map((project) => (
              <ProjectItem
                key={project.id}
                project={project}
                to={"project/" + project.id}
              />
            ))}
            <ProjectItem
              project={{ name: "Add Project" }}
              newProject
              to={"project/new"}
            />
            <div />
            <div />
          </Grid>
        </View>
      ) : (
        <Text>...</Text>
      )}
    </Container>
  )
}
