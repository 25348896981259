import React, { Fragment } from "react"
import SideBySide from "./SideBySide"
import View from "./View"
import PromoBlock from "./PromoBlock"

export default ({
  promoBlocks,
  insert = [],
}: {
  promoBlocks: any
  insert?: any
}) => {
  return (
    <>
      {promoBlocks
        .reduce((collect, item) => {
          const lastItem = collect.length > 0 && [...collect].pop()
          console.log({ lastItem })
          // add new 'set' when last item already has two, or item 'within' has display: "FULL_WIDTH"
          console.log(promoBlocks.length)
          const addNew =
            // promoBlocks.length === 1 ||
            !lastItem || lastItem.length === 2 || lastItem[0].fullWidth === true
          console.log({ addNew })
          return addNew
            ? [...collect, [item]]
            : [...collect.slice(0, collect.length - 1), [...lastItem, item]]
        }, [])
        .map((items, index) =>
          items.length === 1 && items[0].fullWidth === true ? (
            insert.map((item) => item.match).includes(items[0].catchphrase) ? (
              insert.find((item) => item.match === items[0].catchphrase)
                ?.element
            ) : (
              <PromoBlock item={items[0]} />
            )
          ) : (
            <SideBySide key={index}>
              {/* <PromoBlock data={{}} /> */}
              {items.map((item) => (
                <PromoBlock key={item.id} item={item} />
              ))}
              {items.length === 1 && <View />}
            </SideBySide>
          )
        )}
    </>
  )
}
