import * as React from "react"
import { View, Icon } from "../../components"
import { useMutation } from "react-apollo"
import client from "../../user/authClient"
import styled from "styled-components"
import gql from "graphql-tag"
import { useNavigate } from "react-router"

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!) {
    deleteProduct(input: { id: $id }) {
      id
    }
  }
`

const Container = styled(View)`
  margin-left: auto;
  filter: opacity(0.3);
`

const DeleteProduct = ({ id }) => {
  const [deleteProduct] = useMutation(DELETE_PRODUCT, { client })
  const navigate = useNavigate()
  return (
    <Container
      hover
      pointer
      onClick={() => {
        const confirmed = window.confirm(
          "Are sure you'd like to remove this product?"
        )
        if (confirmed)
          deleteProduct({ variables: { id } }).then(() =>
            setTimeout(() => navigate("../../", { replace: true }), 300)
          )
      }}
      py={2}
    >
      <Icon name="delete" />
    </Container>
  )
}

export default DeleteProduct
