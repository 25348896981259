import React, { createContext, useEffect } from "react"
import styled from "styled-components"
import { View, Stack, Text, Divider } from "./components"
import { useToggle } from "react-hooks-lib"
import { useMedia } from "use-media"
import useTheme from "./utilities/useTheme"
import { NavLink } from "./Header"
import {
  // @ts-ignore
  useLocation,
} from "react-router-dom"
import { ContactDetails } from "./components/ContactOverlay"
export const OverlayMenuContext = createContext({
  on: false,
  toggle: () => console.log("no toggle function present"),
  reset: null,
})

export const Consumer = OverlayMenuContext.Consumer

const Container = styled(View).attrs({ px: [2, 3, 4], bg: "background" })<{
  visible: boolean
}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: calc(100vh + 32px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 86px;
  padding-bottom: 176px;
  opacity: 0;
  pointer-events: none;
  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    pointer-events: auto;
  `} transition: opacity 250ms ease;
  transform: translate3d(0, 0, 0);
  z-index: 998;
`

const Content = styled(View).attrs({ center: false })``

const OverlayMenu = ({ children }) => {
  const { on, toggle, reset } = useToggle(false)
  console.log({ on })
  const theme = useTheme()
  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })

  const location = useLocation()

  useEffect(reset, [location])

  return (
    <OverlayMenuContext.Provider value={{ on, toggle, reset }}>
      {children}
      {!isWide && (
        <Container visible={on}>
          <Content>
            <Stack gap={2} my={2}>
              <Divider />
              <NavLink textStyle="headline" block to="/architectural-products">
                Architectural Products
              </NavLink>
              <Divider />
              <NavLink
                textStyle="headline"
                block
                to="/manufacturing-capabilities"
              >
                Manufacturing Capabilities
              </NavLink>
              <Divider />
              <NavLink textStyle="headline" block to="/sustainability">
                Sustainability
              </NavLink>
              <Divider />
              <NavLink textStyle="headline" block to="/about">
                About
              </NavLink>
              <Divider />
            </Stack>
            <ContactDetails mt={6} />
          </Content>
        </Container>
      )}
    </OverlayMenuContext.Provider>
  )
}

export default OverlayMenu
