import React from "react"
import styled from "styled-components"
import { View, Text, Link, Divider } from "../components"
import ScrollToOnMatch from "../components/ScrollToOnMatch"
import { useDesignersQuery } from "../sanity/sanityQueries"
import PromoBlocksOverview from "../components/PromoBlockOverview"

export const Container = styled(View)`
  display: block;
`

export const OverviewContainer = styled(View)`
  flex: 1;
  min-height: 100vh;
`

export const NavigatorContainer = styled(View)`
  width: 320px;
`

export const Sticky = styled(View)`
  position: sticky;
  top: 16px;
`
// next up, the navigation thing... and the Route thing per collection?!

export default () => {
  const { data } = useDesignersQuery({})

  return (
    <Container>
      {data?.allAuthor &&
        data.allAuthor.map(author => (
          <Text key={author.id} textStyle="title" my={2}>
            {author.name}
          </Text>
        ))}
    </Container>
  )
}
