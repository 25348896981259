import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { ApolloLink, concat } from "apollo-link"
import { InMemoryCache } from "apollo-cache-inmemory"

// Setup the header for the request
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = window.localStorage.getItem("accessToken")

  operation.setContext({
    headers: token
      ? {
          "X-Openerp-Session-Id": token
        }
      : {}
  })
  return forward(operation)
})

if (!process.env.REACT_APP_GRAPHQL_ODOO_ENDPOINT) {
  window.alert(
    "Please specify the odoo graphql endpoint in the environment variables."
  )
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ODOO_ENDPOINT
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
})

export default client
