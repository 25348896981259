import React, { useContext } from "react"
import styled, { createGlobalStyle } from "styled-components"
import Logo from "./components/Logo"
import { View, Text, Link, ButtonOutline } from "./components"
import Avatar from "./components/Avatar"
import { UserConsumer } from "./user/AuthProvider"
import MenuToggle from "./components/MenuToggle"
import { useMedia } from "use-media"
import { useLocation } from "react-use"
import useTheme from "./utilities/useTheme"
// @ts-ignore
import { NavLink as RouterLink } from "react-router-dom"
import { OverlayMenuContext } from "./OverlayMenu"
import { useAuthOverlay } from "./user/AuthOverlay"

const CenterPage = createGlobalStyle`
  #app {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    :before {
      margin-top: 0;
    }
    transform: none;
  }
`

const Fixed = styled(View).attrs(() => ({
  bg: "background",
  px: [2, 4, 4],
}))`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1278;
  max-width: 1800px;
`

const Container = styled(View)`
  position: relative;
  height: ${({ theme }) => theme.headerHeight};
  display: flex;
  justify-items: center;
`

const Nav = styled(View)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-right: 18vw;
  padding-left: 18vw;
  @media (min-width: 1400px) {
    padding-right: 320px;
    padding-left: 320px;
  }
`

const LogoContainer = styled(Link)`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
`

const UserContainer = styled(View)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NavLink = styled(Link).attrs(
  ({
    to,
    match,
    textStyle = "navigation",
    pathname,
  }: {
    to: string
    match?: string
    textStyle?: string
    pathname?: string
  }) => ({
    as: RouterLink,
    // textStyle: pathname?.includes(to) ? "navigationBold" : textStyle,
    getProps: (linkProps: any) => {
      console.log(
        to,
        linkProps.location.pathname,
        linkProps.location.pathname.includes(to)
      )
      return {
        ...((linkProps.location.pathname.includes(match) ||
          linkProps.location.pathname.includes(to)) && {
          "data-active": "true",
        }),
      }
    },
    onMouseUp: () => {
      window.sessionStorage.setItem("navigated", "click")
      window.sessionStorage.setItem(
        "navigatedTime",
        new Date().getTime().toString()
      )
    },
  })
)`
  position: relative;
  &[aria-current] .underline {
    display: block;
  }
`

const Flex1 = styled(View)`
  flex: 1;
`

const Underline = styled(View)`
  position: absolute;
  bottom: -6px;
  height: 2px;
  background-color: black;
  left: 0;
  right: 0;
  display: none;
`

export default (): JSX.Element => {
  const { navigateAuth, executeIfAuthenticated } = useAuthOverlay()

  const theme = useTheme()
  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })

  const location = useLocation()
  const { on: active, toggle } = useContext(OverlayMenuContext)

  return (
    <Fixed>
      <CenterPage />
      <Container>
        <LogoContainer to="">
          <Logo style={{ width: "8rem", height: "100%" }} />
        </LogoContainer>
        {isWide && (
          <>
            <Nav as="nav">
              <NavLink
                to="/architectural-products"
                pathname={location.pathname}
              >
                Architectural Products
                <Underline className="underline" />
              </NavLink>
              <NavLink
                to="/manufacturing-capabilities"
                pathname={location.pathname}
              >
                Manufacturing Capabilities
                <Underline className="underline" />
              </NavLink>
              <NavLink to="/sustainability" pathname={location.pathname}>
                Sustainability
                <Underline className="underline" />
              </NavLink>
              {/* <NavLink to="/designers">Designers</NavLink> */}
              <NavLink to="/about" pathname={location.pathname}>
                About
                <Underline className="underline" />
              </NavLink>
              {/* <NavLink
        as={ButtonOutline}
        to="/start"
        bg="white"
        px={4}
        onClick={() => navigate("/start")}
        mr={4}
      >
        Start Your Project
      </NavLink>*/}
            </Nav>
          </>
        )}
        {isWide && (
          <UserContainer>
            <UserConsumer>
              {(user) => (
                <>
                  {user ? (
                    <>
                      <Text
                        fontWeight="bold"
                        hover
                        pointer
                        onClick={() => navigateAuth("/dashboard")}
                      >
                        Dashboard
                      </Text>
                      <Link to="/dashboard" ml={4}>
                        <Avatar />
                      </Link>
                    </>
                  ) : (
                    <>
                      <Text
                        fontWeight="bold"
                        hover
                        pointer
                        onClick={() => navigateAuth("/dashboard")}
                      >
                        Join Aectual
                      </Text>
                      <View
                        hover
                        pointer
                        onClick={() => navigateAuth("/dashboard")}
                        ml={4}
                      >
                        <Avatar />
                      </View>
                    </>
                  )}
                </>
              )}
            </UserConsumer>
          </UserContainer>
        )}
        {!isWide && (
          <>
            <Flex1 />
            <MenuToggle
              color={"black"}
              style={{ marginRight: -18 }}
              active={active}
              toggle={toggle}
            />
          </>
        )}
      </Container>
    </Fixed>
  )
}
