import React from "react"
import styled from "styled-components"
import {
  View,
  Stack,
  Text,
  Label,
  Input,
  Button,
  Divider,
  Link,
} from "../components"
import { useModelsQuery, useCreateModel } from "./modelQueriesAndMutations"

import { useForm } from "react-hook-form"
import { DateTime } from "luxon"

const Container = styled(View)``

const Content = styled(View)`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`

const Card = styled(Link).attrs(() => ({ p: 2 }))`
  display: block;
  border: 2px solid #dddddd;
  border-radius: 4px;
`

export default () => {
  const { data, refetch } = useModelsQuery({})

  const { register, handleSubmit, watch, errors, reset } = useForm()
  const [createModel] = useCreateModel({})

  const onSubmit = (data) => {
    const variables = {
      title: data.title,
      description: data.description,
      ticket: data.ticket,
      backendTicket: data.backendTicket,
    }
    createModel({ variables }).then((data) => {
      reset()
      refetch()
    })
  }

  return (
    <Container>
      <Content pb={3}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <Stack mt={3} gap={3}>
            <Text textStyle="title" my={4}>
              Create Model
            </Text>
            <View>
              <Label>Title</Label>
              <Input
                name="title"
                ref={register({ required: true, minLength: 2 })}
              />
            </View>
            <View>
              <Label>Description</Label>
              <Input name="description" ref={register({ required: false })} />
            </View>
            <View>
              <Label>Ticket</Label>
              <Input
                name="ticket"
                ref={register({ required: true, minLength: 6 })}
              />
            </View>
            <View>
              <Label>Backend Ticket</Label>
              <Input
                name="backendTicket"
                ref={register({ required: false, minLength: 6 })}
              />
            </View>
            <Button as="button" type="submit">
              Submit
            </Button>
          </Stack>
        </form>
        <Stack mt={5} gap={2}>
          <Divider />
          <Text textStyle="title" my={4}>
            Models
          </Text>
          <Stack gap={3}>
            {data &&
              data.models.map((model) => (
                <Card to={`/model/${model.id}`} key={model.id}>
                  <Label color="secondaryText" mb={1} textStyle="footnote">
                    {DateTime.fromISO(model.createdAt).toRelative({
                      locale: "en",
                    })}
                  </Label>
                  <Text textStyle="action">{model.title}</Text>
                  {model.description && (
                    <Label mt={1}>{model.description}</Label>
                  )}
                </Card>
              ))}
          </Stack>
        </Stack>
      </Content>
    </Container>
  )
}
