import React, { createContext, useContext, useState } from "react"
import styled from "styled-components"
import { Stack, View, Text, Button } from "../components"
import { useToggle } from "react-hooks-lib"
import MenuToggle from "../components/MenuToggle"
import { OverlayContainer } from "../user/AuthOverlay"
import { DetailsForm } from "../dashboard/sample/SamplePage"
import { Dispatch, SetStateAction /* and others */ } from "react"

const ScrollContent = styled(View).attrs(() => ({ px: [0, 5, 7] }))`
  width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
`
// type AppContextState = { isMenuOpen: boolean; isSideOpen: boolean }

type AppContextValue = {
  // state: AppContextState;
  // type, you get when hovering over `setState` from `useState`
  toggle: any
  setProduct: Dispatch<SetStateAction<null>>
  setAmount: Dispatch<SetStateAction<null>>
  setSample: Dispatch<SetStateAction<null>>
}

const CheckoutOverlayContext = createContext<Partial<AppContextValue>>({})

export const CheckoutConsumer = CheckoutOverlayContext.Consumer

export const useCheckoutOverlay = () => useContext(CheckoutOverlayContext)

const CheckoutOverlayProvider = ({ children }) => {
  const { on, toggle } = useToggle(false)

  const [product, setProduct] = useState<any | null>(null)
  const [amount, setAmount] = useState<any | null>(null)
  const [sample, setSample] = useState<any | null>(null)

  return (
    <CheckoutOverlayContext.Provider
      value={{ toggle, setProduct, setAmount, setSample }}
    >
      {children}
      <OverlayContainer visible={on} bg="tertiaryBackground">
        <ScrollContent>
          <View
            p={[2, 5, 7]}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <MenuToggle
              active={true}
              toggle={toggle}
              color="black"
              style={{ marginRight: -4, marginTop: 6 }}
            />
          </View>
          <View
            mt={[4, 6, 8]}
            pt={[4, 6, 8]}
            style={{
              maxWidth: "24em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {sample?.title && (
              <DetailsForm
                product={sample?.title}
                amount={amount}
                sample={sample}
              />
            )}
          </View>
        </ScrollContent>
      </OverlayContainer>
    </CheckoutOverlayContext.Provider>
  )
}

export default CheckoutOverlayProvider
