// The header component with three images
import React from "react"
import styled from "styled-components"
import { View, Text, RatioContainer, Button, Link } from "./"
import { FadeImage } from "./SlideImage"
import Vimeo from "./Vimeo"
import { useMedia } from "use-media"

const HeroContainer = styled(View)`
  display: flex;
  justify-content: center;
  align-items: stretch;
`

const NarrowContainer = styled(View)`
  overflow-x: hidden;
`

export default ({ item }) => {
  const isNarrow = useMedia({ maxWidth: 820 })
  const isVeryWide = useMedia({ minWidth: 1690 })

  if (isNarrow)
    return (
      <NarrowContainer>
        <Text
          textStyle="headline"
          style={{
            flex: 1,
            fontSize: "clamp(32px, 14vw, 72px)",
            maxWidth: "23rem",
            lineHeight: "1",
          }}
          mr={2}
          mt={[3, 4, 5]}
        >
          {item.catchphrase}
          {item.actions &&
            item.actions.length !== 2 &&
            item.actions.map((action) => (
              <Button
                key={action.id}
                center
                ellipsis
                secondary
                as={Link}
                to={action.action}
                style={{
                  pointerEvents: "auto",
                }}
                bg={"#E7FD6D"}
                color={"black"}
                my={8}
                px={[2, 5, 5]}
              >
                {action.label}
              </Button>
            ))}
        </Text>
        {item.subtitle && (
          <Text textStyle="action" style={{ maxWidth: "23em" }} mb={8}>
            {item.subtitle}
          </Text>
        )}
      </NarrowContainer>
    )

  return (
    <>
      <HeroContainer>
        <Text
          textStyle="hero"
          style={{
            flex: 1,
            fontSize: "clamp(20px, 4.4vw, 72px)",
            // marginRight: "-3vw",
            zIndex: 90,
          }}
          mr={2}
          pt={[8, 9, 10]}
        >
          {item.catchphrase}

          {item.actions &&
            item.actions.length !== 2 &&
            item.actions.map((action) => (
              <Button
                key={action.id}
                center
                ellipsis
                secondary
                as={Link}
                to={action.action}
                mt={3}
                px={isVeryWide ? "6vw" : 8}
                style={{
                  pointerEvents: "auto",
                  marginRight: "-5em",
                }}
                bg={"#E7FD6D"}
                color={"black"}
              >
                {action.label}
              </Button>
            ))}
        </Text>
        <View style={{ flex: 3 }}>
          <RatioContainer ratio={16 / 9}>
            <Vimeo
              videoLink={item.videoUrl}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </RatioContainer>
        </View>
      </HeroContainer>
      {item.subtitle && (
        <Text
          textStyle="action"
          center
          style={{ maxWidth: "23em", fontSize: "36px", fontWeight: "700" }}
          my={8}
        >
          {item.subtitle}
        </Text>
      )}
    </>
  )
}
