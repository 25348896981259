import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
  Portal,
  Icon,
} from "../../components"
import { useUser, useAuth } from "../../user/AuthProvider"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import axios from "axios"
import FileDownload from "js-file-download"

import ListModels from "../model/ListModels"

const CLIENT_PRODUCT_QUERY = gql`
  query clientProduct($id: Int!) {
    product: clientProduct(id: $id) {
      id
      name
      description
      project {
        id
        name
      }
      image {
        url
      }
      owner: author {
        id
        username
        name
      }
      models {
        id
      }
      files {
        id
        name
        versions {
          id
          url
          date
        }
      }
    }
  }
`

export const useProductQuery = (id) =>
  useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(id, 10) },
    client,
  })

const CREATE_FILE_MUTATION = gql`
  mutation createFile($name: String!, $productId: Int!) {
    createFile(input: { name: $name, productId: $productId }) {
      file {
        id
        name
        versions {
          id
          url
          date
        }
      }
    }
  }
`

export const useCreateFile = () => useMutation(CREATE_FILE_MUTATION, { client })

const CREATE_FILE_VERSION_MUTATION = gql`
  mutation createFile(
    $content: String
    $fileId: Int!
    $generator: Boolean
    $url: String
  ) {
    createFileVersion(
      input: {
        content: $content
        fileId: $fileId
        generator: $generator
        url: $url
      }
    ) {
      version {
        id
        url
        date
        generator
      }
    }
  }
`

export const useCreateVersion = () =>
  useMutation(CREATE_FILE_VERSION_MUTATION, {
    client,
  })

const fileToBase64 = (filepath) => {
  return new Promise((resolve) => {
    var reader = new FileReader()
    // Read file content on file loaded event
    reader.onloadend = function() {
      resolve(reader.result)
    }
    // Convert data to base64
    reader.readAsDataURL(filepath)
  })
}

export default () => {
  let { productId = "" } = useParams()
  const { data, refetch } = useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
    fetchPolicy: "network-only",
  })

  const [newFile] = useMutation(CREATE_FILE_MUTATION, { client })
  const [newVersion] = useMutation(CREATE_FILE_VERSION_MUTATION, { client })

  const files = data?.product?.files ?? []
  const models = data?.product?.models ?? []

  console.log(files, models)

  const token = window.localStorage.getItem("accessToken")

  const downloadFile = (url) => {
    axios({
      method: "GET",
      url: "https://erp-test12.aectual.com" + url + "&session_id=" + token,
      headers: {
        "X-Openerp-Session-Id": token,
      },
      responseType: "blob",
    })
      .then((response) => {
        FileDownload(response.data, "FILE")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <Stack gap={2} px={[2, 3, 3]}>
            <Text textStyle="action">{data?.product?.project?.name}</Text>
            <Divider />
            <Text textStyle="action">{data?.product?.name}</Text>
            <Divider />
            <Link to="../" replace block>
              <Icon name="arrow-left" style={{ display: "inline-block" }} />
              Back to Product
            </Link>
            <Divider />
            {files.length === 0 && models.length === 0 && (
              <Stack gap={3} mt={6}>
                <Text textStyle="title">Upload Pattern</Text>
                <Text textStyle="body">
                  Upload your floorplan files to get direct feedback on the
                  possibilities, get a quotation, and see how your pattern fits
                  and enriches your space design.
                </Text>
              </Stack>
            )}
          </Stack>
        )}
      </Portal>
      <Container>
        {(files.length > 0 || models.length > 0) && (
          <>
            <DashboardContentHeader
              headline={data?.product?.name}
              subtext="Uploads"
            />
          </>
        )}

        {files.length > 0 && (
          <Stack gap={2}>
            {[...files].reverse().map((file) => (
              <Text>
                file: {file.name}
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    fileToBase64(acceptedFiles[0]).then((result) => {
                      if (typeof result === "string") {
                        console.log(result)
                        newVersion({
                          variables: {
                            fileId: file.id,
                            content: result.replace(/^data:.+;base64,/, ""),
                          },
                        }).then(() => {
                          refetch({ id: parseInt(productId, 10) })
                        })
                      }
                    })
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <span {...getRootProps()} style={{ cursor: "pointer" }}>
                        {" "}
                        +
                      </span>
                      <input {...getInputProps()} />
                    </>
                  )}
                </Dropzone>
                {file.versions.length > 0 && (
                  <>
                    <br />
                    versions:{" "}
                    {file.versions.map((version) =>
                      version.url.includes("https:") ? (
                        <>
                          <a
                            href={version.url}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {DateTime.fromISO(version.date).toFormat(
                              "dd.LL.yyyy hh:mm"
                            )}
                          </a>
                          <span> </span>
                        </>
                      ) : (
                        <>
                          <a
                            href={
                              "https://erp-test12.aectual.com" +
                              version.url +
                              "&session_id=" +
                              token
                            }
                            target="_blank"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            download={file.name}
                          >
                            {DateTime.fromISO(version.date).toFormat(
                              "dd.LL.yyyy hh:mm"
                            )}
                          </a>
                          <span> </span>
                        </>
                      )
                    )}
                  </>
                )}
                <Divider />
              </Text>
            ))}
          </Stack>
        )}
        {/* <ButtonOutline
        mt={4}
        onClick={() => {
          const name = window.prompt("Enter file Name")
          if (typeof name === "string" && name.length > 1) {
            newFile({
              variables: { name, productId: parseInt(productId, 10) },
            }).then(() => refetch({ id: parseInt(productId, 10) }))
          }
        }}
      >
        New File
      </ButtonOutline>
      */}
        <Dropzone
          onDrop={(acceptedFiles) => {
            const name = window.prompt("Enter file Name", acceptedFiles[0].name)
            // newFile(acceptedFiles[0])

            if (typeof name === "string" && name.length > 1) {
              newFile({
                variables: { name, productId: parseInt(productId, 10) },
              }).then(({ data }) => {
                if (data.createFile) {
                  const reader = new FileReader()
                  reader.onloadend = function() {
                    // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
                    if (reader.result) {
                      var b64 = reader.result // .replace(/^data:.+;base64,/, '');
                      newVersion({
                        variables: {
                          fileId: data.createFile.file.id,
                          content: b64,
                        },
                      }).then(() => {
                        refetch({ id: parseInt(productId, 10) })
                      })
                    }

                    // console.log(b64); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
                  }

                  reader.readAsDataURL(acceptedFiles[0])
                }
              })
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <ButtonOutline
                mt={2}
                as="div"
                {...getRootProps()}
                style={{
                  ...(models.length === 0 &&
                    files.length === 0 && {
                      display: "flex",
                      width: "calc(100% - 4em)",
                      marginLeft: "auto",
                      marginRight: "auto",
                      minHeight: "25vmin",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15vmin",
                    }),
                }}
                textStyle={files.length !== 0 ? "title" : "action"}
              >
                Drag and drop a file, or click to upload
              </ButtonOutline>
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      </Container>
    </>
  )
}
