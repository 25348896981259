import "resize-observer-polyfill"
import "intersection-observer"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import DataProvider from "./DataProvider"
import AuthProvider, { UserProvider } from "./user/AuthProvider"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/browser"

import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"

const instance = createInstance({
  urlBase: "https://www.aectual.xyz/piwik/",
  // siteId: 1, // optional, default value: `1`
  // userId: 'UID76903202', // optional, default value: `undefined`.
  // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: "/matomo.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
})

if (!process.env.REACT_APP_SENTRY_URL) {
  window.alert("Please specify the Sentry URL in the environment variables.")
}
// Setup Sentry logging
/*
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL
})
*/
ReactDOM.render(
  <MatomoProvider value={instance}>
    <DataProvider>
      <AuthProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </AuthProvider>
    </DataProvider>
  </MatomoProvider>,
  document.body
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
