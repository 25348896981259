import gql from "graphql-tag"

export const UPDATE_PROJECT_NAME = gql`
  mutation updateProjet($id: Int!, $name: String!) {
    updateProject(input: { id: $id, patch: { name: $name } }) {
      project {
        id
        name
      }
    }
  }
`

export const UPDATE_PROJECT_LOCATION = gql`
  mutation updateProjet($id: Int!, $location: String!) {
    updateProject(input: { id: $id, patch: { location: $location } }) {
      project {
        id
        location
      }
    }
  }
`

export const UPDATE_PROJECT_CLIENT = gql`
  mutation updateProjet($id: Int!, $client: String!) {
    updateProject(input: { id: $id, patch: { client: $client } }) {
      project {
        id
        client
      }
    }
  }
`
