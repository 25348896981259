import React from "react"
import View from "./View"

export default (props) => (
  <View style={{ position: "relative", minHeight: 64 }} {...props}>
    <div className="uk-icon uk-spinner" uk-spinner="ratio: 1">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        data-svg="spinner"
      >
        <circle fill="none" stroke="#000" cx="15" cy="15" r="14"></circle>
      </svg>
    </div>
  </View>
)
