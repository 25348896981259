import React from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  Button,
  Link,
  Divider,
  Grid,
  RatioContainer,
  Portal,
  Icon,
} from "../../components"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import { useUser } from "../../user/AuthProvider"
import sanityClient from "../../sanity/sanityClient"
import { useAuthOverlay } from "../../user/AuthOverlay"

const PROJECTS = gql`
  query projects {
    currentUser {
      id
      projects {
        id
      }
    }
  }
`

const PROJECT_QUERY = gql`
  query project($id: Int!) {
    project(id: $id) {
      id
      name
      owner: author {
        id
        username
        name
      }
      products {
        id
        name
      }
    }
  }
`

const CREATE_PROJECT = gql`
  mutation newProject($name: String!) {
    createProject(input: { name: $name }) {
      project {
        id
        name
      }
    }
  }
`

const PRODUCT_CATEGORY = gql`
  query Category($id: ID!) {
    ProductCategory(id: $id) {
      id: _id
      name
      slug {
        current
      }
      enabledInProductChooser
      odooProductCategoryId
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation newProduct($name: String!, $projectId: Int!, $categoryId: Int!) {
    createProduct(
      input: { name: $name, projectId: $projectId, categoryId: $categoryId }
    ) {
      product {
        id
        name
        category {
          id
          name
        }
        project {
          id
        }
      }
    }
  }
`

export const useCreateProduct = () => useMutation(CREATE_PRODUCT, { client })

export const useProjectQuery = (projectId) =>
  useQuery(PROJECT_QUERY, {
    variables: { id: parseInt(projectId, 10) },
    client,
  })

export default ({ categoryId }) => {
  const { navigateAuth, executeIfAuthenticated } = useAuthOverlay()
  const navigate = useNavigate()

  const { data, refetch } = useQuery(PROJECTS, { client })

  const { data: categoryData } = useQuery(PRODUCT_CATEGORY, {
    variables: { id: categoryId },
    client: sanityClient,
  })

  console.log({ categoryData })

  const [newProject] = useMutation(CREATE_PROJECT, { client })

  const [newProduct] = useMutation(CREATE_PRODUCT, { client })

  if (!categoryData?.ProductCategory?.enabledInProductChooser) return null

  return (
    <>
      <Button
        block
        mt={4}
        onClick={() =>
          executeIfAuthenticated(() => {
            console.log("refetching projects")
            refetch()
              .then(({ data }) => {
                console.log("DIE,", data?.currentUser?.projects)
                if (!data.currentUser) {
                  window.alert("Please login, or create an account first")
                } else if (data?.currentUser?.projects.length > 0) {
                  navigate(
                    `/dashboard/project/${data?.currentUser?.projects[0]?.id}/product/variant/${categoryData?.ProductCategory?.slug?.current}`
                  )
                  /*
                newProduct({
                  variables: {
                    categoryId:
                      categoryData?.ProductCategory?.odooProductCategoryId ?? 17,
                    name:
                      categoryData?.ProductCategory?.name ?? "Default Product Name",
                    projectId: data?.currentUser?.projects[0].id,
                  },
                }).then(({ data }) => {
                  navigate(
                    `/dashboard/project/${data?.createProduct?.product?.project?.id}/product/${data?.createProduct?.product?.id}`
                  )
                })
                */
                } else if (data?.currentUser?.projects.length === 0) {
                  newProject({ variables: { name: "Project 001" } }).then(
                    (projectData) => {
                      /*
                    newProduct({
                      variables: {
                        categoryId:
                          categoryData?.ProductCategory?.odooProductCategoryId ??
                          17,
                        name:
                          categoryData?.ProductCategory?.name ??
                          "Default Product Name",
                        projectId: projectData?.data?.createProject?.project.id,
                      },
                    }).then(({ data }) => {
                      navigate(
                        `/dashboard/project/${data?.createProduct?.product?.project?.id}/product/${data?.createProduct?.product?.id}`
                      )
                    })
                    */
                      navigate(
                        `/dashboard/project/${projectData?.data?.createProject?.project.id}/product/variant/${categoryData?.ProductCategory?.slug?.current}`
                      )
                    }
                  )
                }
              })
              .catch(() => console.log("failed refetching projects"))

            /*
            const name = window.prompt("Enter Product Name")
            if (typeof name === "string" && name.length > 1) {
              newProduct({
                variables: {
                  name,
                  projectId: parseInt(projectId, 10),
                  categoryId: 17,
                },
              }).then(loadAgain)
            }
          */
          })
        }
      >
        Start Your Project
      </Button>
    </>
  )
}
