import React from "react"
import styled from "styled-components"
import { View, Text } from "../components"

export default ({}) => {
  return (
    <View bg="secondaryBackground" px={4} py={5}>
      <Text textStyle="headline" style={{ minHeight: 700 }}>
        Aectual connects designer from all over the globe to a state of the art
        digital craft & supply chain
      </Text>
    </View>
  )
}
