import React from "react"
import styled from "styled-components"
import { View, Text } from "."
import { ViewProps } from "./View"

interface CheckboxProps extends ViewProps {
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  children?: any
  color?: any
}

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs(() => ({
  type: "checkbox"
}))<{ type?: string }>`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<CheckboxProps>`
  position: absolute;
  top: -2px;
  left: -2px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    /* box-shadow: 0 0 0 3px pink; */
  }
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-appearance: none;
  outline: 0;
  width: 20px;
  height: 20px;
  display: block;
  padding: 0;
  cursor: pointer;
  background-image: ${({ checked }) =>
    checked
      ? `url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"> <path id="checkbox-9-icon" d="M81,81v350h350V81H81z M227.383,345.013l-81.476-81.498l34.69-34.697l46.783,46.794l108.007-108.005 l34.706,34.684L227.383,345.013z"></path> </svg>')`
      : `url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"> <path id="checkbox-9-icon" d="M391,121v270H121V121H391z M431,81H81v350h350V81z"></path> </svg>')`};
`

const CheckboxContainer = styled(View).attrs(() => ({ as: "div" }))<
  CheckboxProps
>`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 20px;
  height: 20px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const Checkbox = ({ checked, onChange, children, ...props }: CheckboxProps) => (
  <CheckboxContainer {...props}>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked}></StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
