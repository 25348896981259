import React, { useRef, useLayoutEffect } from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"

const scrollIntoViewSmoothly =
  "scrollBehavior" in document.documentElement.style
    ? scrollIntoView
    : smoothScrollIntoView

export default ({ path }: { path?: string }) => {
  const ref = useRef(null)
  const to = useRef<number | undefined>()
  useLayoutEffect(() => {
    if (
      !!ref.current &&
      window.sessionStorage.getItem("navigated") !== "scroll"
    ) {
      console.log("scroll to ", path)
      to.current = setTimeout(() => {
        scrollIntoViewSmoothly(ref.current!, {
          behavior: "auto",
          scrollMode: "always",
          block: "start",
          inline: "start",
        })
      }, 250)
    }
    return () => clearTimeout(to.current)
  }, [])
  return (
    <div
      ref={ref}
      style={{
        height: 0,
        position: "relative",
        top: -80,
      }}
    ></div>
  )
}
