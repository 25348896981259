import React, { useState, useEffect, useLayoutEffect, useCallback } from "react"
import { useLocation } from "react-use"

const ManageScroll = () => {
  const location = useLocation()
  const [scrollPositions, setScrollPositions] = useState({})
  const [scrollY, setScrollY] = useState(0)
  const key = location.state ? location.state.key : "no-key"

  useEffect(() => {
    const newScrollPositions = { ...scrollPositions, [key]: window.scrollY }
    try {
      window.sessionStorage.setItem(
        "scrollPositions",
        JSON.stringify(newScrollPositions)
      )
    } catch (e) {}
    setScrollPositions({ ...newScrollPositions })
  }, [scrollY])

  const listener = () => {
    setScrollY(window.scrollY)
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!window.sessionStorage.getItem("noScroll")) {
        if (scrollPositions[key]) {
          window.scrollTo(0, scrollPositions[key])
        } else {
          window.scrollTo(0, 0)
        }
      }
    }, 50)
  }, [key])

  useEffect(() => {
    let storage = window.sessionStorage.getItem("scrollPositions")
    if (storage) {
      setScrollPositions(JSON.parse(storage) || {})
      if (scrollPositions[key]) {
        window.scrollTo(0, scrollPositions[key])
      }
    }
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  }, [])
  return null
}

export default ManageScroll
