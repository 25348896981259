import React, { createContext, useContext, useState } from "react"
import styled from "styled-components"
import { Stack, View, Text, Button } from "../components"
import { useToggle } from "react-hooks-lib"
import MenuToggle from "../components/MenuToggle"
import { OverlayContainer } from "../user/AuthOverlay"

const ContactOverlayContext = createContext({ toggle: () => {} })

export const AuthContactConsumer = ContactOverlayContext.Consumer

export const useContactOverlay = () => useContext(ContactOverlayContext)

export const ContactDetails = (props) => (
  <Stack gap={3} {...props}>
    <Text>
      Phone:
      <br />
      <a href="tel:+31202291786" style={{ textDecoration: "underline" }}>
        +31 (0)20 229 1786
      </a>
    </Text>
    <Text>
      Email:
      <br />
      <a
        href="mailto:sales@aectual.com"
        style={{ textDecoration: "underline" }}
      >
        sales@aectual.com
      </a>
    </Text>
    <Text>
      Address:
      <br />
      Aectual B.V.
      <br />
      Naritaweg 14, 1043 BZ, Amsterdam (NL)
    </Text>
  </Stack>
)

const ContactOverlayProvider = ({ children }) => {
  const { on, toggle } = useToggle(false)

  return (
    <ContactOverlayContext.Provider value={{ toggle }}>
      {children}
      <OverlayContainer visible={on} bg="alternateBackground">
        <View p={[2, 5, 7]} style={{ position: "absolute", right: 0, top: 0 }}>
          <MenuToggle
            active={true}
            toggle={toggle}
            color="black"
            style={{ marginRight: -4, marginTop: 6 }}
          />
        </View>
        <View
          mt={[4, 6, 8]}
          pt={[4, 6, 8]}
          style={{ maxWidth: "24em", marginLeft: "auto", marginRight: "auto" }}
        >
          <Text textStyle="title">
            Get in touch by email or phone and we'll be happy to give you
            instant feedback about all possibilities for your project.
          </Text>
          <ContactDetails mt={6} />
        </View>
      </OverlayContainer>
    </ContactOverlayContext.Provider>
  )
}

export default ContactOverlayProvider
