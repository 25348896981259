import React from "react"
// import styled from "styled-components"
import Button from "./Button"

/*
const ButtonOutline = styled(Button).attrs(() => ({
  bg: "secondary",
  color: "primary"
}))`
  border: 1px solid black;
`
*/

const ButtonOutline = (props) => (
  <Button
    {...props}
    bg="white"
    color="primary"
    style={{ border: "1px solid black", ...props.style }}
  />
)

export default ButtonOutline
