import React from "react"
import styled from "styled-components"
import View from "./View"

const Circle = styled(View)<{ medium?: boolean; big?: boolean }>`
  position: relative;
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  width: ${(props) => (props.big ? 96 : props.medium ? 48 : 36)}px;
  height: ${(props) => (props.big ? 96 : props.medium ? 48 : 36)}px;
  border-radius: ${(props) =>
    props.big ? 96 / 2 : props.medium ? 48 / 2 : 36 / 2}px;
`

const ProfileImage = styled.img<{ medium?: boolean; big?: boolean }>`
  width: ${(props) => (props.big ? 96 : props.medium ? 48 : 36)}px;
  height: ${(props) => (props.big ? 96 : props.medium ? 48 : 36)}px;
  border-radius: ${(props) =>
    props.big ? 96 / 2 : props.medium ? 48 / 2 : 36 / 2}px;
  object-fit: cover;
`

export default ({ url = "/images/avatar-placeholder.png", ...rest }) => (
  <Circle {...rest}>
    <ProfileImage src={url} {...rest} />
  </Circle>
)
