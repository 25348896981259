import React from "react"
import styled from "styled-components"
import { Composition } from "atomic-layout"
import { View, Text } from "./components"

const areas = `
  side header
  side body
  side footer
`

const StyledComposition = styled(Composition)`
  height: 100vh;
  & > * {
    box-shadow: 0 0 0 1px black;
  }
`

export const Page = () => (
  <StyledComposition areas={areas} gap={1}>
    {({ Header, Body, Footer, Header2, Body2, Side }) => (
      <>
        <Header>
          <Text p={3}>
            The Header <Text>More Text</Text>
          </Text>
        </Header>
        <Body>
          <Text p={3}>The Body</Text>
        </Body>
        <Footer>
          <Text p={3}>The Footer</Text>
        </Footer>
        <Header2>
          <Text p={3}>The Header</Text>
        </Header2>
        <Body2>
          <Text p={3}>The Body</Text>
        </Body2>
        <Side>
          <Text p={3}>The Side</Text>
        </Side>
      </>
    )}
  </StyledComposition>
)

export default ({ children }) => (
  <StyledComposition
    areas={areas}
    gap={1}
    templateCols="24em auto"
    templateRows="100px auto 100px"
  >
    {({ Header, Body, Footer, Side }) => (
      <>
        <Side>{children[0]}</Side>
        <Header></Header>
        <Body>{children[1]}</Body>
        <Footer></Footer>
      </>
    )}
  </StyledComposition>
)
