import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"

if (!process.env.REACT_APP_GRAPHQL_MODELS_ENDPOINT) {
  window.alert(
    "Please specify the models graphql endpoint in the environment variables."
  )
}

const cache = new InMemoryCache()

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_MODELS_ENDPOINT
})

const client = new ApolloClient({
  cache,
  link: httpLink
})

export default client
