import React from "react"
import styled from "styled-components"
import { space, color } from "styled-system"

const renderGraphic = (name) => {
  switch (name) {
    case "arrow-right":
      return (
        <g>
          <path
            d="M691.28,780.047l8.406,8.367,1.535-1.491-5.87-5.868h15.023V779.04H695.351l5.87-5.868-1.535-1.489Z"
            transform="translate(710.374 788.414) rotate(180)"
            fill="currentColor"
          />
        </g>
      )
    case "arrow-left":
      return (
        <g transform="translate(21 20) rotate(180)">
          <path
            d="M691.28,780.047l8.406,8.367,1.535-1.491-5.87-5.868h15.023V779.04H695.351l5.87-5.868-1.535-1.489Z"
            transform="translate(710.374 788.414) rotate(180)"
            fill="currentColor"
          />
        </g>
      )
    case "arrow-down":
      return (
        <g transform="translate(20 2) rotate(90)">
          <path
            d="M691.28,780.047l8.406,8.367,1.535-1.491-5.87-5.868h15.023V779.04H695.351l5.87-5.868-1.535-1.489Z"
            transform="translate(710.374 788.414) rotate(180)"
            fill="currentColor"
          />
        </g>
      )
    case "arrow-up":
      return (
        <g transform="translate(0 19) rotate(-90)">
          <path
            d="M691.28,780.047l8.406,8.367,1.535-1.491-5.87-5.868h15.023V779.04H695.351l5.87-5.868-1.535-1.489Z"
            transform="translate(710.374 788.414) rotate(180)"
            fill="currentColor"
          />
        </g>
      )
    case "mode-edit":
      return (
        <g>
          <path d="M3 17.25v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
        </g>
      )
    case "add":
      return (
        <g>
          <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z"></path>
        </g>
      )
    case "delete":
      return (
        <g>
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12zm13-15h-3.5l-1-1h-5l-1 1h-3.5v2h14v-2z"></path>
        </g>
      )
    case "image":
      return (
        <g
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
          <circle cx="8.5" cy="8.5" r="1.5"></circle>
          <polyline points="21 15 16 10 5 21"></polyline>
        </g>
      )
    default:
      return null
  }
}

const styles = (size) => ({
  fill: "currentcolor",
  verticalAlign: "middle",
  width: size,
  height: size,
})

export default styled(({ icon, name, size, style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    style={{ ...styles(size ? size : 24), ...style }}
    {...rest}
  >
    {renderGraphic(icon || name)}
  </svg>
))`
  ${space};
  ${color};
`
