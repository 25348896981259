import gql from "graphql-tag"
import { useQuery, useMutation, useLazyQuery } from "react-apollo"
import client from "./sanityClient"

export const IMAGE = `
  asset {
    id: _id
    _id
    extension
    mimeType
    path
    assetId
    metadata {
      dimensions {
        width
        height
        aspectRatio
      }
    }
  }
  crop {
    bottom
    left
    right
    top
  }
  hotspot {
    height
    width
    x
    y
  }
`

const PROMO_BLOCK = `
  id: _key
  blockType
  fullWidth
  catchphrase
  subtitle
  subtext
  textColor
  zeroMargin
  list {
    id: _key
    title
    textRaw
    image {
      image {
        ${IMAGE}
      }
      mobile {
        ${IMAGE}
      }
      url
    }
    moreRaw
    action {
      label
      action
    }
  }
  actions {
    id: _key
    label
    action
  }
  images {
    id: _key
    image {
      ${IMAGE}
    }
    mobile {
      ${IMAGE}
    }
    url
  }
  slides {
    id: _key
    title
    descriptionRaw
    image {
      image {
        ${IMAGE}
      }
      mobile {
        ${IMAGE}
      }
      url
    }
  }
  videoUrl
`

const HOME = gql`
  query HomeOverview {
    allOverview(where: { title: { eq: "Home" } }) {
      id: _id
      promoBlocks {
        label
        ${PROMO_BLOCK}
      }
    }
    allPost(sort: {publishedAt:DESC}) {
      publishedAt
      bodyRaw
      title
      mainImage {
       ${IMAGE}
      }
    }
    allCustomerQuote {
      id: _id
      name
      position
      author {
        id: _key
        name
      }
      quoteRaw
      avatarImage {
        ${IMAGE}
      }
      logoImage {
        ${IMAGE}
      }
    }
  }
`

const PRODUCT_CATEGORIES = gql`
  query ProductCategories {
    allOverview(where: { slug: { current: { eq: "products" } } }) {
      id: _id
      title
      slug {
        current
      }
      promoBlocks {
        ${PROMO_BLOCK}
      }
    }
    allProductCategory(where: { slug: { current: { eq: "main" } } }) {
      id: _id
      parent {
        id: _id
        name
        label
        slug {
          current
        }
      }
      children {
        id: _id
        name
        label
        slug {
          current
        }
        image {
          image {
            ${IMAGE}
          }
          url
        }
        children {
          id: _id
          name
          label
          slug {
            current
          }
          image {
          image {
            ${IMAGE}
          }
          url
        }
        }
      }
      name
      label
      slug {
        current
      }
      image {
        image {
          ${IMAGE}
        }
        url
      }
      overview {
        id: _id
        title
        promoBlocks {
          ${PROMO_BLOCK}
        }
      }
    }
  }
`

const CATEGORY_OVERVIEW = gql`

  fragment variantFields on DesignerVariationDocument {
    id: _id
    variantKey: title
    title: displayName
    designer
    author {
      name
    }
    ticket
    secret
    image {
      ${IMAGE}
    }
    presets {
      id: _key
      title
      parameters
      image {
        ${IMAGE}
      }
    }
  }

  query CategoryOverview($slug: String!) {
    allProductCategory(where: { slug: { current: { eq: $slug } } }) {
      id: _id
      name
      parent {
        id: _id
        name
        slug {
          current
        }
      }
      children {
        id: _id
        name
        disabled
        slug {
          current
        }
        image {
          image {
            ${IMAGE}
          }
          url
        }
      }
      overview {
        id: _id
        title
        promoBlocks {
          ${PROMO_BLOCK}
        }
      }
      productPageVariations {
        ...variantFields
      }
    }
  }
`

const ALL_CATEGORY_IDS = gql`
  query Categories {
    allProductCategory {
      id: _id
      name
      slug {
        current
      }
    }
  }
`

const COLLECTION = gql`
  query CollectionBySlug($slug: String!) {
    allCollection(where: { slug: { current: { eq: $slug } } }) {
      id: _id
      slug {
        current
      }
      title
      sections {
        id: _key
        title
        slug {
          current
        }
        promoBlocks {
          ${PROMO_BLOCK}
        }
      }
    }
    allPost(sort: {publishedAt:DESC}) {
      publishedAt
      bodyRaw
      title
      mainImage {
       ${IMAGE}
      }
    }
  }
`

// Create 'designers' data type...
const DESIGNERS = gql`
  query Authors {
    allAuthor {
      id: _id
      name
      bioRaw
      image {
        id: _key
        image {
          ${IMAGE}
        }
      }
    }
  }
`

const PRODUCT_CATEGORY = gql`
  query ProductCategory($id: ID!) {    
    allOverview(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      promoBlocks {
        ${PROMO_BLOCK}
      }
    }
    allPossibilities(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      promoBlocks {
        ${PROMO_BLOCK}
      }
    }
    allSustainability(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      promoBlocks {
        ${PROMO_BLOCK}
      }
    }
    allToolsServices(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      promoBlocks {
        ${PROMO_BLOCK}
      }
    }
    allFaq(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      catchphrase
      text
      questions {
        id: _key
        question
        answerRaw
      }
    }
    allInUse(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      title: catchphrase
      text
      projects {
        id: _id
        client
        designer
        author {
          id: _id
          name
        }
        slides {
          id: _key
          title
          descriptionRaw
          image {
            image {
              ${IMAGE}
            }
          }
        }
      }
    }
    allDesignerVariations(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      title: catchphrase
      text
      designerVariations {
        id: _key
        showOnProductPage
        display
        access
        title
        designer
        description
        image {
          ${IMAGE}
        }
      }
    }
    allMaterials(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      title
      text
      materials {
        id: _key
        title
        code
        description
        image {
          ${IMAGE}
        }
      }
    }
    allDownloads(where: { _: { references: $id, is_draft: false } }) {
      id: _id
      title
      downloads {
        id: _key
        name
        description
        type
        file {
          asset {
            id: _id
            url
            originalFilename
          }
        }
      }
    }
    ProductCategory(id: $id) {
      id: _id
      name
      children {
        id: _id
        name
        slug {
          current
        }
        image {
          image {
            ${IMAGE}
          }
          url
        }
      }
      overview {
        id: _id
        title
        promoBlocks {
          ${PROMO_BLOCK}
        }
      }
      samples {
        id: _id
        price
        title
        displayName
        description
        image {
          ${IMAGE}
        }
      }
    }
  }
`

export const useHomeQuery = (options) => useQuery(HOME, { client, ...options })

export const useCategoryIdsQuery = (options) =>
  useQuery(ALL_CATEGORY_IDS, { client, ...options })

export const useProductCategoryQuery = (options) =>
  useLazyQuery(PRODUCT_CATEGORY, {
    client,
    ...options,
  })

export const useProductCategoriesQuery = (options) =>
  useQuery(PRODUCT_CATEGORIES, { client, ...options })

export const useCategoryOverviewQuery = (options) =>
  useQuery(CATEGORY_OVERVIEW, { client, ...options })

export const useCollectionQuery = (options) =>
  useQuery(COLLECTION, { client, ...options })

export const useDesignersQuery = (options) =>
  useQuery(DESIGNERS, { client, ...options })
