import React from "react"
import styled from "styled-components"
import { View, Text } from "./"

const Container = styled(View).attrs(() => ({ bg: "black" }))`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 82px);
`

const Area = styled(View).attrs(() => ({ bg: "black" }))`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 480px;
  height: 200px;
  border: 1px solid white;
`

const UploadArea = () => (
  <Container>
    <Area>
      <Text color="primaryText">Drag and Drop a file</Text>
    </Area>
  </Container>
)

export default UploadArea
