import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
  Icon,
} from "../../components"
import client from "../../user/authClient"
import { useUser, useAuth } from "../../user/AuthProvider"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import { Container, DashboardContentHeader } from "../projects/ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import Viewer from "../../shapediver/Viewer"
import Popover from "react-awesome-popover"
import NewModelPage from "./NewModel"
import { SpecItem } from "../project/ProjectPage"

const MODELS_QUERY = gql`
  query clientProduct($id: Int!) {
    product: clientProduct(id: $id) {
      id
      models {
        id
        title
        ticket
        parameters
      }
    }
  }
`

const CREATE_MODEL_MUTATION = gql`
  mutation createModel(
    $title: String!
    $productId: Int!
    $ticket: String!
    $secret: String
    $parameterSectionsKey: String
    $parameters: String
  ) {
    createModel(
      input: {
        title: $title
        productId: $productId
        ticket: $ticket
        backendTicket: $secret
        parameterSectionsKey: $parameterSectionsKey
        parameters: $parameters
      }
    ) {
      model {
        id
        title
        ticket
        backendTicket
        parameterSectionsKey
        parameters
      }
    }
  }
`

export const useCreateModel = () =>
  useMutation(CREATE_MODEL_MUTATION, { client })

export default ({ productId, viewModel = true, refetch, children, ticket }) => {
  const user = useUser()

  const { data, refetch: refetchModels } = useQuery(MODELS_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
    fetchPolicy: "network-only",
  })

  const [newModel] = useMutation(CREATE_MODEL_MUTATION, { client })

  const models = data?.product?.models ?? []
  console.log({ models })

  const model = models.length > 0 && models.slice(-1)[0]
  console.log({ model })
  console.log("TICKET ", model.ticket)
  return (
    <>
      <Text
        textStyle="action"
        style={{ position: "absolute", left: 0, top: 0 }}
      ></Text>
      {viewModel && (
        <>
          {model && viewModel ? (
            <SpecItem
              label="Custom Product Status"
              key={model.id}
              textStyle="action"
            >
              <Viewer
                propTicket={ticket || model.ticket}
                preview
                propParameters={
                  model.parameters && JSON.parse(model.parameters)
                }
              />
              {/*
                    <Link key={model.id} to={`model/${model.id}`} block my={2}>
            <Icon
              name="mode-edit"
              size={18}
              style={{ position: "relative", top: -3 }}
            />{" "}
            Edit Design
          </Link>
          */}
            </SpecItem>
          ) : (
            <SpecItem label="Custom Product Status">
              <div style={{ minHeight: 200 }} />
            </SpecItem>
          )}
        </>
      )}
      {children}
      {user.employee && (
        <View mt={8}>
          <Text mt={4} mb={2}>
            Actions for employee:
          </Text>

          <Grid gridGap={[3, 4, 5]} minColumnWidth={[180, 180, 180]}>
            <Text>Models:</Text>

            <Text>
              <Popover arrowProps={{ style: { color: "white" } }}>
                <View pointer hover>
                  New Model
                </View>
                <div>
                  <NewModelPage
                    propProductId={productId}
                    refetch={() => {
                      refetch()
                      refetchModels()
                    }}
                  />
                </div>
              </Popover>
            </Text>

            {[...models].reverse().map((model) => (
              <Link key={model.id} to={`model/${model.id}`}>
                {model.title || model.name}
              </Link>
            ))}

            <div />
            <div />
            <div />
          </Grid>
        </View>
      )}
    </>
  )
}
