import React from "react"
import styled from "styled-components"
import { Text } from "./"

export default styled(Text).attrs(() => ({
  textStyle: "footnote",
  as: "label",
}))`
  display: block;
  text-transform: uppercase;
`
