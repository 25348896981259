import React from "react"
import styled from "styled-components"
import { View, Stack, Grid, Headline, Divider, Text, Link } from "./components"
import { useLocation } from "react-router-dom"
import { useContactOverlay } from "./components/ContactOverlay"
const Container = styled(View).attrs(() => ({
  py: 5,
  px: 0,
}))`
  min-height: 200px;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 550px) {
    display: none;
  }
`

const Bottom = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const FlexContainer = styled(View).attrs(() => ({
  as: "footer",
  px: [2, 4, 4],
}))`
  display: flex;
  flex-wrap: wrap;

  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 29.8125rem /*477px*/) {
    justify-content: space-around;
  }
  @media only screen and (min-width: 77.5rem /*1240px*/) {
    justify-content: space-evenly;
  }
`

const MainItem = styled(View).attrs(({ pr = 3 }) => ({ pr, mt: 3 }))`
  min-width: 12.5rem /*200px*/;
  flex: 1;
`

const Item = styled(View).attrs(() => ({ as: "li", mt: 1 }))``

const List = styled(Stack).attrs(() => ({ as: "ul" }))``

const Copyright = styled(View)`
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
`

export default (): JSX.Element => {
  const { toggle } = useContactOverlay()
  const location = useLocation()
  console.log({ location })
  return location.pathname.includes("dashboard") ||
    location.pathname.includes("model") ? (
    <div />
  ) : (
    <Container bg="alternateBackground" mt={[4, 5, 6]}>
      <FlexContainer>
        <MainItem>
          <Text textStyle="action" mb={2}>
            About
          </Text>
          <List>
            <Item>
              <Link to="/about/our-mission">Our Mission</Link>
            </Item>
            <Item>
              <Link to="/about/for-designers">For Designers</Link>
            </Item>
            <Item>
              <Link to="/about/for-product-manufacturers">
                For Product Manufacturers
              </Link>
            </Item>
            <Item>
              <Link to="/about/news">News & Media</Link>
            </Item>
            <Item>
              <Link to="/about/team">Team</Link>
            </Item>
            <Item>
              <Link to="/about/press">Press</Link>
            </Item>
            <Item>
              <Link to="/about/contact">Contact</Link>
            </Item>
          </List>
        </MainItem>
        <MainItem>
          <Text textStyle="action" mb={2}>
            Product Categories
          </Text>
          <List>
            <Item>
              <Link to="/architectural-products">Overview</Link>
            </Item>
            <Item>
              <Link to="/architectural-products/flooring">Flooring</Link>
            </Item>
            <Item>
              <Link to="/architectural-products/space-dividers">
                Space Dividers
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/wall-paneling">
                Wall Paneling
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/facade-cladding">
                Facade Cladding
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/pre-cast-concrete2">
                Pre-cast Concrete
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/architectural-furniture">
                Architectural Furniture
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/outdoor">Outdoor</Link>
            </Item>
          </List>
        </MainItem>
        <MainItem>
          <Text textStyle="action" mb={2}>
            Architecural Products
          </Text>
          <List>
            <Item>
              <Link to="/architectural-products/flooring/variants/pattern-terrazzo">
                Pattern Terrazzo
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/flooring/variants/pattern-terrazzo-lite">
                Pattern Terrazzo Lite
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/space-dividers/variants/curve">
                Curve Space Divider
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/wall-paneling/variants/woven">
                Weave Wall Paneling
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/wall-paneling/variants/sculpture">
                Sculpt Wall Paneling
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/wall-paneling/variants/curve">
                Curve Wall Paneling
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/facade-cladding/variants/circular-facades">
                Circular Facades
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/facade-cladding/variants/gfrc-panels">
                GFRC Panels
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/pre-cast-concrete2/variants/stairs">
                Pre-cast Concrete Stairs
              </Link>
            </Item>
            <Item>
              <Link to="/architectural-products/pre-cast-concrete2/variants/pavement">
                Concrete Pavement
              </Link>
            </Item>
          </List>
        </MainItem>

        <MainItem>
          <Text textStyle="action" mb={2}>
            Manufacturing Capabilities
          </Text>
          <List>
            <Item>
              <Link to="/manufacturing-capabilities/xl-3d-printing">
                XL 3D Printing
              </Link>
            </Item>
            <Item>
              <Link to="/manufacturing-capabilities/3dp-bio-polymer">
                3DP + Bio Polymer
              </Link>
            </Item>
            <Item>
              <Link to="/manufacturing-capabilities/3dp-concrete">
                3DP + Concrete
              </Link>
            </Item>
            <Item>
              <Link to="/manufacturing-capabilities/3dp-terrazzo">
                3DP + Terrazzo
              </Link>
            </Item>
            <Item>
              <Link to="/manufacturing-capabilities/3dp-recycled-waste">
                3DP + Recycled waste
              </Link>
            </Item>
          </List>
        </MainItem>

        <MainItem>
          <Text textStyle="action" mb={2}>
            Sustainability
          </Text>
          <List>
            <Item>
              <Link to="/sustainability/circular">Circular</Link>
            </Item>
            <Item>
              <Link to="/sustainability/material-reduction">
                Material reduction
              </Link>
            </Item>
            <Item>
              <Link to="/sustainability/zero-waste">Zero waste</Link>
            </Item>
            <Item>
              <Link to="/sustainability/bio-based">Bio based</Link>
            </Item>
            <Item>
              <Link to="/sustainability/recycle-waste-streams">
                Recycle waste streams
              </Link>
            </Item>
            <Item>
              <Link to="/sustainability/local-economy">Local Economy</Link>
            </Item>
            <Item>
              <Link to="/sustainability/leed-breeam-well">
                LEED, BREEAM, Well
              </Link>
            </Item>
          </List>
        </MainItem>

        <MainItem>
          <Text textStyle="action" mb={2}>
            Contact
          </Text>
          <List>
            <Item>
              <Text onClick={toggle} hover pointer>
                Email us
              </Text>
            </Item>
            <Item>
              <Text onClick={toggle} hover pointer>
                Call us
              </Text>
            </Item>
            <Item>
              <Text onClick={toggle} hover pointer>
                Office
              </Text>
            </Item>
          </List>
        </MainItem>
      </FlexContainer>
      <Copyright>
        <Text textStyle="footnote" my={3} px={[2, 4, 4]} color="#888">
          Copyright © 2020 Aectual B.V.
        </Text>
      </Copyright>
    </Container>
  )
}
