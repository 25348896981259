// @ts-ignore
import React, { Fragment } from "react"
import { View } from "./"
import {
  background,
  BackgroundProps,
  bottom,
  BottomProps,
  color as styledColor,
  ColorProps,
  display,
  DisplayProps,
  height,
  HeightProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  right,
  RightProps,
  space as styledSpace,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
} from "styled-system"

interface MoreProps {
  children: Array<any>
  gap?: number | Array<number>
  bg?: string
  style?: any
  flex?: boolean
}

export interface StackProps extends SpaceProps, MoreProps {}

const Stack: React.FunctionComponent<StackProps> = ({
  children,
  gap = 2,
  ...rest
}): any => {
  if (!children) return null
  const Wrapper = View // Object.keys(rest).length > 0 ? View : Fragment
  const firstChild = children.findIndex((child) => !!child)
  let lastChild = [...children].reverse().findIndex((child) => !!child)
  if (lastChild > -1) {
    lastChild = children.length - lastChild - 1
  }
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (!child) return null
    if (index < firstChild || index > lastChild) {
      return child
    }
    const addProps: any =
      child.props.m || child.props.my
        ? null
        : {
            ...(index > firstChild && { mt: child.props.mt || gap }),
            ...(index < lastChild && { mb: child.props.mb || gap }),
          }
    return React.cloneElement(child, addProps)
  })
  return <Wrapper {...rest}>{childrenWithProps}</Wrapper>
}

export default Stack
