import React, { useState } from "react"
import styled from "styled-components"
import { View, Text, Divider, SideBySide } from "../components"
import BlockContent from "@sanity/block-content-to-react"
import Arrow from "../components/Arrow"

const Inline = styled(View)<{ onClick?: any }>`
  display: flex;
  justify-content: space-between;
`

export default ({ questions }) => {
  const [expand, setExpand] = useState(-1)
  return (
    <View>
      {questions.map((question, index) => (
        <>
          <Divider />
          <Inline onClick={() => setExpand(expand === index ? -1 : index)}>
            <Text textStyle="title">{question.question}</Text>
            <Text
              textStyle="title"
              style={{
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              pt={2}
            >
              <Arrow
                style={{
                  transform:
                    expand !== index ? "rotate(180deg)" : "rotate(90deg)",
                }}
              />
            </Text>
          </Inline>
          {expand === index && (
            <Text mt={3} style={{ maxWidth: "32em" }}>
              <BlockContent blocks={question.answerRaw} />
            </Text>
          )}
        </>
      ))}
      <Divider />
    </View>
  )
}
