// @ts-ignore
import React from "react"
import styled from "styled-components"
import { View } from "./"
import { space } from "styled-system"
/*
function stuc<T>(El: string = 'div') {
  type PropType = T & { children?: JSX.Element[], className?: string }
  return (props: PropType) => <El className={props.className}>{props.children}</El>
}
*/
// const Container = stuc<{customProp?: boolean}>()

interface DividerProps {
  thick?: boolean
  color?: string
}

export default styled(View).attrs(({ color = "border", mb = 2 }) => ({
  as: "hr",
  color,
  mb,
}))<DividerProps>`
  position: relative;
  overflow: visible;
  border: 0;
  border-bottom: 1px solid transparent;
  background: none;
  ${(props): string =>
    props.thick
      ? `border-bottom: 2px solid currentColor;`
      : `border-bottom: 1px solid currentColor;
  `}
  ${space};
`
