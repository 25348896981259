import React, { Fragment, useState, useLayoutEffect } from "react"
import ReactDOM, { createPortal } from "react-dom"

const Portal = ({ children, portal = "" }) => {
  const [showPortal, setShowPortal] = useState(false)

  useLayoutEffect(() => {
    setShowPortal(true)
  }, [])
  return !!portal ? (
    showPortal ? (
      createPortal(
        children,
        // @ts-ignore
        window.document.getElementById(
          // @ts-ignore
          portal
        )
      )
    ) : null
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default Portal
