import { SVGLoader as loader } from "three/examples/jsm/loaders/SVGLoader"
import flatten from "lodash-es/flatten"

const svg = url =>
  new Promise(resolve =>
    new loader().load(url, shapes => {
      console.log(shapes)

      resolve(
        flatten(
          shapes.paths.map((group, index) =>
            group
              .toShapes(false, false)
              .map(shape => ({ shape, color: "#555", index }))
          )
        )
      )
    })
  )

export default svg
