export default [
  "Floor",
  "Wall",
  "Ceiling",
  "Stair",
  "Column",
  "Façade",
  "Canopy",
  "Pavement",
  "Fence"
]
