import React, { useRef, useState, useEffect, useMemo } from "react"
import { Canvas, useFrame, useUpdate } from "react-three-fiber"
import * as THREE from "three"
import Controls from "./Controls"

import svg from "./svg"

interface ShapeInterface {
  color?: string
  index?: number
  shape?: any
}

function Shape({ shape, rotation, position }) {
  return (
    <mesh rotation={rotation} position={position}>
      <lineBasicMaterial
        attach="material"
        color="blue"
        linewidth={3}
        // @ts-ignore
        wireframe
      />
      <shapeBufferGeometry attach="geometry" args={[shape]} />
    </mesh>
  )
}

function LinePath(props) {
  const vertices = React.useMemo(() => {
    const path = new THREE.Path()
    const firstPoint = props.vertices[0]

    path.moveTo(firstPoint.x, firstPoint.y)
    props.vertices.forEach((point) => path.lineTo(point.x * 50, point.y * 50))
    path.closePath()

    return path.getPoints()
  }, [props.vertices])

  const ref = useUpdate((geometry) => {
    // @ts-ignore
    geometry.setFromPoints(vertices)
    console.log(vertices)
  }, [])

  return (
    <line>
      <bufferGeometry attach="geometry" />
      <lineBasicMaterial attach="material" color="#444" />
    </line>
  )
}

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  // @ts-ignore
  // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.02))
  /*
  const geometry = new THREE.DodecahedronBufferGeometry(1, 1)
  const edges = new THREE.EdgesGeometry(geometry)
  const lines = new THREE.LineSegments(
    edges,
    new THREE.LineBasicMaterial({ color: "red" })
  )
  */
  // @ts-ignore
  // const geom = useMemo(() => new THREE.DodecahedronBufferGeometry(1, 1))

  const geo = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(50, 50)
    return new THREE.EdgesGeometry(geometry)
  })
  // or WireframeGeometry( geometry )
  // var mat = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2 })
  // var wireframe = new THREE.LineSegments(geo, mat)

  return (
    <group {...props} scale={[10, 10, 10]}>
      <mesh
        ref={mesh}
        // scale={active ? [150, 150, 150] : [80, 80, 80]}
        position={[5, 5, 0]}
        onClick={(e) => {
          setActive(!active)
          window.alert(`Selected tile at ${props.x}, ${props.y} `)
        }}
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}
      >
        <planeGeometry attach="geometry" args={[10, 10]} />
        {/*<edgesGeometry
          attach="geometry"
          args={[new THREE.PlaneGeometry(1, 1)]}
        />*/}
        <meshStandardMaterial
          attach="material"
          color={"#333"}
          opacity={active ? 0.6 : hovered ? 0.3 : 0.1}
          transparent
        />
      </mesh>
      {/*}
      <line>
        <lineSegments attach="geometry" args={[geo]} />
        <lineBasicMaterial attach="material" color="black" />
      </line>*/}
      <LinePath
        vertices={[
          new THREE.Vector3(0, 0, 0),
          new THREE.Vector3(1, 0, 0),
          new THREE.Vector3(1, 1, 0),
          new THREE.Vector3(0, 1, 0),
        ]}
      />
    </group>
  )
  return (
    <mesh
      {...props}
      ref={mesh}
      // scale={active ? [150, 150, 150] : [80, 80, 80]}
      scale={[80, 80, 80]}
      onClick={(e) => {
        setActive(!active)
        window.alert(`Selected tile at ${props.x}, ${props.y} `)
      }}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial
        attach="material"
        color={"#333"}
        wireframe={!hovered && !active}
        opacity={active ? 0.6 : hovered ? 0.3 : 1}
        transparent
      />
    </mesh>
  )
}

const TheSVG = () => {
  const [shapes, setShapes] = useState<ShapeInterface[]>([])
  useEffect(
    // @ts-ignore
    () => void svg("/pier/200130_SCH.1702_Full Pattern.svg").then(setShapes),
    []
  )
  console.log({ shapes })
  if (shapes.length === 0) return null
  return (
    <group scale={[10, 10, 10]}>
      {shapes.map((shape, index) => (
        <Shape
          shape={shape.shape}
          key={shape.shape.uuid}
          {...shape}
          {...{
            rotation: [0, 0, 0],
            position: [-100, -300, 0],
            opacity: 1,
            trail: 5,
            lazy: true,
          }}
        />
      ))}
    </group>
  )
}

export default () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        left: 0,
        top: 0,
      }}
    >
      <Canvas
        pixelRatio={window.devicePixelRatio || 1}
        concurrent
        orthographic={true}
        camera={{
          fov: 80,
          position: [0, 0, 2000],
          rotation: [
            0,
            THREE.MathUtils.degToRad(-20),
            THREE.MathUtils.degToRad(180),
          ],
          near: 0.1,
          far: 20000,
        }}
      >
        <ambientLight intensity={0.5} />
        <spotLight intensity={0.5} position={[300, 300, 4000]} />
        {[...Array(100)].map((_, x) => (
          <>
            {[...Array(20)].map((_, y) => (
              <Box
                position={[-500 + x * 100, -300 + y * 100, Math.random() * 4]}
                x={x}
                y={y}
              />
            ))}
          </>
        ))}
        <TheSVG />
        <Controls />
      </Canvas>
    </div>
  )
}
