import React, { Children } from "react"
import { View } from "./"
import styled from "styled-components"

const mapToFlexJustify = (justify) => {
  const values = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    between: "space-between",
  }

  return values[justify]
}

const mapToFlexAlign = (align) => {
  const values = {
    top: "flex-start",
    center: "center",
    bottom: "flex-end",
  }

  return values[align]
}

const Wrapper = styled(View)<{
  justifyContent?: string
  gap?: number
  alignItems?: string
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`

const Flex = styled(View)<{ flex?: boolean }>`
  ${(props) =>
    props.flex &&
    `
    flex: 1
  `}
`

const Inline = ({
  children,
  gap = 2,
  justify = "normal",
  verticalAlign = "bottom",
  ...rest
}) => {
  const justifyContent = mapToFlexJustify(justify)
  const alignItems = mapToFlexAlign(verticalAlign)
  return (
    <Wrapper
      gap={gap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...rest}
    >
      {Children.map(children, (child, index) =>
        child !== null && child !== undefined ? (
          child.props.flex ? (
            <Flex
              flex={child.props.flex}
              {...(index < children.length - 1 && { mr: gap })}
            >
              {child}
            </Flex>
          ) : (
            child
          )
        ) : null
      )}
    </Wrapper>
  )
}

export default Inline
