import * as React from "react"
import Popover from "./Popover/Popover"
import { Inline, Icon, Button, Text } from "."
import View from "./View"
import Input from "./Input"
import { useMutation } from "react-apollo"
import client from "../user/authClient"
import { useDebouncedCallback } from "use-debounce"
import { useToggle } from "react-hooks-lib"

const EditValuePopover = ({ value, updateMutation, thing, id }) => {
  const { on, toggle, reset } = useToggle(false)
  const [update] = useMutation(updateMutation, { client })

  const [debouncedUpdate] = useDebouncedCallback((value) => {
    update({
      variables: { id, [thing]: value },
    })
  }, 350)

  return !on ? (
    <Inline gap={0} style={{ width: "100%" }} bg="black">
      <Text flex color="white" px={1} py={1} mb={"1px"}>
        {value}
      </Text>
      <View
        hover
        pointer
        style={{ position: "relative", top: -4 }}
        onClick={toggle}
        mr={1}
      >
        <Icon name="mode-edit" color="white" size={18} />
      </View>
    </Inline>
  ) : (
    <Inline gap={0} mr={"-10px"} bg="background" style={{ width: "100%" }}>
      <Input
        onChange={(e) => debouncedUpdate(e.target.value)}
        style={{ outline: "none", height: "100%" }}
        defaultValue={value}
        flex
        px={1}
        py={0}
        autoFocus
        pb={"0.2rem"}
        onKeyPress={(e) => {
          if (e.key === "Enter") toggle()
        }}
      />
      <Button
        onClick={toggle}
        px={2}
        py={1}
        style={{ display: "block", height: "100%" }}
      >
        Done
      </Button>
    </Inline>
  )
}

export default EditValuePopover
