import React, { useState } from "react"
import styled from "styled-components"
import {
  View,
  Stack,
  Grid,
  Text,
  Divider,
  Inline,
  Icon,
  Navigator,
  RatioContainer,
} from "../components"
import BlockContent from "@sanity/block-content-to-react"
import { DateTime } from "luxon"
import { useToggle } from "react-hooks-lib"
import { SanityImage } from "../components/SlideImage"

/*
const StyledGrid = styled(Grid)`
  & > *:nth-last-child(-n + 2) {
    display: none;
  }
`
*/

const Controls = styled(Text)`
  display: flex;
  flex-direction: column;
  & > *:nth-child(2) {
    flex: 1;
  }
`

const News = ({ items, expand = false }) => {
  const { on, toggle, reset } = useToggle(expand)
  const [index, setIndex] = useState(0)

  return (
    <Grid gridGap={[3, 3, 3]} minColumnWidth={[180, "20%", "20%"]} mb={5}>
      <Controls>
        <Divider />
        <Text textStyle="title" mb={4}>
          <View mt={[4, 5, 6]}>News</View>
        </Text>
        <Navigator
          count={items.length - 3}
          active={index}
          next={() => setIndex(index + 1)}
          previous={() => setIndex(index - 1)}
          loop={false}
        />
      </Controls>
      {items?.slice(index, index + 3).map((item) => (
        <View>
          <Stack gap={3}>
            <Text>
              <Divider />
              {DateTime.fromISO(item.publishedAt).toFormat("dd.LL.yyyy")}
            </Text>
            <Inline verticalAlign="top"  mr={0}>
              <Text textStyle="subhead" flex mr={0} style={{minHeight: "2.5em"}}>
                {item.title}
              </Text>
              {!expand && (
                <Text pt={3} pointer hover onClick={toggle}>
                  <Icon name={!on ? "arrow-left" : "arrow-down"} />
                </Text>
              )}
            </Inline>
            {on && (
              <>
                {item.mainImage && (
                  <RatioContainer ratio={1}>
                    <SanityImage source={item.mainImage} />
                  </RatioContainer>
                )}
                <Text mt={item.mainImage ? 2 : 0}>
                  <BlockContent blocks={item.bodyRaw} />
                </Text>
              </>
            )}
          </Stack>
        </View>
      ))}
    </Grid>
  )
}

export default News
