import * as React from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import { useLocation } from "react-router-dom"

const RoutesTracker = ({ children }) => {
  const { trackPageView, trackEvent } = useMatomo()
  const location = useLocation()

  // Track page view
  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      trackPageView({
        // documentTitle: "Home", // optional
      })
    } else {
      console.log("do not track")
    }
  }, [location])
  return children
}

export default RoutesTracker
