import React from "react"
import styled from "styled-components"
import { Icon } from "./"

const Arrow = styled(Icon).attrs(() => ({
  name: "arrow-right",
}))`
  position: relative;
  transition: transform 250ms ease;
`

export const LeftArrow = styled(Icon).attrs(() => ({
  name: "arrow-left",
}))`
  position: relative;
  transition: transform 250ms ease;
`

export default Arrow
