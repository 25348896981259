import React from "react"
import styled from "styled-components"
import useTheme from "../utilities/useTheme"
import { useMedia } from "use-media"
import { View, Stack, Text, Link, Divider, Inline, Icon } from "../components"
import Logo from "../components/Logo"
// @ts-ignore
import { Routes, Route, Link as RouterLink } from "react-router-dom"

import { useUser } from "../user/AuthProvider"
import Arrow, { LeftArrow } from "../components/Arrow"
import Avatar from "../components/Avatar"
import LinesEllipsis from "react-lines-ellipsis"
import { useContactOverlay } from "../components/ContactOverlay"
import Badge from "../components/Badge"

import ProductInfoSide from "./product/ProductInfoSide"

const Container = styled(View)`
  display: flex;
`

const Sidebar = styled(View)`
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100vh;
  z-index: 7;
`

const ContentFrame = styled(View).attrs(() => ({
  pr: [2, 3, 3],
  py: [2, 3, 3],
  bg: "#efefef",
}))`
  position: relative;
  padding-left: 320px;
  flex: 1;
  min-height: 100vh;
  ::before {
    content: "";
    position: fixed;
    top: 0;
    width: 100%;
    background-color: inherit;
    height: 1.6rem;
    z-index: 567;
  }
  ::after {
    content: "";
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: inherit;
    height: 1.6rem;
    z-index: 568;
  }
`

const Content = styled(View).attrs(() => ({ bg: "white" }))`
  min-height: 100%;
  ::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    width: 1.6rem;
    z-index: 569;
  }
`

const VerticalFlex = styled(View)`
  display: flex;
  flex-direction: column;
  > *:first-child {
    /* flex: 1; */
  }
  overflow: hidden;
  height: 100%;
`

const SideFlex = styled(Stack)`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const EditorArea = styled(View)`
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
`

const GeneralInfoContainer = styled(View).attrs(() => ({
  px: [2, 3, 3],
  pb: 4,
}))`
  flex: 2000;
  overflow-y: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
`

const DashboardPage = ({ children }) => {
  const user = useUser()
  console.log({ user })
  const { toggle } = useContactOverlay()
  return (
    <Container>
      <Sidebar pt={3}>
        <VerticalFlex>
          <View mx={[2, 3, 3]} relative>
            <Link block to="/">
              <Logo style={{ height: "1.7rem", marginTop: "0.5rem" }} />
            </Link>
            <Badge style={{ position: "absolute", right: 0, top: 3 }}>
              BETA
            </Badge>
          </View>
          <Routes>
            <Route
              path=""
              element={
                <>
                  <View px={[2, 3, 3]} pb={6}>
                    <Divider mt={3} />

                    <Inline verticalAlign="center">
                      {user && (
                        <Text textStyle="title">
                          {user.company && user.company.length > 1
                            ? user.company
                            : user.name}
                        </Text>
                      )}
                    </Inline>
                  </View>
                  <GeneralInfoContainer>
                    <Text textStyle="action" mt={"7rem"}>
                      Welcome to your personal dashboard
                    </Text>
                    <Text textStyle="footnote" mt={3}>
                      Here, you can simplify your workflow by organizing each
                      product and design per project to create a comprehensive
                      product playlist over time.
                      <br />
                      <br />
                      During our beta period, we are continuously improving our
                      online services by adding products, designs, and
                      functionalities to help make your design process easier.
                      Please check back regularly to discover new features and
                      share your feedback and input with us.
                      <br />
                      <br />
                      Parallel to our onlines services, we’re always happy to
                      provide one-on-one personal advice on our product
                      possibilities and technologies.
                    </Text>
                    <Inline verticalAlign="top" mt={3}>
                      <Icon name="arrow-right" mr={1} />
                      <Text
                        pointer
                        hover
                        onClick={toggle}
                        style={{ textDecoration: "underline" }}
                      >
                        Don’t hesitate to email or phone us.
                      </Text>
                    </Inline>
                  </GeneralInfoContainer>
                </>
              }
            />
            <Route
              path="project/:projectId/product/variant/*"
              element={<></>}
            />
            <Route
              path="project/:projectId/product/:productId/model/:modelId"
              element={<></>}
            />
            <Route
              path="project/:projectId/product/:productId/quote"
              element={<></>}
            />
            <Route
              path="project/:projectId/product/:productId/sample"
              element={<></>}
            />
            <Route
              path="project/:projectId/product/:productId/:productType/*"
              element={<ProductInfoSide />}
            />
          </Routes>
          <div id="dashboard-side" />
          <EditorArea id="editor" />
          {user && (
            <View pb={[1, 2, 2]} px={[2, 3, 3]}>
              <Divider mb={1} />
              <Inline
                gap={2}
                py={1}
                style={{ overflow: "hidden" }}
                alignItems="center"
              >
                <View mr={1}>
                  <Avatar />
                </View>
                <View flex>
                  <View to="profile" as={RouterLink}>
                    <Text textStyle="body">{user.name}</Text>
                    <Text textStyle="footnote" pt={1}>
                      {user.email}
                    </Text>
                  </View>
                </View>
              </Inline>
            </View>
          )}
        </VerticalFlex>
      </Sidebar>
      <ContentFrame>
        <Content>{children}</Content>
      </ContentFrame>
    </Container>
  )
}

export default DashboardPage
