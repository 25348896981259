import React from "react"
import styled from "styled-components"
import { View } from "./"

const Center = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default Center
