import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import gql from "graphql-tag"
import { useMutation, useQuery } from "react-apollo"
import client from "../../user/authClient"
// @ts-ignore
import { useNavigate, useParams, Navigate } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  Input,
  Button,
  Link,
  Portal,
  Icon,
  Divider,
  Inline,
} from "../../components"
import ImageSelect from "../../components/ImageSelect"
import FilePreview from "../../components/FilePreview"
import { useForm } from "react-hook-form"
import { useUser } from "../../user/AuthProvider"
import {
  CLIENT_PRODUCT_QUERY,
  CREATE_FILE_MUTATION,
  CREATE_FILE_VERSION_MUTATION,
  useProductTypeSlug,
} from "../product/product-mutations"

const SEND_MESSAGE = gql`
  mutation sendMessage($subject: String!, $body: String!) {
    sendMessage(input: { subject: $subject, body: $body }) {
      success
    }
  }
`

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  center: true,
  pt: 5,
})`
  max-width: 26em;
`

const RequestQuotePage = () => {
  const user = useUser()
  let { productId = "" } = useParams()

  const { data, refetch } = useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
    fetchPolicy: "network-only",
  })

  const [success, setSuccess] = useState(false)

  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()
  const [sendMessage] = useMutation(SEND_MESSAGE, { client })

  const getProduct = () => data?.product

  const onSubmit = (data) => {
    const product = getProduct()

    const model = [...product.models].reverse()[0]
    const ticket = model.ticket
    const parameters = model.parameters

    const variables = {
      subject: "Quotation Request",
      body: `Quotation Request

From:
${user.company && user.company.length > 1 && `${user.company}\n`}
${user && user?.name}\n
${user && user?.email}\n
\n
Project:\n
${product?.project?.name}\n
\n
Product:\n
ID: ${product?.id}\n
Name: ${product?.name}\n
Category: ${product?.category?.name}\n
\n
Notes:\n
${data.notes}\n
\n
Ticket: ${ticket}\n
Parameters:\n
${JSON.stringify(JSON.parse(parameters), null, 2)}
      `,
    }
    sendMessage({ variables }).then((data) => {
      console.log(data)
      setSuccess(true)
    })
  }

  return (
    <>
      <Portal portal="dashboard-side">
        {user && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />

            <Inline
              as={Link}
              to="../../../../../"
              replace
              verticalAlign="center"
            >
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.project?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />

            <Inline as={Link} to="../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />

            <Text textStyle="title" mt={8} mb={8}>
              Request Quote
            </Text>
          </Stack>
        )}
      </Portal>

      <Container>
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          {success ? (
            <Text textStyle="title" mt={6}>
              We've successfilly received your request. We'll get back to you
              shortly.
            </Text>
          ) : (
            <Stack gap={4} mt={6}>
              <Text>
                From:
                <br />
                {user && user?.company!?.length > 1 && (
                  <>
                    {user.company}
                    <br />
                  </>
                )}
                {user && user?.name}
                <br />
                {user && user.email}
              </Text>
              <Text>
                Product:
                <br />
                ID: {data?.product?.id}
                <br />
                Name: {data?.product?.name}
                <br />
                Category: {data?.product?.category?.name}
              </Text>
              <View>
                <Text textStyle="footnote">Notes</Text>
                <Input
                  as="textarea"
                  name="notes"
                  mt={1}
                  ref={register()}
                  autoFocus
                  style={{ minHeight: 240 }}
                />
              </View>

              <View>
                <Button my={2} mr={3} as="button" type="submit">
                  Send Request
                </Button>
              </View>
            </Stack>
          )}
        </form>
      </Container>
    </>
  )
}

export default RequestQuotePage
