import gql from "graphql-tag"

const SLIDE_FRAGMENT = gql`
  fragment slideDetails on Slide {
    id
    order
    file {
      id
      secret
      url
    }
    width
    height
    title
    description
    tags {
      id
      name
    }
    keyValues {
      id
      key {
        name
      }
      value
    }
  }
`

export default SLIDE_FRAGMENT
