import React from "react"
import Vimeo from "@u-wave/react-vimeo"

/*
const embed = (link) => {
  var pattern, match, position
  var patterns = [
    {
      regex: /youtu\.be\/([\w\-.]+)/,
      type: "youtube",
      url: "https://www.youtube.com/embed/%1",
    },
    {
      regex: /youtube\.com(.+)v=([^&]+)/,
      type: "youtube",
      url: "https://www.youtube.com/embed/%2",
    },
    {
      regex: /vimeo\.com\/([0-9]+)/,
      type: "vimeo",
      url: "https://player.vimeo.com/video/%1",
    },
    {
      regex: /vimeo\.com\/(.*)\/([0-9]+)/,
      type: "vimeo",
      url: "https://player.vimeo.com/video/%2",
    },
  ]

  var url = ""

  for (var i = 0; i < patterns.length; i++) {
    pattern = patterns[i]

    if ((match = link.match(pattern.regex))) {
      position = pattern.url.match(/%([0-9]+)/)[1]
      // type = pattern.type
      url = pattern.url.replace(/%([0-9]+)/, match[position])
    }
  }

  return url
}
*/

export default ({
  videoLink,
  style,
  background = false,
  responsive = true,
}: {
  videoLink: string
  style?: any
  background?: boolean
  responsive?: boolean
}) =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#CCC",
        padding: "1em",
      }}
    >
      Dev mode, no video.
    </div>
  ) : (
    <Vimeo
      video={videoLink}
      autoplay
      muted
      responsive={responsive}
      loop
      background={background}
      style={style}
    />
  )

/*
  <iframe
    src={
      embed(videoLink) +
      `?api=1&title=0&byline=0&portrait=0&loop=1&autoplay=1&autopause=0&background=${
        background ? "1" : "0"
      }`
    }
    style={{ borderStyle: "none", overflow: "hidden", ...style }}
  />
*/
