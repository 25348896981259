export const surfaces = [
  320 * 240,
  640 * 480,
  960 * 1280,
  1024 * 1420,
  1536 * 2048
]

// later ook compressie erbij: hidpi: hogere compressie
const optimalSurface = (canvas, surfaces, pixelRatio) => {
  const canvasSurface = canvas.width * pixelRatio * (canvas.height * pixelRatio)
  for (let i = 0; i < surfaces.length; i++) {
    // the first surface that's bigger, times a ratio, give me that one
    if (surfaces[i] > canvasSurface * 1.1) {
      return surfaces[i]
    }
  }
  // last resort
  return surfaces[surfaces.length - 1]
  // return dimensions: width and height
}

const getDevicePixelRatio = () => {
  if (window.devicePixelRatio) {
    return window.devicePixelRatio
  } else {
    return 1
  }
}

export const getPixelRatio = () => {
  var pixelRatio = getDevicePixelRatio()
  var screenWidth = window.screen.width
  var screenHeight = window.screen.height
  if (window.matchMedia) {
    if (
      window.matchMedia("(orientation: landscape)").matches &&
      screenWidth < screenHeight
    ) {
      screenHeight = [screenWidth, (screenWidth = screenHeight)][0]
    }
  }
  if (screenWidth < window.innerWidth) {
    pixelRatio = pixelRatio * (screenWidth / window.innerWidth)
  }
  // it's fine to load a 1024 image in a canvas of 520 pixels on a retina screen you see:
  // on a hidpi screen a slightly smaller image is acceptable: (losse functie van maken, rite!)
  pixelRatio = 1 + (pixelRatio - 1) * 0.75
  return pixelRatio
}

export const fit = (image, canvas) => {
  const canvas_ratio = canvas.width / canvas.height
  const image_ratio = image.width / image.height
  if (canvas_ratio > image_ratio) {
    var resulting_width = Math.round(canvas.height * image_ratio)
    return { width: resulting_width, height: canvas.height }
  } else {
    var resulting_height = Math.round(canvas.width / image_ratio)
    return { width: canvas.width, height: resulting_height }
  }
}

export const letterboxOrCrop = (canvas, image, crop) => {
  var canvas_ratio = canvas.width / canvas.height
  var image_ratio = image.width / image.height
  if (
    (canvas_ratio > image_ratio && !crop) ||
    (canvas_ratio < image_ratio && crop === true)
  ) {
    // height is leading: canvas height
    var resulting_width = Math.round(canvas.height * image_ratio)
    return { width: resulting_width, height: canvas.height }
  } else {
    // width is leading: canvas width
    var resulting_height = Math.round(canvas.width / image_ratio)
    return { width: canvas.width, height: resulting_height }
  }
}

const imageSize = canvas => {
  const pixelRatio = getDevicePixelRatio()
  const surface = optimalSurface(canvas, surfaces, pixelRatio)

  const imageRatio = canvas.width / canvas.height
  let width = imageRatio * Math.sqrt(surface / imageRatio)
  const height = Math.round(width / imageRatio)
  width = Math.round(width)
  return {
    width,
    height
  }
}

export default imageSize
