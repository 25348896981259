import React, { useState, useEffect } from "react"
import { View } from "./"
import random from "random"

export default ({ children, disabled }) => {
  const [showSecond, set] = useState(true)
  useEffect(() => {
    // const to = setTimeout(() => set(true), random.int(50, 100))
    // return () => clearTimeout(to)
  }, [])
  return (
    <>
      {children[0]}
      {showSecond && !disabled && (
        <View
          style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
        >
          {children[1]}
        </View>
      )}
    </>
  )
}
