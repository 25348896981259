import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
  Portal,
} from "../../components"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import { Container, DashboardContentHeader, ProjectItem } from "./ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import { useProductQuery } from "../product/product-mutations"

const ProductDetailsEditPage = () => {
  let { productId = "" } = useParams()
  const { data } = useProductQuery(productId)
  return (
    <>
      <Portal portal="dashboard-side">
        <Text textStyle="title">{data?.product?.project?.name}</Text>
      </Portal>
      <View p={3}>
        <Stack gap={2}>
          <Text>Project: {data?.product?.project?.name}</Text>

          <Text>Edit product details</Text>
          <Text>Product Name: {data?.product?.name}</Text>
        </Stack>
      </View>
    </>
  )
}

export default ProductDetailsEditPage
