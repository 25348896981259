import React from "react"

export default ({ style }) => (
  <svg viewBox="0 0 276 57" preserveAspectRatio="xMidYMid meet" style={style}>
    <g>
      <g id="svg_1">
        <path
          id="svg_2"
          d="m22.49,0l10,0l22.47,55.58l-11.2,0l-5.56,-14.53l-21.44,0l-5.56,14.53l-11.2,0l22.49,-55.58zm-2.15,31.69l14.29,0l-5.08,-13.34c0,0 -1.03,-2.7 -2.06,-6.03c-1.03,3.33 -2.06,6.03 -2.06,6.03l-5.09,13.34z"
        />
        <path
          id="svg_3"
          d="m65.1,38.28c0.63,6.03 4.92,9.85 10.8,9.85c5.48,0 8.66,-2.78 10.88,-7.23l7.46,4.29c-3.73,7.7 -9.61,11.51 -18.5,11.51c-10.96,0 -20.25,-8.34 -20.25,-20.8c0,-12.47 9.29,-20.8 20.72,-20.8c11.27,0 19.21,8.18 19.21,19.69l0,3.49l-30.32,0zm0,-7.31l21.12,0c-0.95,-5.08 -4.69,-8.26 -10.32,-8.26c-5.32,0 -9.37,3.66 -10.8,8.26z"
        />
        <path
          id="svg_4"
          d="m138.71,45.34c-3.73,7.46 -10.16,11.35 -18.42,11.35c-11.67,0 -20.72,-8.26 -20.72,-20.8c0,-12.55 9.53,-20.8 20.72,-20.8c8.73,0 15.25,3.97 18.02,10.72l-8.18,5.4c-2.3,-5 -5.8,-6.75 -9.92,-6.75c-5.88,0 -10.48,4.45 -10.48,11.43c0,7.07 4.45,11.43 10.48,11.43c5.24,0 8.18,-2.62 10.4,-7.23l8.1,5.25z"
        />
        <path
          id="svg_5"
          d="m141.94,16.2l6.11,0l0,-10.64l10,0l0,10.64l8.58,0l0,8.1l-8.58,0l0,17.94c0,4.05 1.99,5.56 5,5.56c1.51,0 2.54,-0.24 3.33,-0.48l0.95,8.26c-1.75,0.64 -4.37,1.11 -6.67,1.11c-6.83,0 -12.62,-2.86 -12.62,-12.55l0,-19.85l-6.11,0l0,-8.09l0.01,0z"
        />
        <path
          id="svg_6"
          d="m173.72,16.2l10,0l0,22.15c0,5.72 2.46,8.97 8.02,8.97c5.56,0 9.13,-4.53 9.13,-10.8l0,-20.33l10,0l0,39.38l-10,0l0,-6.19c-2.54,4.68 -6.99,7.3 -12.55,7.3c-8.26,0 -14.61,-5.48 -14.61,-16.04l0,-24.44l0.01,0z"
        />
        <path
          id="svg_7"
          d="m257.51,55.59l-10,0l0,-5.08c-2.3,3.81 -6.11,6.19 -12.15,6.19c-10.72,0 -19.14,-7.7 -19.14,-20.8c0,-13.1 9.45,-20.8 18.82,-20.8c6.19,0 10,2.06 12.47,5.56l0,-4.45l10,0l0,39.38zm-9.37,-19.7c0,-6.67 -4.53,-11.43 -10.88,-11.43c-6.35,0 -10.88,4.76 -10.88,11.43c0,6.67 4.53,11.43 10.88,11.43c6.35,0.01 10.88,-4.76 10.88,-11.43z"
        />
        <path id="svg_8" d="m265.93,0l10,0l0,55.58l-10,0l0,-55.58z" />
      </g>
    </g>
  </svg>
)
