import imageUrlBuilder from "@sanity/image-url"

export const client = {
  clientConfig: {
    apiUrl: "https://dkhim1sv.api.sanity.io/v1/",
    projectId: "dkhim1sv",
    dataset: "production"
  }
}

const builder = imageUrlBuilder(client)

const urlFor = (source, dimensions) => {
  return builder
    .image(source)
    .size(dimensions.width, dimensions.height)
    .url()
}

export default urlFor
