import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { Stack, View, Text, Input, Button } from "../components"
import { useForm } from "react-hook-form"
import { AuthContext, useAuth } from "./AuthProvider"
import client from "./authClient"
import { useRegisterMutation } from "./authMutations"
import friendlyUrl from "friendly-url"
import { useToggle } from "react-hooks-lib"

const Container = styled(View).attrs({ px: [1, 2, 3], pb: 5, center: true })`
  max-width: 26em;
  padding-top: ${({ theme }) => theme.headerHeight};
`

const Alert = styled(Text).attrs({
  color: "red",
})``

export default ({
  title = "Signup",
  toggleLogin,
  callback,
  page = false,
}: {
  title?: string
  toggleLogin?: Function
  callback?: Function
  page?: boolean
}) => {
  const { login } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    setValue,
  } = useForm()
  const [signupMutation] = useRegisterMutation({ client })
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { on: continueEnabled, toggle } = useToggle(false)

  const { refetch } = useAuth()

  useEffect(() => {
    setFailed(false)
    const username = getValues().username
    if (username?.charAt(username.length - 1) !== "-") {
      setValue("username", friendlyUrl(username))
    }
  }, [watch("username")])

  const onSubmit = (data) => {
    // login(data)

    const number = Math.random()
    const id = number.toString(36).substr(2, 9)

    const variables = {
      username: id,
      email: data.email,
      name: data.name,
      company: data.company,
    }
    signupMutation({ variables })
      .then((data) => {
        setSuccess(true)
        setTimeout(toggle, 7000)
      })
      .catch((error) => {
        setErrorMessage(error.graphQLErrors.map((x) => x.message)[0])
        setFailed(true)
      })
    // navigate("/you")
  }

  const executeCallback = () => {
    // @ts-ignore
    refetch().then(({ data }) => {
      console.log({ data })
      // @ts-ignore
      callback()
    })
  }

  return (
    <Container>
      {success ? (
        <Stack gap={3} mt={page ? 8 : 6} mb={2}>
          <Text textStyle="title">
            Great! You've successfully signed to our early testing program.
            We’ll be in touch soon!
          </Text>
          <Text>
            You should expect to receive an email introducing you to our new
            online dashboard services within 24 hours. This new feature of our
            platform runs in parallel with our existing architectural design and
            production services. We’re excited to connect with you soon and are
            here to help you create custom architecture in a sustainable way.
          </Text>
          <Text>
            If you're in need of more immediate assistance, please reach out to{" "}
            <a href="mailto:community@aectual.com">community@aectual.com</a> and
            we'll get back to you right away!
          </Text>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <Stack gap={3} mt={page ? 8 : 4}>
            <Text textStyle="title" mb={5}>
              Join Aectual by signing up for your free account and access to our
              early testing program.
            </Text>
            <Text>
              As a member of the Aectual Beta Program, you’ll join our community
              of designers and clients in shaping our digital design to
              production platform. Test-drive our online tools and services and
              let us know what you think!
            </Text>
            <Text>Enter your details below to sign up.</Text>
            <View>
              <View>
                <Input
                  name="name"
                  ref={register({ required: true, minLength: 2 })}
                  py={2}
                  bg={page ? "background" : "tertiaryBackground"}
                  placeholder="Name"
                />
                {errors.name && <Alert>Name is a required field</Alert>}
              </View>

              <View>
                <Input
                  name="company"
                  py={2}
                  ref={register({ required: false })}
                  bg={page ? "background" : "tertiaryBackground"}
                  placeholder="Company"
                />
                {errors.company && (
                  <Alert>Company is not a required field</Alert>
                )}
              </View>

              <View>
                <Input
                  name="email"
                  type="email"
                  py={2}
                  ref={register({ required: true, minLength: 6 })}
                  bg={page ? "background" : "tertiaryBackground"}
                  placeholder="Email"
                />
                {errors.password && <Alert>Email is a required field</Alert>}
              </View>
            </View>

            {failed && (
              <Text>
                {errorMessage || "Something went wrong, please try again."}
              </Text>
            )}

            <Button my={2} mr={3} as="button" type="submit">
              Signup
            </Button>
            {toggleLogin && (
              <Text onClick={toggleLogin} hover pointer>
                Login, I already have an account.
              </Text>
            )}
          </Stack>
        </form>
      )}
    </Container>
  )
}
