import React, { useContext } from "react"
import styled from "styled-components"
import { color } from "styled-system"

interface Props {
  onClick?: (e: Event) => void
}

const Container = styled.button<Props>`
  transform: scale(0.85);
  transform-origin: center center;

  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  ${color} text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }

  transition: color 200ms ease;
  z-index: 999;
`

const Box = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`

const innerCSS = `
  width: 40px;
  height: 1px;
  background-color: currentColor;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`

const Inner = styled.span<{ active?: boolean }>`
  display: block;
  top: 50%;
  margin-top: -2px;
  ${innerCSS} &::before, &::after {
    content: "";
    display: block;
    ${innerCSS};
  }

  &::before {
    top: -10px;
    transition: top 0.1s 0.14s ease, opacity 0.1s ease;
  }

  &::after {
    bottom: -10px;
    transition: bottom 0.1s 0.14s ease,
      transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${({ active }) =>
    active
      ? `
    transform: rotate(45deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease, opacity 0.1s 0.14s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  `
      : ""};
`

export default ({
  color,
  style,
  active,
  toggle,
}: {
  color: string
  style?: any
  active: boolean
  toggle: any
}) => {
  return (
    <Container onClick={toggle} color={color} style={style}>
      <Box>{active ? <Inner active /> : <Inner />}</Box>
    </Container>
  )
}
