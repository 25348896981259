import React, { useRef } from "react"
import { extend, useThree, useFrame } from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

extend({ OrbitControls })

function Controls() {
  const controlsRef = useRef()
  const { camera, gl } = useThree()

  // @ts-ignore
  useFrame(() => controlsRef?.current?.update())

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, gl.domElement]}
      enableRotate={false}
      enablePan={true}
      maxDistance={1000}
      minDistance={500}
      minPolarAngle={Math.PI / 6}
      maxPolarAngle={Math.PI / 2}
    />
  )
}

export default Controls
