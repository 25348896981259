import React, { Fragment } from "react"
import styled from "styled-components"
import {
  View,
  Stack,
  Text,
  Headline,
  Divider,
  RatioContainer,
} from "../components"
import Vimeo from "../components/Vimeo"

interface HowPageProps {
  children?: any
}

const Container = styled(View)`
  display: flex;
`

const OverviewContainer = styled(View)`
  flex: 1;
`

const NavigatorContainer = styled(View)`
  width: 320px;
`

const HowPage = ({}: HowPageProps): JSX.Element => (
  <View bg="secondaryBackground" px={0} py={0}>
    <RatioContainer ratio={16 / 9}>
      <Vimeo
        videoLink="https://vimeo.com/345444699"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </RatioContainer>
  </View>
)

export default HowPage
