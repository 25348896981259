import React from "react"
import { useParams } from "react-router-dom"
import { View, Text, Link, Icon } from "../components"
import { useModelQuery } from "./modelQueriesAndMutations"
import { useCreateSnapshot } from "./modelQueriesAndMutations"

import Viewer from "../shapediver/Viewer"

export default () => {
  const { id = "db2b6038-ec24-4d8d-a85a-a20d15daaf14" } = useParams()
  const { data, refetch } = useModelQuery({ variables: { id } })

  const [createSnapshot] = useCreateSnapshot({})

  return (
    <View mt={2} px={[2, 3, 3]}>
      <Link to="/dashboard" back textStyle="title" mb={2} block>
        <Icon name="arrow-left" mr={2} />
        {data?.model?.title}
      </Link>

      {data?.model?.ticket && (
        <Viewer
          standalone
          propTicket={data.model.ticket}
          snapshots={data.model.snapshots}
          createSnapshot={(parameters, description) =>
            createSnapshot({
              variables: {
                modelId: data?.model?.id,
                parameters: JSON.stringify(parameters),
                description,
              },
            }).then(refetch)
          }
          modelId={id || null}
        />
      )}
    </View>
  )
}
