import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Text } from "./"
import historyLength from "../utilities/historyLength"
import { TextProps } from "./Text"

interface Props extends TextProps {
  fontSize?: number
  onClick?: any
  noScroll?: boolean
  back?: boolean
  children?: React.ReactNode | string
  to?: string
  href?: string
  as?: React.ReactNode | string
  key?: any
  ref?: any
  match?: string
  replace?: boolean
  fontWeight?: string
  pathname?: string
  view?: boolean
}

const StyledLink = styled(Text).attrs(
  ({
    as = ({
      center,
      ellipsis,
      textStyle,
      secondary,
      alternate,
      noScroll,
      borderRadius,
      active,
      children,
      to,
      ...rest
    }) => (
      <Link to={to} {...rest}>
        {children}
        {/*JSON.stringify(rest)*/}
      </Link>
    ),
    back,
    noScroll,
  }: {
    as?: React.ReactNode | string
    back?: boolean
    noScroll?: boolean
  }) => ({
    as,
    onClick: noScroll
      ? () => {
          window.sessionStorage.setItem("noScroll", "active")
          setTimeout(() => window.sessionStorage.removeItem("noScroll"), 200)
        }
      : back
      ? (e) => {
          if (historyLength() > 0) {
            console.log("history back")
            e.preventDefault()
            window.history.back()
          }
        }
      : null,
  })
)<Props>`
  text-decoration: none;
  opacity: 1;
  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
  transition: opacity 200ms ease;
  ${(props) =>
    props.view &&
    `
    background: red;
    $::before {
      margin-top: 0;
    };
    $::after {
      margin-bottom: 0;
    };
  `}
`

export default StyledLink
