import React, { useEffect } from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  Portal,
  Inline,
  Icon,
} from "../../components"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useLazyQuery, useMutation } from "react-apollo"
import { DashboardContentHeader, ProjectItem } from "../projects/ProjectsPage"
import { useCreateFile, useCreateVersion } from "../product/product-mutations"
import ModelPage from "../../models/ModelPage"
import Viewer from "../../shapediver/Viewer"
import ParameterSections from "./ParameterSections"
import sanityClient from "../../sanity/sanityClient"
import { IMAGE } from "../../sanity/sanityQueries"
import { useUser } from "../../user/AuthProvider"

const MODELS_QUERY = gql`
  query clientProduct($id: Int!) {
    product: clientProduct(id: $id) {
      id
      name
      project {
        id
        name
      }
      category {
        id
      }
      models {
        id
        title
        ticket
        backendTicket
        parameterSectionsKey
        parameters
        selectedPreset
      }
    }
  }
`

const UPDATE_PARAMETERS = gql`
  mutation updateParameters($id: Int!, $parameters: String!) {
    updateModel(input: { id: $id, patch: { parameters: $parameters } }) {
      model {
        id
        parameters
      }
    }
  }
`

const SAVE_SELECTED_PRESET = gql`
  mutation updateParameters($id: Int!, $selectedPreset: String!) {
    updateModel(
      input: { id: $id, patch: { selectedPreset: $selectedPreset } }
    ) {
      model {
        id
        selectedPreset
      }
    }
  }
`

export const PRESETS_QUERY = gql`
  query Variants {
    allDesignerVariations: allDesignerVariationDocument {
      id: _id
      key: title
      ticket
      secret
      presets {
        id: _key
        title
        parameters
        image {
          ${IMAGE}
        }
      }
      parameterSections {
        id: _id
        title
        category {
          id: _id
          name
          slug {
            current
          }
          odooProductCategoryId
        }
        sections {
          id: _key
          label
          note
          key
          valueImages {
            value
            image {
              ${IMAGE}
            }
          }
        }
      }
      requestUpload
    }
  }
`

const PARAMETER_SECTIONS_QUERY = gql`
  query SECTIONS($parameterSectionsKey: String) {
    allParameterSections(where:{title:{eq:$parameterSectionsKey}}) {
      id: _id
      title
      category {
        id: _id
        name
        slug {
          current
        }
        odooProductCategoryId
      }
      sections {
        id: _key
        label
        note
        key
        valueImages {
          value
          image {
            ${IMAGE}
          }
        }
      }
    }
  }
`

const Container = styled(View)`
  position: relative;
  height: calc(100vh - 3rem);
`

export default () => {
  const user = useUser()
  const { productId, modelId } = useParams()
  const navigate = useNavigate()

  const { data } = useQuery(MODELS_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
  })

  const model =
    data?.product?.models.find((model) => model.id === parseInt(modelId, 10)) ??
    null

  // PRESETS
  const { data: presetsData } = useQuery(PRESETS_QUERY, {
    client: sanityClient,
  })

  // PARAMETER SECTIONS
  const [querySections, { data: parameterSectionsData }] = useLazyQuery(
    PARAMETER_SECTIONS_QUERY,
    {
      client: sanityClient,
    }
  )

  useEffect(() => {
    if (!!model?.parameterSectionsKey) {
      querySections({
        variables: { parameterSectionsKey: model.parameterSectionsKey },
      })
    }
  }, [model])

  console.log({ parameterSectionsData })

  const variant = presetsData?.allDesignerVariations.find(
    (variant) =>
      variant.ticket === model?.ticket || variant.key === model?.ticket
  )
  console.log({ variant })

  const presets = variant?.presets
  console.log({ presets })

  const sections =
    parameterSectionsData?.allParameterSections?.[0]?.sections ||
    variant?.parameterSections?.sections
  console.log({ sections })
  console.log(
    "fdsfds",
    parameterSectionsData?.allParameterSections?.[0]?.sections
  )
  //

  const [createFile] = useCreateFile()
  const [createVersion] = useCreateVersion()
  const [updateParametersMutation] = useMutation(UPDATE_PARAMETERS, { client })
  const [saveSelectedPresetMutation] = useMutation(SAVE_SELECTED_PRESET, {
    client,
  })

  async function callback(files) {
    try {
      // save those two files in odoo
      files.forEach(async (file, index) => {
        if (file?.url) {
          const response = await createFile({
            variables: {
              name: index === 0 ? "OBJ" : "AEC",
              productId: parseInt(productId, 10),
            },
          })
          if (response) {
            await createVersion({
              variables: {
                fileId: response.data.createFile.file.id,
                url: file.url,
                generator: true,
              },
            })
          }
        }
      })
    } catch (error) {
      window.alert("export failed")
    }
    setTimeout(() => navigate("../../", { replace: true }), 200)
  }
  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />

            <Inline
              as={Link}
              to="../../../../../../"
              replace
              verticalAlign="center"
            >
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.project?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider mb={0} />
          </Stack>
        )}
      </Portal>
      <Container>
        {model && presetsData && (
          <Viewer
            propTicket={
              model.ticket.length > 50 ? model.ticket : variant.ticket
            }
            modelId={model.ticket.length > 50 ? model.ticket : variant.ticket}
            propParameters={model.parameters && JSON.parse(model.parameters)}
            snapshots={undefined}
            saveParameters={(parameters) =>
              updateParametersMutation({
                variables: {
                  id: model.id,
                  parameters: JSON.stringify(parameters),
                },
              })
            }
            saveSelectedPreset={(selectedPreset) =>
              saveSelectedPresetMutation({
                variables: {
                  id: model.id,
                  selectedPreset,
                },
              })
            }
            selectedPreset={model.selectedPreset}
            secret={variant?.secret || model.secret}
            portal="editor"
            processCallback={callback}
            parameterSections={sections}
            presets={presets}
            full
          />
        )}
      </Container>
    </>
  )
}
