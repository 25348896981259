import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  Inline,
  View,
  Text,
  Button,
  Link,
  Divider,
  Grid,
  RatioContainer,
  Portal,
  Icon,
} from "../../components"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import { useUser } from "../../user/AuthProvider"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import EditValuePopover from "../../components/EditValuePopover"
import EditValueInline from "../../components/EditValueInline"
import {
  UPDATE_PROJECT_NAME,
  UPDATE_PROJECT_LOCATION,
  UPDATE_PROJECT_CLIENT,
} from "../projects/project-mutations"
import DeleteProject from "../projects/Deleteproject"
import {
  useProductCategories,
  useProductTypeDetails,
} from "../product/product-mutations"

const PROJECT_QUERY = gql`
  query project($id: Int!) {
    project(id: $id) {
      id
      name
      location
      client
      products {
        id
        name
        image {
          url
          thumb
        }
        category {
          id
        }
      }
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation newProduct($name: String!, $projectId: Int!, $categoryId: Int!) {
    createProduct(
      input: { name: $name, projectId: $projectId, categoryId: $categoryId }
    ) {
      product {
        id
        name
        image {
          url
          thumb
        }
        category {
          id
          name
        }
      }
    }
  }
`

export const useCreateProduct = () => useMutation(CREATE_PRODUCT, { client })

export const useProjectQuery = (projectId) =>
  useQuery(PROJECT_QUERY, {
    variables: { id: parseInt(projectId, 10) },
    client,
    fetchPolicy: "network-only",
  })

export const SpecItem = ({
  label,
  value,
  children,
  updateMutation,
  thing,
  id,
  element,
  textStyle,
  ...rest
}: {
  label: string
  value?: string
  children?: any
  updateMutation?: any
  thing?: string
  id?: number
  element?: any
  textStyle?: string
}) => (
  <Inline {...rest}>
    <View mr={3} style={{ width: 200 }}>
      <Text textStyle={textStyle || "body"}>{label}</Text>
      <Divider mt={1} mb={0} />
    </View>
    {children || (
      <View flex>
        <Inline style={{ minHeight: "1.6rem" }} bg="black">
          {!updateMutation && (
            <Text color="white" flex px={1} py={1}>
              {(typeof value === "number" || typeof value === "string") &&
                value}
            </Text>
          )}
          {!!updateMutation && (
            <EditValueInline
              updateMutation={updateMutation}
              thing={thing}
              id={id}
              value={value}
            />
          )}
          {element}
        </Inline>
        <Divider mt="-1px" mb={0} />
      </View>
    )}
  </Inline>
)

const getParentCategoryString = (parent) => (parent ? `${parent.name} / ` : "")

export default () => {
  const user = useUser()

  let { projectId = "" } = useParams()
  const { data, refetch } = useProjectQuery(projectId)
  const loadAgain = () =>
    refetch({
      id: parseInt(projectId, 10),
    })

  const { data: categoriesData } = useProductCategories()

  const [newProduct] = useMutation(CREATE_PRODUCT, { client })

  const products = data?.project?.products ?? []

  return (
    <>
      <Portal portal="dashboard-side">
        <Stack gap={2} px={[2, 3, 3]} mt={3}>
          <Divider />
          <Inline as={Link} to="../../" replace verticalAlign="center">
            {user && (
              <Text flex textStyle="body">
                {user.company && user.company.length > 1
                  ? user.company
                  : user.name}
              </Text>
            )}
            <Icon name="arrow-left" size={18} />
          </Inline>
          <Divider />
          <Text textStyle="title" mt={8} mb={3}>
            {data ? data.project.name : "_"}
          </Text>

          <Text textStyle="footnote" mt={3}>
            Organize your project here. Add one or more products and go to the
            design process of one of your products.
            <br />
            <br />
            All design steps will be automatically be saved for you so you can
            come back whenever you like and continue the process or make changes
            based on developments in the project.
          </Text>
        </Stack>
      </Portal>
      <Container>
        <View style={{ flex: 1 }}>
          <Grid gridGap={[3, 4, 5]} minColumnWidth={[180, 240, 260]}>
            {products.map((product) => (
              <ProjectItem
                key={product.id}
                to={"product/" + product.id}
                project={product}
                image={
                  categoriesData?.allProductCategory.find(
                    (category) =>
                      category.odooProductCategoryId === product.category.id
                  )?.image?.image
                }
                isProduct
                category={`
                ${getParentCategoryString(
                  categoriesData?.allProductCategory.find(
                    (category) =>
                      category.odooProductCategoryId === product.category.id
                  )?.parent
                )}
                  ${categoriesData?.allProductCategory.find(
                    (category) =>
                      category.odooProductCategoryId === product.category.id
                  )?.name || ""}`}
              />
            ))}
            <ProjectItem
              to={"product/new"}
              project={{ name: "Add Product" }}
              newProject
            />
            <div />
            <div />
          </Grid>
        </View>
        <DeleteProject id={parseInt(projectId, 10)} />
        <Stack gap={2}>
          <SpecItem
            label="Project name"
            value={data?.project?.name}
            updateMutation={UPDATE_PROJECT_NAME}
            thing="name"
            id={parseInt(projectId, 10)}
          />
          <SpecItem
            label="Client"
            value={data?.project?.client}
            updateMutation={UPDATE_PROJECT_CLIENT}
            thing="client"
            id={parseInt(projectId, 10)}
          />
          <SpecItem
            label="Location"
            value={data?.project?.location}
            updateMutation={UPDATE_PROJECT_LOCATION}
            thing="location"
            id={parseInt(projectId, 10)}
          />
        </Stack>
      </Container>
    </>
  )
}
