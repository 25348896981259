import React from "react"
import styled from "styled-components"
import { View, Text } from "./"

const Container = styled(View)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  transition: opacity 250ms ease;
`

/*
export default ({ onDrop }: { onDrop: any }) => (
  <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles[0])}>
    {({ getRootProps, getInputProps }) => (
      <Container {...getRootProps()} p={2}>
        <input {...getInputProps()} />
        <Text textStyle="footnote">Select file (jpg/png)</Text>
      </Container>
    )}
  </Dropzone>
)
*/

export default React.forwardRef(
  ({ children, ...rest }: { children: any }, ref) => (
    <Container p={2} {...rest} ref={ref}>
      {children}
    </Container>
  )
)
