export default (props: any): string => {
  const {
    theme: { textStyles },
    textStyle = "body",
    fontWeight,
  } = props
  const styleSpecs = textStyles[textStyle]
  return `
    font-family: ${styleSpecs.fontFamily};
    font-size: ${styleSpecs.fontSize};
      font-weight: ${
        fontWeight
          ? styleSpecs[fontWeight]
          : "fontWeight" in styleSpecs
          ? styleSpecs[styleSpecs.fontWeight]
          : styleSpecs["normal"]
      };
      line-height: ${styleSpecs.lineHeight};
      letter-spacing: ${styleSpecs.letterSpacing}em;
      ${styleSpecs.baselineCorrection &&
        `
        &::after {	
          content: "";	
          margin-bottom: -${styleSpecs.baselineCorrection}em;	
          display: block;	
          height: 0;	
        }
      `}
      ${styleSpecs.heightCorrection &&
        `
      &::before {	
        content: "";	
        margin-top: -${styleSpecs.heightCorrection}em;	
        display: block;	
        height: 0;	
      }
      `}
  `
}
