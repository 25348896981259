import * as React from "react"
import styled from "styled-components"
import { View, Text } from "./"

const Container = styled(View).attrs(() => ({
  bg: "#E7FD6D",
  borderRadius: 3,
  px: 2,
  py: 1,
}))``

const Badge = ({ children, style }) => (
  <Container style={style}>
    <Text textStyle="footnote">{children}</Text>
  </Container>
)

export default Badge
