import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"

import client from "./modelsClient"

const MODELS = gql`
  query MODELS {
    models(orderBy: CREATED_AT_DESC) {
      id
      createdAt
      title
      description
      ticket
      backendTicket
    }
  }
`

export const useModelsQuery = (options) =>
  useQuery(MODELS, { ...options, client })

const CREATE_MODEL = gql`
  mutation createModel(
    $title: String
    $description: String
    $ticket: String!
    $backendTicket: String
  ) {
    createModel(
      input: {
        model: {
          title: $title
          description: $description
          ticket: $ticket
          backendTicket: $backendTicket
        }
      }
    ) {
      model {
        id
        createdAt
        title
        description
        ticket
      }
    }
  }
`

export const useCreateModel = (options) =>
  useMutation(CREATE_MODEL, { ...options, client })

const MODEL = gql`
  query MODEL($id: UUID!) {
    model(id: $id) {
      id
      createdAt
      title
      description
      ticket
      snapshots(orderBy: CREATED_AT_DESC) {
        id
        description
        createdAt
        parameters
      }
    }
  }
`

export const useModelQuery = (options) =>
  useQuery(MODEL, { ...options, client })

const CREATE_SNAPSHOT = gql`
  mutation createSnapshot(
    $modelId: UUID!
    $parameters: JSON
    $description: String
  ) {
    createSnapshot(
      input: {
        snapshot: {
          modelId: $modelId
          parameters: $parameters
          description: $description
        }
      }
    ) {
      snapshot {
        id
        description
        parameters
        createdAt
      }
    }
  }
`

export const useCreateSnapshot = (options) =>
  useMutation(CREATE_SNAPSHOT, { ...options, client })

const PROCESS_MODEL = gql`
  mutation processModel($id: UUID, $secret: String, $parameters: JSON) {
    processModel(input: { id: $id, secret: $secret, parameters: $parameters }) {
      files {
        id
        url
        msg
      }
    }
  }
`

export const useProcessModel = (options) =>
  useMutation(PROCESS_MODEL, { ...options, client })
