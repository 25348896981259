import React from "react"
import { View, RatioContainer } from "../components"

export default ({}) => (
  <RatioContainer ratio={16 / 9}>
    <iframe
      frameBorder="0"
      src="https://staircase-mvp.surge.sh/"
      width="100%"
      height="100%"
    />
  </RatioContainer>
)
