import styled, { css } from "styled-components"
import { color, space } from "styled-system"
import textStyles from "../utilities/textStyles"

import { TextProps } from "./Text"

interface InputProps extends TextProps {
  type?: string
  ref?: any
  autoFocus?: boolean
  placeholder?: string
}

export const Transition = {
  hover: {
    on: "all 0.12s ease-in",
    off: "all 0.13s ease-out",
  },
  reaction: {
    on: "all 0.15s ease-in",
    off: "all 0.1s ease-out",
  },
  dropdown: {
    off: "all 0.35s ease-out",
  },
}

const Input = styled.input.attrs(
  ({ color = "text", bg = "background" }: { color?: string; bg?: string }) => ({
    color,
    bg,
  })
)<InputProps>`
  box-sizing: border-box;
  flex: 1 0 auto;
  background: none;
  ${(props) => textStyles(props)};
  width: 100%;
  appearance: none;
  outline: 1px solid ${(props) => props.theme.colors[props.bg]};
  outline-offset: -1px;
  border: none;
  border-bottom: 2px solid
    ${(props) =>
      props.disabled ? props.theme.colors.border : props.theme.colors.border};
  padding: 8px 1px;
  margin-top: 2px;
  box-shadow: none;

  ${(props) =>
    props.type === "checkbox" &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `}

  &::placeholder {
    color: ${({ theme }) => "#666"};
  }

  &:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
      inset 0 0 0 100px ${(props) => props.theme.colors[props.bg]};
  }
  &:focus {
    outline-offset: 0;
  }

  &[type="file"] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }

  ${color};
  ${space};
`

export default Input
