import { useMedia } from "use-media"
import useTheme from "./useTheme"

const useIsWide = () => {
  const theme = useTheme()
  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })
  return isWide
}

export default useIsWide
