// @ts-ignore
import React from "react"
import styled from "styled-components"
import { View } from "./"
import textStyles from "../utilities/textStyles"

import {
  background,
  BackgroundProps,
  bottom,
  BottomProps,
  color as styledColor,
  ColorProps,
  display,
  DisplayProps,
  height,
  HeightProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  right,
  RightProps,
  space as styledSpace,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
  fontSize,
  fontWeight,
} from "styled-system"

interface CustomTextProps extends SpaceProps {
  textStyle?: string
  fontWeight?: string
  center?: boolean
  inline?: boolean
  block?: boolean
  pointer?: boolean
  ellipsis?: boolean
  theme?: any
  hover?: boolean
}

/*
    BackgroundProps,
    BottomProps,
    ColorProps,
    DisplayProps,
    HeightProps,
    LeftProps,
    MaxWidthProps,
    MinHeightProps,
    PositionProps,
    RightProps,
    SpaceProps,
    TextAlignProps,
    TopProps,
    WidthProps,
    ZIndexProps

*/

export interface TextProps extends CustomTextProps {
  fontSize?: number
  onClick?: any
  flex?: boolean
}

/**
 * Text is a `div` with common styled-systems
 */
/*

  ${background};
  ${bottom};
  ${display};
  ${height};
  ${left};
  ${maxWidth};
  ${minHeight};
  ${position};
  ${right};
  ${styledColor};
  ${styledSpace};
  ${textAlign};
  ${top};
  ${width};
  ${zIndex};

  */
// → &nbsp;
const Text = styled(View).attrs(({ color = "text" }) => ({ color }))<TextProps>`
  ${(props) => textStyles(props)};
  ${fontSize};
  ${fontWeight};
  ${(props) => (props.center ? `text-align: center` : "")};
  ${(props) => (props.inline ? `display: inline` : "")};
  ${(props) => (props.block ? `display: block` : "")};
  ${(props) => (props.pointer ? `cursor: pointer` : "")};
  ${(props) =>
    props.hover
      ? `
  :hover {
    opacity: 0.7;
  }`
      : ""};

  & a {
    text-decoration: none;
    color: ${({ theme, color }) => theme.colors[color || "primary"]};
    transition: opacity 200ms ease;
  }
  & a:hover {
    color: ${({ theme, color }) => theme.colors[color || "primary"]};
    opacity: 0.7;
  }
  ${({ ellipsis }) =>
    ellipsis &&
    `
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  transition: opacity 250ms ease;
`

Text.displayName = "Text"

export default Text
