import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import client from "./authClient"

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      user {
        id
        name
        username
      }
      token
    }
  }
`

const REGISTER = gql`
  mutation registerUser(
    $username: String!
    $email: String!
    $name: String!
    $company: String
  ) {
    register(
      input: {
        username: $username
        name: $name
        email: $email
        company: $company
      }
    ) {
      user {
        id
        name
        username
        company
      }
    }
  }
`

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      company
      name
      username
      email
      role
      earlyAccess
    }
  }
`

const LOGOUT = gql`
  mutation Logout {
    logout {
      success
    }
  }
`

const TOKEN_INFO = gql`
  query tokenInfo($token: String!) {
    resetPasswordTokenInfo(token: $token) {
      user {
        id
        name
      }
    }
  }
`

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(input: { token: $token, password: $password }) {
      user {
        id
        username
        name
        email
      }
      token
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation updateCompany($company: String) {
    updateUser(input: { patch: { company: $company } }) {
      user {
        id
        company
      }
    }
  }
`

export const UPDATE_NAME = gql`
  mutation updateName($name: String) {
    updateUser(input: { patch: { name: $name } }) {
      user {
        id
        name
      }
    }
  }
`

export const useLoginMutation = (options) =>
  useMutation(LOGIN, { client, ...options })
export const useRegisterMutation = (options) =>
  useMutation(REGISTER, { client, ...options })
export const useCurrentUserQuery = (options) =>
  useQuery(CURRENT_USER, { client, ...options })
export const useLogoutMutation = (options) =>
  useMutation(LOGOUT, { client, ...options })
export const useResetPasswordMutation = (options) =>
  useMutation(RESET_PASSWORD, { client, ...options })
export const useTokenInfoQuery = (options) =>
  useQuery(TOKEN_INFO, { client, ...options })
