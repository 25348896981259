import React from "react"
// @ts-ignore
import { Routes, Route } from "react-router-dom"
import ScrollIntoView from "./ScrollIntoView"

const ScrollToOnMatch = ({ path }: { path: string }) => {
  return (
    <Routes>
      <Route path={path} element={<ScrollIntoView />} />
    </Routes>
  )
}

export default ScrollToOnMatch
