// this thing with animage on the left, text on the right or vice versa
// reverse

import React from "react"
import { View } from "./"
import styled from "styled-components"

const getBreakpoint = (number) => ({ theme: { breakpoints } }) =>
  breakpoints[number]

const Container = styled(({ reverse, ...rest }) => <View {...rest} />)`
  position: relative;
  display: flex;
  flex-direction: ${({ reverse }) =>
    reverse === true ? "row-reverse" : "row"};
  justify-content: space-between;
  // margin-left: -${({ theme: { space } }) => space[2]};
  @media (max-width: ${({ breakpoint = 1 }) => getBreakpoint(breakpoint)}) {
    // flex-wrap: wrap;
    flex-direction: column;
    .hide-on-wrap {
      display: none;
    }
  }
  @media (min-width: ${({ breakpoint = 1 }) => getBreakpoint(breakpoint)}) {
    &:last-of-type {
      padding-bottom: 0;
    }
    & > * {
      flex: 1;
    }
    & > *:last-child {
      margin-right: 0;
    }
    & > *:${({ reverse }) =>
      reverse === true ? "first-child" : "last-child"} {
      padding-left: ${({ theme: { space } }) => space[2]};
    }
    & > *:${({ reverse }) =>
      reverse !== true ? "first-child" : "last-child"} {
      padding-right: ${({ theme: { space } }) => space[2]};
    }
  }
`
//    margin-left: ${({ theme: { space } }) => space[2]}px;
// margin-right: ${({ theme: { space } }) => space[2]}px;
//  max-width: ${props => props.theme.maxWidth};

const SideBySide = (props) => {
  const { children, ...rest } = props
  return <Container {...rest}>{children}</Container>
}

export default SideBySide
