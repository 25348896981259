import React from "react"

// https://erp-test.aectual.com/jobs

export default ({}) => (
  <iframe
    frameBorder="0"
    src="https://erp-test.aectual.com/jobs"
    width="100%"
    height="800px"
  />
)
