import React, { createContext, useEffect, useState } from "react"
import client from "./authClient"
import { useCurrentUserQuery } from "./authMutations"

interface UserInterface {
  name?: string | false | null
  username?: string | false | null
  company?: string | null
  email?: string | false | null
  employee?: boolean
  earlyAccess?: boolean
}

interface IContextProps {
  isAuthenticated?: boolean
  setAuthenticated?: any
  isAuthenticating?: boolean
  setAuthenticating?: any
  login: Function
  logout: Function
  name?: string | false | null
  username?: string | false | null
  company?: string | false | null
  email?: string | false | null | undefined
  user?: UserInterface
  refetch?: Function
  // React Children
  children?: React.ReactNode
}

/**
 * Button functionality and context.
 */
const initialContext: IContextProps = { login: () => {}, logout: () => {} }

export const AuthContext: React.Context<IContextProps> = React.createContext(
  initialContext
)

const UserContext: React.Context<UserInterface> = createContext({})

interface AuthInterface {
  user?: UserInterface
  login?: Function
  isAuthenticated?: boolean
  refetch?: Function
  logout: Function
  isAuthenticating?: boolean
}

export const AuthConsumer = AuthContext.Consumer
export const UserConsumer = UserContext.Consumer
export const useAuth = (): AuthInterface => React.useContext(AuthContext)

export const UserProvider = (props) => {
  const { user } = useAuth()
  return <UserContext.Provider value={user} {...props} />
}

export const useUser = () => {
  const context = React.useContext(UserContext)
  return context
}

const AuthProvider = ({ children }) => {
  const { loading, error, data, refetch } = useCurrentUserQuery({
    fetchPolicy: "network-only",
    client,
  })

  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isAuthenticating, setAuthenticating] = useState(
    !!window.localStorage.getItem("authenticated")
  )

  useEffect(() => {
    if (!loading) {
      if (data?.currentUser?.id && isAuthenticated === false) {
        console.log("Yes! Authenticated!")
        setAuthenticating(false)
        setAuthenticated(true)
        window.localStorage.setItem("authenticated", "yes")
      } else if (!data?.currentUser) {
        console.log("No longer authenticated.")
        setAuthenticating(false)
        setAuthenticated(false)
        window.localStorage.setItem("authenticated", "no")
      }
    }
  }, [loading, data])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated && !!data?.currentUser,
        setAuthenticated,
        isAuthenticating,
        setAuthenticating,
        login: (data) => {
          setAuthenticating(false)
          setAuthenticated(true)
        },
        logout: () => {
          window.localStorage.removeItem("accessToken")
          window.localStorage.setItem("authenticated", "no")
          setAuthenticating(false)
          setAuthenticated(false)
        },
        name: isAuthenticated && data?.currentUser?.name,
        username: isAuthenticated && data?.currentUser?.username,
        email: isAuthenticated && data?.currentUser?.email,
        company: isAuthenticated && data?.currentUser?.company,
        user: isAuthenticated && {
          ...data?.currentUser,
          employee:
            data?.currentUser?.role === "EMPLOYEE" ||
            data?.currentUser?.username === "rudin",
          earlyAccess: data?.currentUser?.earlyAccess,
        },
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
