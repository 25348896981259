import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
// @ts-ignore
import { useNavigate, useParams } from "react-router-dom"
import { Stack, View, Text, Input, Button, Link } from "../components"
import { useForm } from "react-hook-form"
import { AuthContext } from "./AuthProvider"
import { useResetPasswordMutation, useTokenInfoQuery } from "./authMutations"
import { useAuth, useUser } from "./AuthProvider"

const Redirect = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [])
  return null
}

const Container = styled(View).attrs({ px: [1, 2, 3], pb: 5, center: true })`
  max-width: 26em;
`

const Alert = styled(Text).attrs({
  color: "red",
})``

export default () => {
  const { token } = useParams()
  const { data: tokenInfo, error: tokenError } = useTokenInfoQuery({
    variables: { token },
  })
  console.log({ token, tokenInfo, tokenError })
  const { login } = useContext(AuthContext)
  const { register, handleSubmit, watch, errors, reset, clearError } = useForm()
  const [resetPassword] = useResetPasswordMutation({})
  const [failed, setFailed] = useState(false)

  const { isAuthenticated, isAuthenticating, refetch } = useAuth()

  useEffect(() => {
    if (failed) setFailed(false)
    clearError("password")
    clearError("confirm")
  }, [watch("password"), watch("confirm")])

  const onSubmit = (data) => {
    const variables = {
      token,
      password: data.password,
    }
    resetPassword({ variables })
      .then((data) => {
        console.log(data)
        window.localStorage.setItem(
          "accessToken",
          data.data.resetPassword.token
        )
        setTimeout(refetch, 220)
      })
      .catch(() => setFailed(true))
  }

  if (tokenError?.message?.includes("is niet geldig"))
    return (
      <Container mt={10}>
        <Text textStyle="action">Activation token no longer valid</Text>
        <Text pt={3}>Please contact support</Text>
      </Container>
    )

  if (isAuthenticating) return <Text>...</Text>

  if (isAuthenticated)
    return (
      <Container>
        <Stack gap={4} mt={10}>
          <Text textStyle="action" mt={4}>
            You've succesfully configured your password. You can now go to your
            dashboard.
          </Text>
          <Link to="/dashboard">Go to my Dashboard</Link>
        </Stack>
      </Container>
    )

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Stack gap={4} mt={6}>
          <Text textStyle="title">
            Welcome {tokenInfo?.resetPasswordTokenInfo?.user?.name}
            <br />
            Activate your account
          </Text>
          <Text mb={3}>
            Choose and confirm your password
            {/* <Text>{isAuthenticated && <Redirect to="/dashboard" />}</Text> */}
          </Text>
          <View>
            <Text textStyle="footnote">Password</Text>
            <Input
              name="password"
              type="password"
              mt={1}
              ref={register({ required: true, minLength: 6 })}
              autoFocus
            />
            {!!errors.password && <Alert>Please enter a pasword.</Alert>}
          </View>

          <View>
            <Text textStyle="footnote">Confirm Password</Text>
            <Input
              name="confirm"
              type="password"
              mt={1}
              ref={register({
                required: true,
                minLength: 6,
                validate: (value) => value === watch("password"),
              })}
            />

            {!!errors.confirm && !errors.password && (
              <Alert>The passwords don't match, please correct</Alert>
            )}
          </View>

          {failed && (
            <Text color="red">Something went wrong, please try again.</Text>
          )}

          <View>
            <Button my={2} mr={3} as="button" type="submit">
              Submit
            </Button>
          </View>
        </Stack>
      </form>
    </Container>
  )
}
