import * as React from "react"
import styled from "styled-components"
import { View, Icon } from "../../components"
import ImageSelect from "../../components/ImageSelect"
import gql from "graphql-tag"
import { useMutation } from "react-apollo"
import client from "../../user/authClient"

const UPDATE_PROJECT_IMAGE = gql`
  mutation updateProject($id: Int!, $image: String!) {
    updateProject(input: { id: $id, patch: { image: $image } }) {
      project {
        id
        image {
          url
          thumb
        }
      }
    }
  }
`

const Container = styled(View)`
  position: absolute;
  left: 0;
  top: 0;
`

const ProjectImageSelect = ({ id }) => {
  const [updateProject] = useMutation(UPDATE_PROJECT_IMAGE, { client })

  return (
    <Container pl={2} pt={3} onClick={(e) => e.preventDefault()}>
      <ImageSelect
        callback={(blob) =>
          updateProject({
            variables: { image: blob.replace(/^data:.+;base64,/, ""), id },
          })
        }
        iconOnly
        size={32}
      />
    </Container>
  )
}

export default ProjectImageSelect
