import React from "react"

export const colors = {
  primary: "#000000",
  primaryHover: "#666666",
  primaryText: "#FFFFFF",
  secondary: "#E5F4F2",
  secondaryHover: "#555555",
  secondaryText: "#888888",
  alternate: "#737373",
  alternateHover: "#A6A6A6",
  alternateText: "rgb(168, 166, 190)",
  accent: "rgb(248, 248, 248)",
  secondaryAccent: "rgb(227, 244, 254)",
  alternateAccent: "rgb(70, 64, 88)",
  text: "#000000",
  textInvert: "#FFFFFF",
  textPlaceholder: "#DDDDDD",
  background: "#efefef",
  secondaryBackground: "#F8F8F8",
  tertiaryBackground: "#DDDDDD",
  alternateBackground: "#E6DCDC",
  backgroundInvert: "#000000",
  overlay: "#61618A",
  border: "#000000",
  disabled: "#D9D9D9",
  shadow: "#000000",
}
// quaternary, quinary, senary, septenary, octonary, nonary, denary...

export const breakpoints = [32, 48, 64, 80].map((n) => n + "em")
export const space = [0, 10, 20, 32, 40, 52, 64, 76, 94, 112, 128].map(
  (size) => `${(size / 20).toFixed(2)}rem`
)
/*
export const space = [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6].map(
  (size) => `${(1 * size).toFixed(2)}rem`
)
*/

export const fontSizes = [...Array(100)].map(
  (empty, size) => `${((1 / 20) * size * 1).toFixed(2)}rem`
)

const fontCollection = {
  walsheimLight: {
    fontFamily: `"GT Walsheim Light", sans`,
    normal: 400,
    bold: 700,
    fontSize: fontSizes[20],
    lineHeight: "1.4em",
    letterSpacing: 0,
    heightCorrection: 0.3,
    baselineCorrection: 0.36,
  },
  walsheim: {
    fontFamily: `"GT Walsheim", sans`,
    normal: 400,
    bold: 700,
    fontSize: fontSizes[20],
    lineHeight: "1.4em",
    heightCorrection: 0.3,
    baselineCorrection: 0.36,
  },
  mono: {
    fontFamily: `"IBM Plex Mono", mono`,
    normal: 300,
    bold: 700,
    fontSize: fontSizes[17],
    lineHeight: 1.4,
  },
}

export const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
}

export const textStyles = {
  logo: {
    ...fontCollection.walsheim,
    fontWeight: "bold",
    fontSize: fontSizes[37],
  },
  title: { ...fontCollection.walsheim, fontSize: fontSizes[30] },
  headline: {
    ...fontCollection.walsheim,
    fontSize: fontSizes[30],
  },
  hero: {
    ...fontCollection.walsheim,
    fontSize: fontSizes[90],
    lineHeight: "1.1em",
  },
  body: {
    ...fontCollection.walsheimLight,
    fontSize: fontSizes[18],
  },
  navigation: {
    ...fontCollection.walsheimLight,
    fontSize: fontSizes[18],
  },
  navigationBold: {
    ...fontCollection.walsheim,
    fontWeight: "normal",
    fontSize: fontSizes[18],
  },
  callout: fontCollection.walsheimLight,
  subhead: { ...fontCollection.walsheim, fontSize: fontSizes[20] },
  footnote: {
    ...fontCollection.walsheimLight,
    fontSize: fontSizes[16],
  },
  caption: fontCollection.walsheimLight,
  action: {
    ...fontCollection.walsheim,
    fontWeight: "normal",
  }, // text in buttons, menu items
  mono: fontCollection.mono,
}

export const radii = [0, 4, 8]

export const shadows = ["none", "0 10px 30px 0 rgba(0,0,0,.07)"]

const maxWidth = `
    max-width: 920px;
    width: 90%;
    margin: 0 auto;
  `

export default {
  isWideBreakpoint: "820px",
  headerHeight: "4.8rem",
  maxWidth,
  breakpoints,
  space,
  textStyles,
  fontSizes,
  fontWeights,
  colors,
  radii,
  shadows,
}
