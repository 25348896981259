import React, { useState, useEffect, useMemo } from "react"
import { View, Stack, Text, Link } from "./components"
import { createGlobalStyle } from "styled-components"
import { useLocation } from "react-use"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Layout from "./Layout"
import routeConfig from "./DashboardRouteConfig"

const TransitionStyle = createGlobalStyle`
//old page exiting, hides it underneath (bottom) 
.pageSlider-exit-active {
  z-index: 1;
}
//new page entering, show it on top
.pageSlider-enter-active {
  z-index: 10;
}

.left .pageSlider-enter {
  transform: translate3d(100px, 0, 0);
  opacity: 0;
}
.left .pageSlider-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.left .pageSlider-exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.left .pageSlider-exit-active {
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
}


.right .pageSlider-enter {
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
}
.right .pageSlider-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.right .pageSlider-exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.right .pageSlider-exit-active {
  transform: translate3d(100px, 0, 0);
  opacity: 0;
}

.content {
  position: absolute;
  left: 20px;
  top: 40px;
  transition: all 400ms ease-in-out;
}

`

// op welke route ben ik?! en op basis daarvan: welke content-items laat ik zien?!
// ...itereren door de configureScope, op zoek naar het juiste pad/array...

const endsWith = (source: string, match: string) => {
  const index = source.lastIndexOf(match)
  const length = match.length
  console.log(index)
  console.log(source.length === index + length)
  return source.length === index + length
}

const startsWith = (source: string, match: string) => {
  const index = source.indexOf(match)
  console.log("startsWith", index)
  return index === 0
}

const walkTree = (tree, path, walk, parent) => {
  console.log(tree, path, walk)

  const index = tree.routes
    ? tree.routes.findIndex(item => {
        return startsWith(path, walk + item.path)
      })
    : -1
  console.log("index", index)
  if (index > -1) {
    // there's a match, so let's see if we can dive a little deeper
    console.log("match")
    return walkTree(
      tree.routes[index],
      path,
      walk + tree.routes[index].path,
      tree
    )
  } else {
    // nothing found
    console.log("no match")
    return { ...tree, parent }
  }
}

const getPathDepth = pathname => {
  let pathArr = pathname.split("/")
  pathArr = pathArr.filter(n => n !== "")
  return pathArr.length
}

export default () => {
  const [prevPathDepth, setPrevPathDepth] = useState(0)
  const [pathDepth, setPathDepth] = useState(1)
  const [branch, setBranch] = useState(routeConfig)
  const location = useLocation()
  const { pathname = "" } = location

  useEffect(() => {
    setBranch(walkTree(routeConfig, pathname, routeConfig.path, null))
    const newPathDepth = getPathDepth(pathname)
    if (pathDepth !== newPathDepth) {
      setPrevPathDepth(pathDepth)
      setPathDepth(newPathDepth)
    }
  }, [location])

  console.log(prevPathDepth, pathDepth)

  //Specify the duration of the animation (on enter and on exit)
  const timeout = { enter: 800, exit: 800 }

  if (branch === null) return null

  console.log("parent", branch.parent)

  return (
    <>
      <View p={[3, 4, 5]} style={{ position: "fixed", minWidth: 300 }}>
        <TransitionStyle />
        <TransitionGroup
          component="div"
          className={pathDepth - prevPathDepth >= 0 ? "left" : "right"}
        >
          <CSSTransition
            key={pathDepth}
            timeout={timeout}
            classNames="pageSlider"
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <div className="content">
              <Stack gap={3}>
                {branch.path !== "/dashboard-poc" && (
                  <Link
                    block
                    to={pathname.substring(0, pathname.lastIndexOf("/"))}
                    textStyle="mono"
                  >
                    &lt; {branch.parent?.label?.split("Browse ").join("")}
                  </Link>
                )}
                <Text textStyle="mono">
                  {branch.label?.split("Browse ").join("")}
                </Text>

                <View>
                  {branch.routes &&
                    branch.routes.map(item => (
                      <Link
                        block
                        key={item.path}
                        to={pathname + item.path}
                        textStyle="mono"
                      >
                        {item.label}
                      </Link>
                    ))}
                </View>
              </Stack>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </View>
      <Layout>
        <View />
        <Text textStyle="mono" m={4}>
          {branch.content}
        </Text>
      </Layout>
    </>
  )
}
