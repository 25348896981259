import React, { Fragment } from "react"
import styled from "styled-components"
import DevInfo from "../components/DevInfo"
import { View } from "../components"
import News from "./News"
import Quotes from "./Quotes"

import Selected from "../products/Selected"
import { GridForButtons } from "../components/Overview"
import CoverCarousel from "../components/CoverCarousel"
import { useHomeQuery } from "../sanity/sanityQueries"
import PromoBlocksOverview from "../components/PromoBlockOverview"

interface HomePageProps {
  children?: any
}

const Container = styled(View)`
  padding-top: ${({ theme }) => theme.headerHeight};
  min-height: 100vh;
`

const HomePage = ({ children }: HomePageProps): JSX.Element => {
  const { data } = useHomeQuery({})
  console.log({ data })

  return (
    <>
      <Container px={[2, 3, 4]}>
        {data?.allOverview?.[0].promoBlocks && (
          <PromoBlocksOverview
            promoBlocks={data.allOverview[0].promoBlocks}
            insert={[
              {
                match: "NEWS",
                element: <News items={data.allPost} />,
              },
              {
                match: "QUOTES",
                element: <Quotes items={data.allCustomerQuote} />,
              },
            ]}
          />
        )}
        {children}
      </Container>
    </>
  )
}

export default HomePage
