import React from "react"
import styled from "styled-components"
import { View, Stack, Grid, RatioContainer, Text, Divider } from "../components"
import { SanityImage } from "../components/SlideImage"
import BlockContent from "@sanity/block-content-to-react"

const Avatar = styled(View)`
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #ddd;
  float: right;
  margin-top: -0.75rem;
`

const Quotes = ({ items }) => (
  <Grid gridGap={[3, 3, 3]} minColumnWidth={[180, "20%", "20%"]} mb={5}>
    <Text textStyle="title">
      <Divider />
      <View mt={[4, 5, 6]}>Clients & designers who trust us</View>
    </Text>
    {items?.map((item) => (
      <View>
        <Stack gap={4}>
          <Text>
            <Divider mb={3} />
            <Avatar>
              {item.avatarImage && <SanityImage source={item.avatarImage} />}
            </Avatar>
            {item.name}
            <br />
            {item.position}
          </Text>
          <Text textStyle="subhead">
            <BlockContent blocks={item.quoteRaw} />
          </Text>
        </Stack>
        {item.logoImage && (
          <View style={{ width: "4rem", height: "4rem" }} mt={4}>
            <RatioContainer
              relative
              my={2}
              ratio={
                item.logoImage.asset.metadata.dimensions.width /
                item.logoImage.asset.metadata.dimensions.height
              }
            >
              <SanityImage source={item.logoImage} />
            </RatioContainer>
          </View>
        )}
      </View>
    ))}
    <div />
    <div />
  </Grid>
)

export default Quotes
