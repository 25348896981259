import React from "react"
import styled from "styled-components"
import textStyles from "../utilities/textStyles"
import Text from "./Text"
import Link from "./Link"

import {
  background,
  BackgroundProps,
  bottom,
  BottomProps,
  color as styledColor,
  ColorProps,
  display,
  DisplayProps,
  height,
  HeightProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  right,
  RightProps,
  space as styledSpace,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
  borderRadius,
  BorderRadiusProps,
} from "styled-system"

export interface ViewProps
  extends BackgroundProps,
    BottomProps,
    ColorProps,
    DisplayProps,
    HeightProps,
    LeftProps,
    MaxWidthProps,
    MinHeightProps,
    PositionProps,
    RightProps,
    SpaceProps,
    TextAlignProps,
    TopProps,
    WidthProps,
    ZIndexProps,
    BorderRadiusProps {}

interface ButtonProps extends ViewProps {
  children: any
  textStyle?: string
  center?: boolean
  inline?: boolean
  block?: boolean
  pointer?: boolean
  ellipsis?: boolean
  theme: any
  as?: any
  ref?: any
  to?: any
  type?: string
}

const Button = styled(({ borderRadius, ...rest }) => (
  <button {...rest} />
)).attrs(
  ({
    secondary,
    alternate,
    bg,
  }: {
    secondary: boolean
    alternate: boolean
    bg: string
  }) => ({
    ...(secondary && { bg: bg || "black", color: "white" }),
    ...(alternate && { bg: bg || "white", color: "primary" }),
  })
)<ButtonProps>`
  display: ${(props) => (props.block ? `block` : `inline-block`)};
  position: relative;
  min-height: ${({ theme }) => theme.space[2]}px;
  border: 0;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
  &:hover:not(:disabled) {
    opacity: 0.75;
  }
  ${(props) => (props.center ? `text-align: center` : "")};
  ${styledSpace};
  ${styledColor};
  ${borderRadius};
  ${({ ellipsis }) =>
    ellipsis &&
    `
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  text-align: center;
`

const ForwardedButton = React.forwardRef((props: any, ref: any) => (
  <Button ref={ref} {...props} as={props.as || "a"}>
    {props.as && !["a", "span", "button", Link].includes(props.as) ? (
      props.children
    ) : (
      <Text textStyle={props.textStyle} color={props.color}>
        {props.children}
      </Text>
    )}
  </Button>
))

ForwardedButton.defaultProps = {
  px: 3,
  py: 3,
  bg: "black",
  color: "white",
  borderRadius: 0,
  as: "span",
  textStyle: "action",
  center: false,
}

export default ForwardedButton
