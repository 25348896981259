import productTypes from "./productTypes"
import friendlyUrl from "friendly-url"

interface RouteInterface {
  label?: string
  path: string
  routes?: Array<RouteInterface>
  parent?: RouteInterface | null
  content?: string
}

const routeConfig: RouteInterface = {
  label: "Dashboard",
  content: `An overview of the most recent products that are being worked on — Recently Used Designs — Your Project Playlist — Your Favourites?`,
  path: "/poc",
  routes: [
    {
      label: "Browse Products",
      content: `An overview of all products as offered by Aectual.`,
      path: "/products",
      routes: productTypes.map((productType) => ({
        label: productType,
        path: `/${friendlyUrl(productType)}`,
        routes: [
          { label: "How does it work", path: "/how" },
          { label: "Possible Applications", path: "/applications" },
          { label: "Product Variations", path: "/variations" },
          { label: "Projects", path: "/projects" },
          { label: "Technical Specifications", path: "/specs" },
          { label: "Order Sample", path: "/sample" },
          { label: "F.A.Q.", path: "/faq" },
        ],
      })),
    },
    {
      label: "Projects",
      content: `An overview of all Clients' projects.`,
      path: "/projects",
      routes: [
        { label: "Project 1", path: "/1" },
        { label: "Project 2", path: "/2" },
        { label: "Project 3", path: "/3" },
      ],
    },
    {
      label: "Settings",
      path: "/settings",
      routes: [
        { label: "Business Information", path: "/business-info" },
        { label: "Team", path: "/team" },
        { label: "Advanced", path: "/advanced" },
      ],
    },
    { label: "Contact", path: "/contact" },
  ],
  parent: null,
}

export default routeConfig
