import React from "react"
import styled from "styled-components"
import { View, Text, Divider } from "./"
import Arrow from "./Arrow"

const Inline = styled(View)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > *:first-child {
    flex: 1;
  }
`

const DownloadItem = ({ download }) => (
  <View>
    <Divider my={0} />
    <Inline my={2}>
      <Text>{download.name}</Text>
      <Text
        mr={1}
        as="a"
        download={download.originalFilename}
        href={download.file.asset.url}
        target="_blank"
        textStyle="footnote"
      >
        Download ({download.type})
      </Text>
      <Arrow style={{ fontSize: "1.5em", lineHeight: 1 }} />
    </Inline>
  </View>
)

export default DownloadItem
