import React from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import {
  Stack,
  Inline,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
  Portal,
  Icon,
} from "../../components"
import { SanityImage } from "../../components/SlideImage"

import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import { useProjectQuery, useCreateProduct } from "../project/ProjectPage"
import sanityClient from "../../sanity/sanityClient"
import { IMAGE } from "../../sanity/sanityQueries"
import { useUser } from "../../user/AuthProvider"
import { useContactOverlay } from "../../components/ContactOverlay"
import { useProductCategories } from "./product-mutations"

export const Item = ({ item, projectId }) => {
  const navigate = useNavigate()
  const [createProduct] = useCreateProduct()

  const goCreate = () => {
    createProduct({
      variables: {
        name: item.title || item.name,
        projectId: parseInt(projectId, 10),
        categoryId: 17,
      },
    }).then(({ data }) => navigate("../" + data.createProduct.product.id))
  }

  return (
    <Stack gap={2}>
      <RatioContainer
        ratio={1}
        bg="#EEE"
        as={item.slug.current === "pattern-terrazzo-no" ? View : Link}
        {...(item.slug.current === "pattern-terrazzo-no"
          ? { onClick: goCreate }
          : { as: Link, to: "../variant/" + item.slug.current })}
        style={{ display: "block", cursor: "pointer" }}
      >
        <SanityImage source={item?.image?.image} />
      </RatioContainer>
      <Stack gap={2}>
        <Text textStyle="title">Add {item.title || item.name}</Text>
        <Link to={"/products"}>
          <Icon name="arrow-right" size={18} mr={1} />
          Go to product page
        </Link>
      </Stack>
    </Stack>
  )
}

const NewProductPage = () => {
  const user = useUser()
  const { toggle } = useContactOverlay()

  let { projectId = "" } = useParams()
  const { data, refetch } = useProjectQuery(projectId)

  const { data: sanityData } = useProductCategories()

  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />

            <Inline
              as={Link}
              to="../../../../"
              back
              replace
              verticalAlign="center"
            >
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../" back replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.project?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Text textStyle="title" mt={8} mb={8}>
              Add Product
            </Text>

            <Text textStyle="action">Overview of products</Text>
            <Text textStyle="body">
              Select the product you want to use for your project. If you need
              more information about the product you can click on the links to
              the product overview pages.
              <br />
              <br />
              When you are thinking of a product or design which is not (or not
              yet) part of our product overview here please share your ideas
              with us and together we’ll see how we can assist you and develop
              your custom product design together, using our technology.
              <br />
              <br />
              When you have any questions feel free to{" "}
              <View
                as="span"
                onClick={toggle}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                contact us
              </View>
              .
            </Text>
          </Stack>
        )}
      </Portal>
      <View p={3}>
        <Stack gap={2}>
          <DashboardContentHeader headline="Add Product" subtext="Products" />

          <Grid gridGap={[3, 4, 5]} minColumnWidth={[210, 240, 240]}>
            {sanityData &&
              sanityData.allProductCategory
                .filter((category) => category.enabledInProductChooser === true)
                .map((category) => (
                  <Item item={category} projectId={projectId} />
                ))}

            <div />
            <div />
            <div />
          </Grid>
        </Stack>
      </View>
    </>
  )
}

export default NewProductPage
