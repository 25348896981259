import React, { useState } from "react"
import styled from "styled-components"
import { useParams, Navigate, useNavigate } from "react-router-dom"
import { useAtom } from "jotai"
import {
  Stack,
  Inline,
  View,
  Text,
  Button,
  Link,
  Divider,
  RatioContainer,
  Portal,
  Icon,
} from "../../components"
import { useUser, useAuth } from "../../user/AuthProvider"
import client from "../../user/authClient"
import { useQuery, useMutation } from "react-apollo"
import {
  Container,
  DashboardContentHeader,
  ProjectItem,
} from "../projects/ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"

import ListModels from "../model/ListModels"
import { SpecItem } from "../project/ProjectPage"
import sanityClient from "../../sanity/sanityClient"
import { PRESETS_QUERY } from "../model/ModelPage"
import { useContactOverlay } from "../../components/ContactOverlay"
import Spinner from "../../components/Spinner"
import DeleteProduct from "./DeleteProduct"
import { UPDATE_PRODUCT_NAME } from "./product-mutations"
import { outputAtom } from "../../shapediver/useShapeDiver"

import {
  CLIENT_PRODUCT_QUERY,
  CREATE_FILE_MUTATION,
  CREATE_FILE_VERSION_MUTATION,
  useProductTypeSlug,
} from "./product-mutations"

const fileToBase64 = (filepath) => {
  return new Promise((resolve) => {
    var reader = new FileReader()
    // Read file content on file loaded event
    reader.onloadend = function() {
      resolve(reader.result)
    }
    // Convert data to base64
    reader.readAsDataURL(filepath)
  })
}

const ProductPage = () => {
  let { productId = "" } = useParams()
  const user = useUser()
  const { toggle } = useContactOverlay()
  const navigate = useNavigate()
  const [output] = useAtom(outputAtom)

  const [uploading, setUploading] = useState(false)

  const { data, refetch } = useQuery(CLIENT_PRODUCT_QUERY, {
    variables: { id: parseInt(productId, 10) },
    client,
    fetchPolicy: "network-only",
  })

  const [newFile] = useMutation(CREATE_FILE_MUTATION, { client })
  const [newVersion] = useMutation(CREATE_FILE_VERSION_MUTATION, { client })

  // PRESETS
  const { data: presetsData } = useQuery(PRESETS_QUERY, {
    client: sanityClient,
  })

  console.log({ presetsData })

  const files = data?.product?.files ?? []
  const models = !!data?.product?.models
    ? [...data?.product?.models].reverse()
    : []

  console.log(files, models, models?.[0]?.ticket)

  const variant = presetsData?.allDesignerVariations.find(
    (variant) =>
      variant.ticket === models?.[0]?.ticket ||
      variant.key === models?.[0]?.ticket
  )
  console.log("VARIANT")
  console.log({ variant })

  const requestUpload = variant?.requestUpload === true
  console.log({ requestUpload })

  const token = window.localStorage.getItem("accessToken")

  // Fetch product category based on Odoo Category Id:
  const [getCategory, { data: categoryData }] = useProductTypeSlug()

  React.useEffect(() => {
    if (data?.product?.category?.id) {
      getCategory({ variables: { id: data.product.category.id } })
    }
  }, [data])

  return (
    <>
      <Portal portal="dashboard-side">
        {data && (
          <Stack gap={2} px={[2, 3, 3]} mt={3}>
            <Divider />

            <Inline as={Link} to="../../../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {user.company && user.company.length > 1
                    ? user.company
                    : user.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Inline as={Link} to="../../" replace verticalAlign="center">
              {user && (
                <Text flex textStyle="body">
                  {data?.product?.project?.name}
                </Text>
              )}
              <Icon name="arrow-left" size={18} />
            </Inline>
            <Divider />
            <Text textStyle="title" mt={8} mb={8}>
              {data?.product?.name}
            </Text>

            {models?.length > 0 && (
              <Link block textStyle="title" to={`model/${models[0].id}`} my={2}>
                Design
              </Link>
            )}
            {categoryData?.allProductCategory?.[0]?.samples?.length > 0 && (
              <Link
                block
                textStyle="title"
                to={`sample`}
                my={2}
                style={{
                  ...(!user.employee && {
                    pointerEvents: "none",
                    opacity: 0.5,
                  }),
                }}
              >
                Sample Request
              </Link>
            )}
              <Link block textStyle="title" to="quote" my={2}>
                Request Quote
              </Link>
            <Link
              block
              textStyle="title"
              to={categoryData?.allProductCategory?.[0]?.slug?.current ?? ""}
              my={2}
            >
              General Product Info
            </Link>
          </Stack>
        )}
      </Portal>
      <Portal portal="editor">
        <Stack gap={2} px={[2, 3, 3]} mt={8}>
          {data && requestUpload && (
            <Text textStyle="title" mb={3}>
              Upload Files
            </Text>
          )}
          {data && requestUpload && (
            <Text mb={3}>
              For the best workflow, upload your design as .dxf file, and
              organize your work in the following layers:
              <br />
              <br />
              PATTERN - Your floor pattern design
              <br />
              OUTLINE - Exact outline of the designated floor area(s)
              <br />
              DIMENSIONS - At least one dimension tag to make sure the scale is
              correct
              <br />
              MISC - Any other element in your drawing that you would like to
              include, for preview purposes
              <br />
              <br />
              Any other vector format will work too, but will take some more
              time to process. If your not certain your file will work, just
              upload your file and we will get in touch with you. Or{" "}
              <Text
                pointer
                hover
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  toggle()
                }}
                style={{
                  textDecoration: "underline",
                  display: "inline-block",
                }}
              >
                contact sales
              </Text>
              .
            </Text>
          )}
        </Stack>
      </Portal>
      <Container>
        <View style={{ flex: 1 }} relative mt={1}>
          <>
            {data && requestUpload && (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const name = acceptedFiles[0].name

                  if (typeof name === "string" && name.length > 1) {
                    setUploading(true)
                    newFile({
                      variables: { name, productId: parseInt(productId, 10) },
                    }).then(({ data }) => {
                      if (data.createFile) {
                        const reader = new FileReader()
                        reader.onloadend = function() {
                          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
                          if (reader.result) {
                            var b64 = reader.result
                              .toString()
                              .replace(/^data:.+;base64,/, "")
                            newVersion({
                              variables: {
                                fileId: data.createFile.file.id,
                                content: b64,
                              },
                            }).then(() => {
                              setUploading(false)
                              refetch({ id: parseInt(productId, 10) })
                            })
                          }

                          // console.log(b64); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
                        }

                        reader.readAsDataURL(acceptedFiles[0])
                      }
                    })
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <SpecItem label="">
                    <Button
                      bg="secondaryBackground"
                      color="black"
                      as={RatioContainer}
                      ratio={21 / 9}
                      flex
                      style={{ width: "100%" }}
                      {...getRootProps()}
                    >
                      <div
                        style={{
                          ...((models.length <= 1 && requestUpload) ||
                          models.length === 0
                            ? {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                marginLeft: "0",
                                marginRight: "auto",
                                minHeight: "25vmin",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                flexDirection: "column",
                                outline: "1px solid black",
                              }
                            : { display: "none" }),
                        }}
                      >
                        <Icon name="add" color="white" size="64" y={2} />
                        <Text textStyle="title">
                          {files.length > 0
                            ? "Pending..."
                            : "Drag and drop a file, or click to upload"}
                        </Text>
                        {files.length > 0 && (
                          <Text style={{ maxWidth: "26em" }} mt={3}>
                            We received your file and are currently reviewing
                            it. Once ready, your file will be visible here with
                            additional design options, and feedback on
                            printability. You will also get a notification by
                            e-mail.
                            <br />
                            <div style={{ height: "0.5em" }} />
                            You can add additional files by dropping files on
                            this area.
                          </Text>
                        )}
                        <Text
                          style={{ textAlign: "left", maxWidth: "29em" }}
                          mt={3}
                        ></Text>
                      </div>
                    </Button>
                    <input {...getInputProps()} />
                  </SpecItem>
                )}
              </Dropzone>
            )}

            {presetsData && (
              <ListModels
                productId={productId}
                viewModel={
                  (requestUpload === true && models.length > 1) ||
                  (requestUpload === false && models.length > 0)
                }
                refetch={refetch}
                ticket={variant?.ticket}
              >
                <Stack gap={2} mt={2}>
                  <SpecItem
                    label="Product Name"
                    value={data?.product?.name}
                    updateMutation={UPDATE_PRODUCT_NAME}
                    thing="name"
                    id={parseInt(productId, 10)}
                  />
                  <SpecItem
                    label="Product Category"
                    value={`${categoryData?.allProductCategory?.[0]?.name ??
                      "AEC Products"} ${
                      user.employee
                        ? ` [Odoo: ${data?.product?.category?.name}, ID: ${data?.product?.category?.id}]`
                        : ""
                    }`}
                  />
                  <Stack gap={2}>
                    {output.map(
                      (item, index) =>
                        !["API Download"].includes(item.name) &&
                        item.data && (
                          <SpecItem
                            label={item.name}
                            key={item.name}
                            value={item.data}
                          />
                        )
                    )}
                  </Stack>
                </Stack>

                {files.length > 0 && (
                  <Stack gap={2} mt={2}>
                    {[...files].reverse().map((file) => (
                      <SpecItem
                        label="File"
                        value={file.name}
                        element={
                          <Text color="white" pb={1} pr={1}>
                            Versions:{" "}
                            {file.versions.map((version) =>
                              version.url.includes("https:") ? (
                                <>
                                  <a
                                    href={version.url}
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      color: "white",
                                    }}
                                  >
                                    {DateTime.fromISO(version.date).toFormat(
                                      "dd.LL.yyyy hh:mm"
                                    )}
                                  </a>
                                  <span> </span>
                                </>
                              ) : (
                                <>
                                  <a
                                    href={
                                      "https://erp-test12.aectual.com" +
                                      version.url +
                                      "?session_id=" +
                                      token
                                    }
                                    target="_blank"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      color: "white",
                                    }}
                                    download={file.name}
                                  >
                                    {DateTime.fromISO(version.date).toFormat(
                                      "dd.LL.yyyy hh:mm"
                                    )}
                                  </a>
                                  <span> </span>
                                </>
                              )
                            )}
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                fileToBase64(acceptedFiles[0]).then(
                                  (result) => {
                                    if (typeof result === "string") {
                                      console.log(result)
                                      newVersion({
                                        variables: {
                                          fileId: file.id,
                                          content: result.replace(
                                            /^data:.+;base64,/,
                                            ""
                                          ),
                                        },
                                      }).then(() => {
                                        refetch({ id: parseInt(productId, 10) })
                                      })
                                    }
                                  }
                                )
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <>
                                  <span
                                    {...getRootProps()}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    +
                                  </span>
                                  <input {...getInputProps()} />
                                </>
                              )}
                            </Dropzone>
                          </Text>
                        }
                      />
                    ))}
                  </Stack>
                )}
                {uploading && <Spinner mt={2} />}
              </ListModels>
            )}
          </>
        </View>
        <DeleteProduct id={parseInt(productId, 10)} />
      </Container>
    </>
  )
}

export default ProductPage
