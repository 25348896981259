import React from "react"
import styled from "styled-components"
import {
  Stack,
  View,
  Text,
  Button,
  Link,
  Portal,
  Inline,
  Divider,
  Icon,
} from "../components"
import { useUser, useAuth } from "./AuthProvider"
import { DashboardContentHeader } from "../dashboard/projects/ProjectsPage"
import { SpecItem } from "../dashboard/project/ProjectPage"
import { UPDATE_COMPANY, UPDATE_NAME } from "./authMutations"

const Container = styled(View).attrs({ px: [1, 2, 3], pb: 5, pt: [2, 3, 3] })``

export default () => {
  const user = useUser()
  const { isAuthenticating, logout } = useAuth()

  if (isAuthenticating) {
    return <Text>...</Text>
  }
  return (
    <>
      <Portal portal="dashboard-side">
        <Stack gap={2} px={[2, 3, 3]} mt={3}>
          <Divider />

          <Inline as={Link} to="/dashboard" replace verticalAlign="center">
            {user && (
              <Text flex textStyle="body">
                Dashboard
              </Text>
            )}
            <Icon name="arrow-left" size={18} />
          </Inline>

          <Divider />
          <Text textStyle="title" mt={8} mb={8}>
            Account Information
          </Text>
        </Stack>
      </Portal>
      <Container>
        {user && (
          <>
            <Stack gap={2} mt={6}>
              <SpecItem
                label="Company"
                value={user?.company || ""}
                updateMutation={UPDATE_COMPANY}
                thing="company"
              />

              <SpecItem
                label="Name"
                value={user?.name || ""}
                updateMutation={UPDATE_NAME}
                thing="name"
              />

              <SpecItem label="E-mail" value={user?.email || ""} />

              <View>
                <Button
                  my={2}
                  mr={3}
                  as="button"
                  onClick={logout}
                  color="white"
                >
                  Log out
                </Button>
              </View>
            </Stack>
          </>
        )}
      </Container>
    </>
  )
}
