import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom"
import { Provider as JotaiProvider } from "jotai"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { Reset } from "styled-reset"
import { View, Stack, Text } from "./components"

import theme from "./theme"
import AuthOverlayProvider from "./user/AuthOverlay"
import ManageScroll from "./components/ManageScroll"
import Header from "./Header"
import UI from "./UI"
import HomePage from "./home/HomePage"
import ProductsPage from "./products/ProductsPage"
import ProductTypePage from "./products/ProductTypePage"
import HowPage from "./how/HowPage"
import LoginPage from "./user/LoginPage"
import SignupPage from "./user/SignupPage"
import ActivationPage from "./user/ActivationPage"
import ProfilePage from "./user/ProfilePage"
import DesignPartnersPage from "./design-partners/DesignPartnersPage"
import UploadArea from "./components/UploadArea"
import JobsPage from "./jobs/JobsPage"
import StaircasePage from "./staircase/StaircasePage"
import ShapeDiverViewer from "./shapediver/Viewer"
import Dashboard from "./Dashboard"
import { Page } from "./Layout"
import SelectTile from "./tile/SelectTile"
import ModelsPage from "./models/ModelsPage"
import ModelPage from "./models/ModelPage"
import ProjectsPage from "./dashboard/projects/ProjectsPage"
import NewProject from "./dashboard/projects/NewProject"
import ProjectPage from "./dashboard/project/ProjectPage"
import ProductPage from "./dashboard/product/ProductPage"
import { useAuth } from "./user/AuthProvider"
import TechnologiesServicesPage from "./technologies-services/TechnologiesServicesPage"
import SustainabilityPage from "./sustainability/SustainabilityPage"
import DesignersPage from "./designers/DesignersPage"
import OverlayMenu from "./OverlayMenu"
import DashboardPage from "./dashboard/DashboardPage"
import DashboardModelPage from "./dashboard/model/ModelPage"
import NewModelPage from "./dashboard/model/NewModel"
import ProjectDetailsEditPage from "./dashboard/projects/ProjectDetailsEditPage"
import ProductDetailsEditPage from "./dashboard/projects/ProductDetailsEditPage"
import NewProductPage from "./dashboard/product/NewProductPage"
import ProductFilesPage from "./dashboard/product/ProductFilesPage"
import ChooseCurveVariant from "./dashboard/product/ChooseVariant"
import Footer from "./Footer"
import ContactOverlayProvider from "./components/ContactOverlay"
import RoutesTracker from "./components/RoutesTracker"
import RequestQuotePage from "./dashboard/request-quote/RequestQuotePage"
import SamplePage from "./dashboard/sample/SamplePage"
import Success from "./dashboard/sample/Success"
import VideoPage from "./video/VideoPage"
import CheckoutOverlayProvider from "./components/CheckoutOverlay"

const GlobalStyle = createGlobalStyle`

  html, body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 15px;
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.background};
    width: 100%;
    overflow-x: visible;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  p:empty {
    display: none;
  }

  @media (min-width: 920px) {
    html, body {
      font-size: 15px;
    }
  }

  @media (min-width: 1280px) {
    html, body {
      font-size: 15px;
    }
  }

  @media (min-width: 1340px) {
    html, body {
      font-size: 15px;
    }
  }

  @media (min-width: 1340px) {
    html, body {
      font-size: 16px;
    }
  }

  @media (min-width: 1800px) {
    html, body {
      font-size: 20px;
    }
  }

  .carousel .slide.slide {
    background-color: transparent;
  }

  .carousel.carousel-slider {
    max-width: 100vw;
  }

  .carousel .control-dots {
    padding: 0;
  }

  .carousel .control-dots .dot {
    box-shadow: none !important;
    transition: background-color 250ms ease-in-out;
  }

  .carousel .control-dots .dot.selected {
    background-color: black;
  }

  iframe {
    header {
      display: none;
    }
  }

  #jq-add-light-modal {
    display: none;
  }

  
`

const HolyFlex = styled(View)`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  & > *:first-child {
    flex: 1;
  }
`

const Start = () => <UploadArea />

const Redirect = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [])
  return null
}

const WhenAuthenticated = ({ children }) => {
  const { isAuthenticated, isAuthenticating } = useAuth()
  return isAuthenticating ? null : isAuthenticated ? (
    children
  ) : (
    <Redirect to="/" />
  )
}

const WebsiteOrDashboardHeader = () => {
  const location = useLocation()
  console.log({ location })
  return location.pathname.includes("dashboard") ||
    location.pathname.includes("model") ? null : (
    <Header />
  )
}

const App = (): JSX.Element => {
  return (
    <JotaiProvider>
      <ThemeProvider theme={theme}>
        <Text id="app">
          <Reset />
          <ManageScroll />
          <GlobalStyle />
          <Router>
            <RoutesTracker>
              <OverlayMenu>
                <ContactOverlayProvider>
                  <CheckoutOverlayProvider>
                    <AuthOverlayProvider>
                      <Routes>
                        <Route path="/poc/*" element={<Dashboard />} />
                        <Route path="/success" element={<Success />} />
                        <Route
                          path="/*"
                          element={
                            <>
                              <WebsiteOrDashboardHeader />
                              <HolyFlex>
                                <Outlet />
                                <Footer />
                              </HolyFlex>
                            </>
                          }
                        >
                          <Route
                            path="/"
                            element={
                              <HomePage>
                                <Outlet />
                              </HomePage>
                            }
                          />
                          <Route path="how" element={<HowPage />} />

                          <Route
                            path="nike"
                            element={
                              <VideoPage videoUrl="https://vimeo.com/366211442/4eb643814f" />
                            }
                          />
                          <Route
                            path="urquiola"
                            element={
                              <VideoPage videoUrl="https://vimeo.com/366549521/04e2947f7d" />
                            }
                          />
                          <Route
                            path="aectualcompany"
                            element={
                              <VideoPage videoUrl="https://vimeo.com/345444699" />
                            }
                          />
                          <Route
                            path="demo"
                            element={
                              <VideoPage videoUrl="https://vimeo.com/395966663/b5a9f999b6" />
                            }
                          />

                          <Route
                            path="company"
                            element={<Redirect to="/about" />}
                          />
                          <Route path="ui" element={<UI />} />
                          <Route
                            path="architectural-products"
                            element={
                              <ProductsPage>
                                <Outlet />
                              </ProductsPage>
                            }
                          >
                            {/* <Route path=":id" element={<Viewer />} /> */}
                          </Route>
                          <Route path="/*" element={<Redirect to="/" />} />

                          <Route
                            path="architectural-products/:productType/*"
                            element={<ProductTypePage />}
                          />
                          <Route
                            path="architectural-products/:parentProductType/variants/:productType/*"
                            element={<ProductTypePage />}
                          />
                          <Route
                            path="manufacturing-capabilities/*"
                            element={<TechnologiesServicesPage />}
                          />

                          <Route
                            path="sustainability/*"
                            element={<SustainabilityPage />}
                          />
                          <Route
                            path="designers/*"
                            element={<DesignersPage />}
                          />

                          <Route
                            path="about/*"
                            element={<TechnologiesServicesPage slug="about" />}
                          />

                          <Route path="contact" element={<Redirect to="/" />} />
                          <Route path="start" element={<Start />} />
                          <Route path="login">
                            <LoginPage page />
                          </Route>
                          <Route path="signup">
                            <SignupPage page />
                          </Route>
                          <Route
                            path="activate/:token"
                            element={<ActivationPage />}
                          />
                          <Route
                            path="dashboard/*"
                            element={
                              <WhenAuthenticated>
                                <DashboardPage>
                                  <Outlet />
                                </DashboardPage>
                              </WhenAuthenticated>
                            }
                          >
                            <Route path="" element={<ProjectsPage />} />
                            <Route
                              path="project/new"
                              element={<NewProject />}
                            />
                            <Route
                              path="project/:projectId"
                              element={<ProjectPage />}
                            />
                            <Route
                              path="project/:projectId/edit"
                              element={<ProjectDetailsEditPage />}
                            />
                            <Route
                              path="project/:projectId/product/new"
                              element={<NewProductPage />}
                            />
                            <Route
                              path="project/:projectId/product/variant/:variantSlug"
                              element={<ChooseCurveVariant />}
                            />
                            <Route
                              path="project/:projectId/product/:productId"
                              element={<ProductPage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/:productType/*"
                              element={<ProductTypePage dashboard />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/edit"
                              element={<ProductDetailsEditPage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/quote"
                              element={<RequestQuotePage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/sample"
                              element={<SamplePage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/files"
                              element={<ProductFilesPage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/model/:modelId"
                              element={<DashboardModelPage />}
                            />
                            <Route
                              path="project/:projectId/product/:productId/model/new"
                              element={<NewModelPage />}
                            />
                            <Route path="profile" element={<ProfilePage />} />
                          </Route>

                          <Route
                            path="design-partners"
                            element={<DesignPartnersPage />}
                          />
                          <Route path="jobs" element={<JobsPage />} />
                          <Route
                            path="staircase-proto"
                            element={<StaircasePage />}
                          />
                          <Route
                            path="shapediver/:ticket"
                            element={<ShapeDiverViewer />}
                          />
                          <Route path="models" element={<ModelsPage />} />
                          <Route path="model/:id" element={<ModelPage />} />
                          <Route path="layout" element={<Page></Page>} />
                          <Route path="select-tile" element={<SelectTile />} />
                        </Route>
                      </Routes>
                    </AuthOverlayProvider>
                  </CheckoutOverlayProvider>
                </ContactOverlayProvider>
              </OverlayMenu>
            </RoutesTracker>
          </Router>
        </Text>
      </ThemeProvider>
    </JotaiProvider>
  )
}

export default App
