import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  ButtonOutline,
  Link,
  Divider,
  Grid,
  RatioContainer,
  DropZone as DropzoneComponent,
} from "../../components"
import client from "../../user/authClient"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import { Container, DashboardContentHeader, ProjectItem } from "./ProjectsPage"
import Dropzone from "react-dropzone"
import { DateTime } from "luxon"
import { useProjectQuery } from "../project/ProjectPage"

const ProjectDetailsEditPage = () => {
  let { projectId = "" } = useParams()
  const { data } = useProjectQuery(projectId)
  return (
    <View p={3}>
      <Stack gap={2}>
        <Text>Edit project details</Text>
        <Text>Project Name: {data?.project?.name}</Text>
      </Stack>
    </View>
  )
}

export default ProjectDetailsEditPage
