import React, { useEffect, Fragment } from "react"
import styled from "styled-components"
import Text from "./Text"
import Icon from "./Icon"
import { useDropzone } from "react-dropzone"
// import FilePreview from "./ImageFilePreview"
// import pica from "pica"
import { useLazyQuery } from "@apollo/react-hooks"
import axios from "axios"

const letterboxOrCrop = (canvas, image, crop = false) => {
  var canvas_ratio = canvas.width / canvas.height
  var image_ratio = image.width / image.height
  if (
    (canvas_ratio > image_ratio && !crop) ||
    (canvas_ratio < image_ratio && crop === true)
  ) {
    // height is leading: canvas height
    var resulting_width = Math.round(canvas.height * image_ratio)
    return { width: resulting_width, height: canvas.height }
  } else {
    // width is leading: canvas width
    var resulting_height = Math.round(canvas.width / image_ratio)
    return { width: canvas.width, height: resulting_height }
  }
}

const StyledDropzone = styled.div`
  border: none;
  max-height: 260px;
  max-width: 100%;

  display: inline-block;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const compress = (file, dimensions = { width: 1280, height: 960 }) =>
  new Promise((resolve, reject) => {
    const { width, height } = dimensions
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const img = new Image()
      if (reader.result) {
        img.src = reader.result.toString()
        img.onload = () => {
          const newDimensions = letterboxOrCrop(
            { width, height },
            { width: img.width, height: img.height }
          )
          console.log(img.width, img.height, newDimensions)
          const elem = document.createElement("canvas")
          elem.width = newDimensions.width
          elem.height = newDimensions.height
          const ctx = elem.getContext("2d")
          // img.width and img.height will contain the original dimensions
          ctx?.drawImage(img, 0, 0, newDimensions.width, newDimensions.height)
          resolve({
            blob: ctx?.canvas.toDataURL("image/jpeg", 0.8),
            ...newDimensions,
          })
        }
      }
    }
    reader.onerror = (error) => reject(error)
  })

export default ({
  callback,
  dimensions = { width: 1280, height: 1280 },
  children = null,
  url = null,
  iconOnly = false,
  size = 24,
}) => {
  const [file, setFile] = React.useState()
  const [resizedImage, setResizedImage] = React.useState()
  const [resizedDimensions, setDimensions] = React.useState()
  const onDrop = React.useCallback((acceptedFiles) => {
    // Do something with the files
    console.log("Do something with the files")
    resizeImage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/gif, image/jpg",
    noKeyboard: true,
  })

  const resizeImage = (file) => {
    setFile(file)
    setTimeout(() => {
      compress(file, dimensions)
        //@ts-ignore
        .then(({ blob, width, height }) => {
          console.log(blob, width, height)
          callback(blob)
        })
        .catch((err) => console.log(err))
    }, 333)
  }

  if (children)
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    )

  return (
    <StyledDropzone className="img-thumbnail mt-2" {...getRootProps()}>
      <input {...getInputProps()} />

      {/* file && <div>Preview file</div> */}

      <Text color="primary" my={iconOnly ? 0 : 2}>
        <Icon
          icon="image"
          size={size}
          style={{ position: "relative", top: -2, opacity: iconOnly ? 0.3 : 1 }}
          mr={1}
        />{" "}
        {!iconOnly && (file || url ? `Change Image` : `Select an Image`)}
      </Text>

      {!file && (resizedImage || url) && (
        <Fragment>
          {resizedImage || (url && <img src={resizedImage || url || ""} />)}
        </Fragment>
      )}
    </StyledDropzone>
  )
}
