import React, { Fragment } from "react"
import styled from "styled-components"
import useTheme from "../utilities/useTheme"
import {
  View,
  Stack,
  Inline,
  Text,
  Headline,
  RatioContainer,
  Divider,
  Link,
  Grid,
  New,
  Icon,
} from "../components"
import { Link as RouterLink } from "react-router-dom"
import TheSecondChildAfterDelay from "../components/TheSecondChildAfterDelay"
import Overview from "./Overview"
import friendlyUrl from "friendly-url"
import { useProductCategoriesQuery } from "../sanity/sanityQueries"
import { useMedia } from "use-media"
import imageUrl from "../sanity/imageUrl"
import productTypes from "../productTypes"
import PromoBlocksOverview from "../components/PromoBlockOverview"
import { FullVideo } from "../components/PromoBlock"

interface ProductsPageProps {
  children?: any
}

export const Container = styled(View)`
  display: flex;
  min-height: 100vh;
`

export const OverviewContainer = styled(View).attrs(() => ({ px: [2, 3, 4] }))`
  flex: 1;
  min-height: 100vh;
`

export const NavigatorContainer = styled(View).attrs(() => ({ pr: [2, 3, 4] }))`
  width: 320px;
  max-width: 35vw;
`

export const Sticky = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.headerHeight};
`

const ProductsPage = ({ children }: ProductsPageProps): JSX.Element => {
  const theme = useTheme()
  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })
  const isVeryWide = useMedia({ minWidth: 1650 })
  // let { path } = useRouteMatch()
  const { data } = useProductCategoriesQuery({})
  console.log({ data })
  React.useEffect(() => {
    if (data) {
      data.allProductCategory.forEach((category) => {
        if (category.image) {
          console.log(category.image)
          console.log(imageUrl(category.image, { width: 800, height: 600 }))
        }
      })
    }
  }, [data])
  return (
    <Fragment>
      <Container mt={theme.headerHeight}>
        <OverviewContainer>
          {data?.allOverview?.[0].promoBlocks && (
            <PromoBlocksOverview
              promoBlocks={data.allOverview[0].promoBlocks}
              insert={[
                {
                  match: "PRODUCT CATEGORIES",
                  element: data?.allProductCategory && (
                    <Grid
                      gridGap={[2, 3, 3]}
                      minColumnWidth={isVeryWide ? 340 : [220, 280, 300]}
                      mb={5}
                    >
                      {data.allProductCategory[0].children
                        .filter((category) => !category.parent)
                        .map((category, index) => (
                          <View>
                            <Divider mb={3} />
                            <Link
                              to={
                                category.slug.current +
                                (category.children?.length === 1
                                  ? `/variants/${category.children[0].slug.current}`
                                  : ``)
                              }
                              block
                              noScroll={index > 0}
                              mb={3}
                              textStyle="subhead"
                            >
                              <Inline>
                                <View flex>
                                  {category.label || category.name}
                                </View>
                                <Icon name="arrow-right" />
                              </Inline>
                              <RatioContainer ratio={1} mt={3}>
                                {category.image?.url && (
                                  <TheSecondChildAfterDelay
                                    disabled={!category.image.url.includes(",")}
                                  >
                                    <img
                                      src={category.image.url.split(",")[0]}
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        border: 0,
                                        pointerEvents: "none",
                                      }}
                                      width="100%"
                                      height="100%"
                                    ></img>
                                    {category.image.url.includes(",") && (
                                      <FullVideo
                                        url={category.image.url.split(",")[1]}
                                      />
                                    )}
                                  </TheSecondChildAfterDelay>
                                )}
                              </RatioContainer>
                            </Link>
                          </View>
                        ))}
                    </Grid>
                  ),
                },
              ]}
            />
          )}
        </OverviewContainer>

        {isWide && (
          <NavigatorContainer>
            <Sticky>
              <Divider mb={2} mt={0} />
              <View as={RouterLink} to="../">
                <Inline verticalAlign="center">
                  <Text textStyle="footnote" flex>
                    Home
                  </Text>
                  <Icon name="arrow-left" size={18} />
                </Inline>
              </View>
              <Divider my={2} />
              <Text textStyle="headline" mb={3}>
                Product categories
              </Text>
              <Divider mb={3} />
              {data?.allProductCategory &&
                data.allProductCategory[0].children
                  .filter((category) => !category.parent)
                  .map((category, index) => (
                    <>
                      <Link
                        to={
                          category.slug.current +
                          (category.children?.length === 1
                            ? `/variants/${category.children[0].slug.current}`
                            : ``)
                        }
                        block
                        noScroll={index > 0}
                        mb={3}
                      >
                        {category.name}
                      </Link>
                    </>
                  ))}
            </Sticky>
          </NavigatorContainer>
        )}
      </Container>
      {children}
    </Fragment>
  )
}

export default ProductsPage
