import React, { Fragment, useState, useEffect } from "react"
import {
  View,
  Stack,
  Text,
  Headline,
  Divider,
  Grid,
  Button,
  Link,
} from "./components"
import Circle from "./components/Circle"
import theme from "./theme"
import CoverCarousel from "./components/CoverCarousel"
import ShapeDiverViewer from "./shapediver/Viewer"

const FontSpecs = ({ textStyle, rootSize }) => (
  <Text textStyle="footnote" color="#bbb">
    Font size:{" "}
    {theme.fontSizes.findIndex(
      (fontSize) => fontSize === theme.textStyles[textStyle].fontSize
    )}{" "}
    / {theme.textStyles[textStyle].fontSize} • Root size: {rootSize}px • Current
    size:{" "}
    {rootSize *
      parseFloat(theme.textStyles[textStyle].fontSize.split("rem").join(""))}
    px — Line height: {theme.textStyles[textStyle].lineHeight}
  </Text>
)

const SpaceSpecs = ({ index, rootSize }) => (
  <Text textStyle="footnote" color="#bbb" mt={2} mb={1}>
    Index: {index} • {theme.space[index]} • Root size: {rootSize}px • Current
    size: {rootSize * parseFloat(theme.space[index].split("rem").join(""))}px
  </Text>
)

const Color = ({ color }: { color: string }) => (
  <View style={{ minWidth: 154 }} mb={3}>
    <Text textStyle="footnote" color="#aaa" my={2}>
      {color}
      <br />
      {theme.colors[color]}
    </Text>
    <Circle bg={color} mr={3} />
  </View>
)

const Colors = () => (
  <Grid gridGap={[3, 4, 5]} minColumnWidth={[145]} my={4}>
    <Color color="primary" />
    <Color color="primaryHover" />
    <Color color="primaryText" />
    <Color color="secondary" />
    <Color color="secondaryHover" />
    <Color color="secondaryText" />
    <Color color="alternate" />
    <Color color="alternateHover" />
    <Color color="alternateText" />
    <Color color="accent" />
    <Color color="secondaryAccent" />
    <Color color="alternateAccent" />
    <Color color="text" />
    <Color color="textInvert" />
    <Color color="textPlaceholder" />
    <Color color="background" />
    <Color color="secondaryBackground" />
    <Color color="alternateBackground" />
    <Color color="backgroundInvert" />
    <Color color="overlay" />
    <Color color="border" />
    <Color color="disabled" />
    <Color color="shadow" />
  </Grid>
)

const UI = ({}): JSX.Element => {
  const [rootSize, setRootSize] = useState(16)
  const getSetRootSize = () => {
    let styles = window.getComputedStyle(window.document.body)
    // get the font size in px
    let fontSize: any = styles.getPropertyValue("font-size")
    fontSize = parseFloat(fontSize)
    setRootSize(fontSize)
  }
  useEffect(() => {
    window.addEventListener("resize", getSetRootSize)
    getSetRootSize()
    return () => {
      window.removeEventListener("resize", getSetRootSize)
    }
  }, [])

  return (
    <View px={[2, 3, 3]}>
      <View bg="secondaryBackground" p={[3, 4, 5]} mb={4}>
        <Headline center>Aectual UI</Headline>
        <Text center>Collection of components & configurations</Text>
      </View>
      <View>
        <Button to="/staircase-proto" as={Link} my={4}>
          Staircase MVP
        </Button>{" "}
        <Button
          href="https://aectual-playroom.surge.sh/"
          target="_blank"
          as="a"
        >
          Visit UI Playroom
        </Button>
      </View>

      <ShapeDiverViewer ticket="d1643357c2f2a95414d8da566834650747dbb0a365b91d4d9ea5ee251d0583db9215e528e2e45e21645bc2d46d86871f5972e12d9fda5c8314f8fd5858956b97f6b8fb598526df3d623bfb875876f3ae7888216be8b77fb7a72f2117124daceb49ac37eaa40777d5d72136b638a02cfab4f262d574a0-61fe15899b72f592369ccd14971155c7" />

      <View
        style={{ maxWidth: 940, marginLeft: "auto", marginRight: "auto" }}
        mb={4}
      >
        <div style={{ width: 400, height: 400, position: "relative" }}>
          <CoverCarousel title="Home - Digital Producer" />
        </div>
        <Divider />
        <Text textStyle="title">Colors:</Text>
        <Divider />
        <Colors />
        <Divider />
        <Text textStyle="title">Typography:</Text>
        <Divider />
        <Stack gap={1} my={3}>
          <Text textStyle="footnote" bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="footnote" />
            Footnote
            <br />
            Newline
          </Text>
          <Headline bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="headline" />
            A headline
            <br />
            Newline
          </Headline>
          <Text bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="footnote" />
            Body Text • Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed nisl arcu, ultrices non neque quis, rutrum tristique est. Nulla
            semper auctor eros, porttitor consectetur eros posuere vel. Etiam
            feugiat lectus sed justo venenatis egestas. Praesent tincidunt
            libero et eleifend porta. Suspendisse venenatis urna sit amet purus
            blandit pharetra. Mauris at imperdiet lectus, a mattis sem.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia Curae; Nullam eu mi eget ligula vehicula dignissim.
            Ut quis felis tincidunt, porttitor metus quis, ultrices libero.
            Nullam ultrices massa dignissim dui commodo malesuada.
          </Text>
          <Text textStyle="title" bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="title" />
            The Title
            <br />
            Newline
          </Text>
          <Text textStyle="caption" bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="caption" />
            Caption
            <br />
            Newline
          </Text>
          <Text textStyle="action" bg="secondaryBackground" p={2}>
            <FontSpecs rootSize={rootSize} textStyle="action" />
            Action
            <br />
            Newline
          </Text>
        </Stack>
        <Divider mt={5} />
        <Text textStyle="title">White space units:</Text>
        <Divider />
        {theme.space.map((space, index) => (
          <Fragment key={index}>
            <SpaceSpecs rootSize={rootSize} index={index} />
            <View pt={index} bg="primary" />
          </Fragment>
        ))}
      </View>
    </View>
  )
}

export default UI
