import React from "react"
import styled from "styled-components"
import useTheme from "../utilities/useTheme"
import { useMedia } from "use-media"
import { View, Inline, Text, Link, Divider, Stack, Icon } from "../components"
import {
  ScrollOrNavigate,
  NavLink,
  useMemorizeScrollOrClick,
} from "../products/ProductTypePage"
import { useCollectionQuery, useHomeQuery } from "../sanity/sanityQueries"
import PromoBlocksOverview from "../components/PromoBlockOverview"
import News from "../home/News"

export const Container = styled(View)`
  display: flex;
  margin-top: ${(props) => props.theme.headerHeight};
  min-height: 100vh;
`

export const OverviewContainer = styled(View).attrs(() => ({ px: [2, 3, 3] }))`
  flex: 1;
  min-height: 100vh;
`

export const NavigatorContainer = styled(View).attrs(() => ({ pr: [2, 3, 4] }))`
  width: 320px;
`

export const Sticky = styled(View)`
  position: sticky;
  top: ${(props) => props.theme.headerHeight};
`
// next up, the navigation thing... and the Route thing per collection?!

const CollectionPage = ({ slug = "manufacturing-capabilities" }) => {
  const theme = useTheme()
  const isWide = useMedia({ minWidth: theme.isWideBreakpoint })
  const { data } = useCollectionQuery({
    variables: { slug },
  })

  useMemorizeScrollOrClick()

  return (
    <Container>
      {data?.allCollection?.[0]?.sections && (
        <OverviewContainer>
          {data.allCollection[0].sections.map((section, index) => (
            <div style={{ position: "relative" }}>
              {section.slug && (
                <ScrollOrNavigate
                  path={section.slug?.current}
                  noScroll={index == 0}
                />
              )}
              {section.promoBlocks && (
                <PromoBlocksOverview promoBlocks={section.promoBlocks} />
              )}
              {section.slug?.current === "news" && (
                <News items={data.allPost} expand />
              )}
            </div>
          ))}
        </OverviewContainer>
      )}
      {isWide && (
        <NavigatorContainer>
          {data?.allCollection?.[0]?.sections && (
            <Sticky>
              <Divider />
              <Link to="/" textStyle="footnote" back block>
                <Inline>
                  <View flex>{"Home"}</View>
                  <Icon name="arrow-left" size={18} />
                </Inline>
              </Link>
              <Divider />
              <Text textStyle="headline">{data.allCollection[0].title}</Text>
              <Divider mb={3} />
              <Stack gap={4} my={4}>
                {data.allCollection[0].sections.map((section, index) => (
                  <View
                    onMouseUp={() => {
                      window.sessionStorage.setItem("navigated", "click")
                      window.sessionStorage.setItem(
                        "navigatedTime",
                        new Date().getTime().toString()
                      )
                    }}
                  >
                    <NavLink
                      to={!section.slug ? "" : section.slug?.current}
                      block
                      noScroll={true}
                    >
                      <span
                        className="active"
                        style={{
                          fontSize: "1.5em",
                          lineHeight: 0,
                          position: "relative",
                          top: "0.1em",
                          marginRight: "0.3em",
                        }}
                      >
                        →
                      </span>
                      {section.title}
                    </NavLink>
                  </View>
                ))}
              </Stack>
            </Sticky>
          )}
        </NavigatorContainer>
      )}
    </Container>
  )
}

export default CollectionPage
