import gql from "graphql-tag"
import slideDetails from "./SLIDE_FRAGMENT"

const ALBUM_QUERY = gql`
  ${slideDetails}

  query allAlbums($user: String!, $title: String!) {
    allAlbums(filter: { user: { nickname: $user }, title: $title }) {
      id
      title
      description
      keyValues {
        id
        key {
          name
        }
        value
      }
      tags {
        id
        name
      }
      slides(orderBy: order_ASC) {
        ...slideDetails
      }
    }
  }
`

export default ALBUM_QUERY
