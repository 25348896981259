import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import gql from "graphql-tag"
import { useMutation } from "react-apollo"
import client from "../../user/authClient"
import { useNavigate, useParams, Navigate, useLocation } from "react-router-dom"
import {
  Stack,
  View,
  Text,
  Input,
  Button,
  Link,
  Portal,
  Icon,
  Divider,
  Inline,
} from "../../components"
import ImageSelect from "../../components/ImageSelect"
import FilePreview from "../../components/FilePreview"
import { useForm } from "react-hook-form"
import { useUser } from "../../user/AuthProvider"
import queryString from "query-string"

const Container = styled(View).attrs({
  px: [1, 2, 3],
  pb: 5,
  center: true,
  pt: 5,
})`
  max-width: 26em;
`

export default () => {
  const user = useUser()

  const location = useLocation()
  let params = queryString.parse(location.search)
  console.log(params)

  const navigate = useNavigate()

  useEffect(() => {
    const redirect = setTimeout(
      // @ts-ignore
      () => params.path && navigate(params.path),
      4500
    )
    return () => clearTimeout(redirect)
  }, [])

  return (
    <>
      <Container>
        <Stack>
          <Text textStyle="title">
            Your payment has successfully been processed.
          </Text>
          <Text>Sending you back to your project page in a second...</Text>
          <Link to={params.path?.toString()}>{params.path}</Link>
        </Stack>
      </Container>
    </>
  )
}
