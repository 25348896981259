// provider... Everywhere you should be able to auth if needed...
// function... param is the next location to go to when authenticated... already authenticated?! then go there directly without overlay?!?!]

import React, { createContext, useContext, useRef, useState } from "react"
import styled from "styled-components"
import { View, Text, Button } from "../components"
import { useNavigate } from "react-router-dom"
import { useToggle } from "react-hooks-lib"
import { useUser } from "./AuthProvider"
import SignupPage from "./SignupPage"
import MenuToggle from "../components/MenuToggle"
import LoginPage from "./LoginPage"

export const OverlayContainer = styled(View).attrs(({ bg = "#DDD" }) => ({
  bg,
}))`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50vw;
  min-width: 404px;
  transform: translateX(30px);

  @media (max-width: 550px) {
    width: 100%;
    min-width: 100%;
    transform: translateX(0px);
  }
  z-index: 1981;

  opacity: 0;
  pointer-events: none;
  ${(props) =>
    props.visible &&
    `
    opacity: 1;
    pointer-events: auto;
    transform: none;
  `}
  transition: opacity 250ms ease, transform 250ms ease;
`

const ScrollContent = styled(View).attrs(() => ({ px: [0, 5, 7] }))`
  width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
`

interface IContextProps {
  executeIfAuthenticated: Function
  navigateAuth: Function
}

const initialContext: IContextProps = {
  navigateAuth: () => {},
  executeIfAuthenticated: () => {},
}

const AuthOverlayContext = createContext(initialContext)

export const AuthOverlayConsumer = AuthOverlayContext.Consumer

export const useAuthOverlay = () => useContext(AuthOverlayContext)

const AuthOverlayProvider = ({ children }) => {
  const ref = useRef()
  const user = useUser()
  const { on, toggle } = useToggle(false)
  const { on: login, toggle: toggleLogin } = useToggle(false)

  const [path, setPath] = useState()

  const navigate = useNavigate()

  const navigateAuth = (path) => {
    ref.current = undefined
    if (user) {
      // window.alert("already logged in, directly sending you to " + path)
      setPath(undefined)
      navigate(path)
    } else {
      setPath(path)
      toggle()
      // window.alert("first signup/login, then I'll be sending you to " + path)
    }
  }
  const executeIfAuthenticated = (fn) => {
    setPath(undefined)
    if (user) {
      fn()
    } else {
      toggle()
      // store function in Ref!
      ref.current = fn
      // fn()
    }
  }
  const executeCallback = () => {
    if (typeof ref.current === "function") {
      // @ts-ignore
      ref.current()
    }
    if (!!path) {
      // @ts-ignore
      navigate(path)
    }
    setTimeout(toggle, 150)
  }
  return (
    <AuthOverlayContext.Provider
      value={{ navigateAuth, executeIfAuthenticated }}
    >
      {children}
      <OverlayContainer visible={on}>
        <View p={[2, 5, 7]} style={{ position: "absolute", right: 0, top: 0 }}>
          <MenuToggle
            active={true}
            toggle={toggle}
            color="black"
            style={{ marginRight: -4, marginTop: 6 }}
          />
        </View>

        {/* <Button
            onClick={() => {
              if (typeof ref.current === "function") {
                if (!user)
                  window.alert(
                    "Not authenticated, but will fire function anyway."
                  )
                // @ts-ignore
                ref.current()
              } else {
                if (!user) window.alert("No function specified.")
              }
            }}
          >
            Execute function is there is one
          </Button> */}
        <ScrollContent>
          {!login && (
            <SignupPage
              title="Please signup/login to continue"
              toggleLogin={toggleLogin}
              callback={executeCallback}
            />
          )}
          {login && (
            <LoginPage toggleLogin={toggleLogin} callback={executeCallback} />
          )}
        </ScrollContent>
        {/*
    render signup/login form with callback => navigate to / execute function
    */}
      </OverlayContainer>
    </AuthOverlayContext.Provider>
  )
}

export default AuthOverlayProvider
