import React from "react"
import { RatioContainer, View } from "../components"
import Vimeo from "../components/Vimeo"

export default ({ videoUrl }) => (
  <View m={7} mt={"15vh"} center style={{ width: "80vw" }}>
    <RatioContainer ratio={16 / 9}>
      <Vimeo videoLink={videoUrl} />
    </RatioContainer>
  </View>
)
